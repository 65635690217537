import * as React from "react";
import { Collapse } from "../../components/Collapse";
import { MarkdownPreview } from "../../components/MarkdownPreview";
import { IWorkbook, StepEnum } from "../../firebase/models";
import { ButtonContainer, Container } from "./Exercise";
import { ExerciseHeader } from "./ExerciseHeader";
import { ExercisesContainer } from "./Exercises";
import { LockUnlock } from "./LockUnlock";
import { Message } from "./Message";

interface InterfaceProps {
  workbook: IWorkbook;
  currentPage: number;
}

interface InterfaceState {
  locked: boolean;
}

export class Introduction extends React.Component<
  InterfaceProps,
  InterfaceState
> {
  state = {
    locked: true,
  };
  render() {
    const { workbook, currentPage } = this.props;

    const toggleLocked = () =>
      this.setState((state: InterfaceState) => ({ locked: !state.locked }));

    return workbook.introduction && currentPage === StepEnum.Work ? (
      <ExercisesContainer>
        <Container className="exercise">
          <Collapse
            locked={this.state.locked}
            header={
              <ExerciseHeader
                lockUnlock={
                  <LockUnlock
                    currentPage={this.props.currentPage}
                    isLocked={this.state.locked}
                    toggleLocked={toggleLocked}
                  />
                }
                currentPage={this.props.currentPage}
                isLocked={this.state.locked}
                title={`${workbook.name} Introduction`}
              />
            }
          >
            <Message>
              <MarkdownPreview markdown={workbook.introduction} />
            </Message>
            <div>
              <ButtonContainer>
                <LockUnlock
                  closeText="Close Section"
                  currentPage={this.props.currentPage}
                  isLocked={this.state.locked}
                  toggleLocked={toggleLocked}
                />
              </ButtonContainer>
            </div>
          </Collapse>
        </Container>
      </ExercisesContainer>
    ) : null;
  }
}
