import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import * as React from "react";
import * as ReactDOM from "react-dom";
import { App } from "./components/App";
import "./index.css";
import { unregister } from "./registerServiceWorker";

// LogRocket.init("livingwisely/livingwisely-firebase");

Sentry.init({
  dsn:
    "https://0946b76a867b4fe4b7b91aa24b0203d7@o283590.ingest.sentry.io/5532985",
  integrations: [new Integrations.BrowserTracing()],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});

ReactDOM.render(<App />, document.getElementById("root"));
unregister();
