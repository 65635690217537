import { Button, Col, Modal, Row } from "antd";
import * as React from "react";
import styled from "styled-components";
import { StepEnum } from "../../firebase/models";
import { subDomain } from "../../firebase/utils";
import media from "../../utils/media-query-template.js";

const myMedia = media as {
  desktop: (...args: any[]) => string;
  tablet: (...args: any[]) => string;
  phone: (...args: any[]) => string;
};

interface InterfaceProps {
  page: number;
}

export const Message = styled.div`
  border: 1px solid #59a9a0;
  background: white;
  border-radius: 4px;
  padding: 20px;
  position: relative;
  overflow: visible;

  section.first:first-child:first-letter {
    color: #59a9a0;
    float: left;
    font-family: Georgia;
    font-size: 75px;
    line-height: 60px;
    padding-top: 4px;
    padding-right: 8px;
    padding-left: 3px;
  }

  section {
    padding-bottom: 20px;
  }

  .ant-tag {
    margin-right: 1px;
  }

  .benefits {
    margin-top: 20px;
    ${myMedia.desktop`
        margin-left: 0;
        padding-left: 0;
      `}
  }

  ul,
  ol {
    margin-top: 10px;
    margin-left: 40px;
  }

  h3:first-child,
  h4:first-child {
    margin-top: 0;
  }
`;

const Sprite = styled.img`
  width: 50px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
`;

const isIndividuals =
  subDomain() === "introduction" || subDomain() === "individuals";

export class HelpfulInstructions extends React.Component<InterfaceProps> {
  private myRef = React.createRef<HTMLDivElement>();
  state = { visible: false };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = (e: any) => {
    this.setState({
      visible: false,
    });
  };

  handleCancel = (e: any) => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };

  render() {
    const messageMap = {
      [StepEnum.Work]: (
        <div>
          <section className="first tooltip-3">
            The questions, prompts and exercises within Step 1 will help you
            brainstorm your initial ideas and thoughts, which will create the
            building blocks for your upcoming documents. You will have a number
            of opportunities to enhance, edit and perfect your responses in the
            next steps.
          </section>

          <Row gutter={24}>
            <Col sm={6}>
              <Button
                size="large"
                type="primary"
                style={{
                  width: "fit-content",
                  height: "38px",
                }}
                onClick={this.showModal}
              >
                Open Detailed Instructions & Tips
              </Button>
              <Modal
                title="Detailed Instructions & Tips"
                visible={this.state.visible}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                footer={null}
                width="75%"
              >
                <section>
                  <Message className="tip">
                    <h3>
                      <Sprite
                        src={require("../../images/sprite-no-white.png")}
                      />
                      LivingWisely Tip
                    </h3>

                    <section>
                      Relax and enjoy the journey. This is a work-in- progress
                      document.
                    </section>
                    <section>
                      Many people find it helpful to write in short intervals
                      and begin with what comes to mind. When they return to
                      their document, they often uncover new ideas and better
                      ways to communicate their thoughts and wishes.
                    </section>
                    <section>
                      Remember, you can use your smartphone or tablet to
                      continue what you started on your computer. And your
                      responses will be saved automatically regardless of which
                      technology you choose to use.
                    </section>
                  </Message>
                </section>
                <br />
                <ol className="benefits text-center counters">
                  <li>
                    <span className="counter">A</span>
                    <span>
                      To begin, click the <b>Open Section</b> button.
                    </span>
                  </li>
                  <li>
                    <span className="counter">B</span>
                    <span>
                      Voice transcribe, type and update as many responses as you
                      wish, and your information will be saved.
                    </span>
                  </li>
                  <li>
                    <span className="counter">C</span>
                    <span>
                      Upload your favorite photos or supporting documents to
                      complement your responses and include an appropriate
                      caption or description for each one.
                    </span>
                  </li>
                  <li>
                    <span className="counter">D</span>
                    <span>
                      Utilize the notepad for tasks or reminders you need to
                      address along the way.
                    </span>
                  </li>
                  <li>
                    <span className="counter">E</span>
                    <span>
                      When you feel you have generated a good amount of starter
                      content, click the <b>Close &amp; Forward</b> button
                      within each section and your responses will be forwarded
                      to Step 2. You will then be able to review, reflect and
                      prioritize your initial responses until you are ready to
                      pull your best ideas and thoughts together into a
                      document.
                    </span>
                  </li>
                </ol>
              </Modal>
            </Col>
          </Row>
        </div>
      ),
      [StepEnum.Synthesis]: (
        <div>
          <section className="first">
            Step 2 will help you review, reflect, prioritize and edit your
            content so that it represents what matters most to you. Remember,
            your initial responses from Step 1 were forwarded here.
          </section>

          <Row gutter={24}>
            <Col sm={16}>
              <Button
                size="large"
                type="primary"
                style={{
                  width: "fit-content",
                  height: "38px",
                }}
                onClick={this.showModal}
              >
                Open Detailed Instructions & Tips
              </Button>
              <Modal
                title="Detailed Instructions & Tips"
                visible={this.state.visible}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                footer={null}
                width="75%"
              >
                <section>
                  <Message>
                    <h3>
                      <Sprite
                        src={require("../../images/sprite-no-white.png")}
                      />
                      LivingWisely Tip
                    </h3>
                    <section>
                      Once you begin writing in Step 2, we recommend that you do
                      not return to Step 1 to make changes to your initial ideas
                      as it will undo your formatting and alter the writing you
                      completed in Step 2.
                    </section>
                    <section>
                      If you would like to review the prompts featured in Step
                      1, simply click on the Review Prompts button within each
                      category header below.
                    </section>
                    <section>
                      If you must return to Step 1, you will need to click the
                      Unlock button, update your response, and then re-click the
                      Close button to forward this information to Step 2.
                    </section>
                  </Message>
                </section>
                <section>
                  <ol className="benefits text-center counters">
                    <li>
                      <span className="counter">A</span>
                      <span>
                        Review the content you have created so far (remember,
                        your initial responses from Step 1 were forwarded here).
                      </span>
                    </li>
                    <li>
                      <span className="counter">B</span>
                      <span>
                        Read your responses out loud and determine if they
                        accurately represent what matters most to you.
                      </span>
                    </li>
                    <li>
                      <span className="counter">C</span>
                      <span>
                        Take time to reflect. See if any new observations and
                        ah-ha moments come to mind or if you can identify the
                        wisdom you gained from your experiences.
                      </span>
                    </li>
                    <li>
                      <span className="counter">D</span>
                      <span>
                        Challenge yourself to dig deeper and share your insights
                        and feelings.
                      </span>
                    </li>
                    <li>
                      <span className="counter">E</span>
                      <span>
                        The Word Cloud at the bottom of this step provides a
                        visual representation of the most frequently used words
                        within your reflective or creative documents. If you
                        notice someone or something is missing, make revisions
                        to your document.
                      </span>
                    </li>
                    <li>
                      <span className="counter">F</span>
                      <span>
                        You may upload photos or supporting documents and add a
                        short caption to each.
                      </span>
                    </li>
                    <li>
                      <span className="counter">G</span>
                      <span>
                        Review, enhance and edit your content until you're ready
                        for the next Step.
                      </span>
                    </li>
                  </ol>
                </section>
              </Modal>
            </Col>
          </Row>
        </div>
      ),
      [StepEnum.Consolidate]: (
        <div>
          <section className="first">
            We recommend that once you begin writing in this step, you do not
            update Steps 1 or 2 since your content will not be forwarded. Or you
            may skip this step and share your content in Step 4.
          </section>
          <Row gutter={24}>
            <Col sm={16}>
              <Button
                size="large"
                type="primary"
                style={{
                  width: "fit-content",
                  height: "38px",
                }}
                onClick={this.showModal}
              >
                Open Detailed Instructions & Tips
              </Button>
              <Modal
                title="Detailed Instructions & Tips"
                visible={this.state.visible}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                footer={null}
                width="75%"
              >
                <section>
                  <Message>
                    <h3>
                      <Sprite
                        src={require("../../images/sprite-no-white.png")}
                      />
                      LivingWisely Tip
                    </h3>
                    <section>
                      You may print your documents within this step using the
                      button at the bottom of the page. Or go to the final step
                      to download and share your documents.
                    </section>
                  </Message>
                  <ol className="benefits text-center counters">
                    <li>
                      <span className="counter">A</span>
                      <span>
                        Notice how the headers have gone away making it easier
                        for you to move content around, and edit and enhance
                        your content as much as you wish.
                      </span>
                    </li>
                    <li>
                      <span className="counter">B</span>
                      <span>
                        You may create one continuous document/story or a series
                        of documents/short stories to share what’s most
                        important within each section.
                      </span>
                    </li>
                  </ol>
                </section>
              </Modal>
            </Col>
          </Row>
        </div>
      ),
      [StepEnum.Review]: (
        <div>
          <section className="first">
            Everything you have written so far has been temporarily forwarded to
            this Step for sharing.
          </section>

          <Row gutter={24}>
            <Col sm={16}>
              <Button
                size="large"
                type="primary"
                style={{
                  width: "fit-content",
                  height: "38px",
                }}
                onClick={this.showModal}
              >
                Open Detailed Instructions & Tips
              </Button>
              <Modal
                title="Detailed Instructions & Tips"
                visible={this.state.visible}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                footer={null}
                width="75%"
              >
                <section>
                  <Message>
                    <h3>
                      <Sprite
                        src={require("../../images/sprite-no-white.png")}
                      />
                      LivingWisely Tip
                    </h3>
                    <section>
                      <section>
                        At any time, you may update your content by returning to
                        Step 2 or 3.
                      </section>
                      {isIndividuals && (
                        <div>
                          <section>
                            If you would you like to deepen your LegacyJourney
                            experience or explore more programs and resources,
                            contact your sponsoring organization or click here
                            now.
                          </section>

                          <Button
                            block={true}
                            href={"https://livingwisely.org/individuals/cart"}
                            icon="cart"
                            type="primary"
                          >
                            Explore Now
                          </Button>
                        </div>
                      )}
                    </section>
                  </Message>
                </section>
                <section>
                  <ol className="benefits text-center counters">
                    <li>
                      <span className="counter">A</span>
                      <span>
                        <b>Share all your content:</b> You may share everything
                        you have written so far by clicking the{" "}
                        <b>Share Document</b> button at the bottom of the page.
                        A box will appear for you to choose the people you wish
                        to include in your content sharing. Or you may enter an
                        email address for additional people you would like to
                        include.
                      </span>
                    </li>
                    <li>
                      <span className="counter">B</span>
                      <span>
                        <b>Share portions of your content:</b> If you would like
                        to share only a portion of your content with a
                        particular person, you may edit and change your content
                        and it will not impact or change what you created in
                        your previous Steps. Or simply click the{" "}
                        <b>Don’t Share</b> button within each section and that
                        block of content will not be shared. When you are ready,
                        click the Share Document button at the bottom of the
                        page. When you have completed your sharing, click the
                        Refresh button and everything will return to it’s
                        earlier form.
                      </span>
                    </li>
                    <li>
                      <span className="counter">C</span>
                      <span>
                        You may save your content to your personal files by
                        clicking the Download & Print button.
                      </span>
                    </li>
                    <li>
                      <span className="counter">D</span>

                      <span>
                        <b>Document preservation services:</b> We provide free
                        estimates to edit, enhance, design and/or preserve your
                        documents into a meaningful and lasting keepsake to
                        share with your loved ones and community. Simply click
                        on the Document Preservation button when you are ready.
                      </span>
                    </li>
                  </ol>
                </section>
              </Modal>
            </Col>
          </Row>
        </div>
      ),
    };

    return <Message ref={this.myRef}>{messageMap[this.props.page]}</Message>;
  }
}
