import * as React from "react";
import * as routes from "../../constants/routes";
import { auth } from "../../firebase";
import { Button, Form, Input } from "antd";
import { IOrganization } from "../../firebase/models";
import { doAddOrganizationUser, doLoginUser } from "../../firebase/db";

interface InterfaceProps {
  email?: string;
  error?: any;
  history?: any;
  password?: string;
  organization: Partial<IOrganization>;
}

interface InterfaceState {
  email: string;
  error: any;
  password: string;
}

export class SignInForm extends React.Component<
  InterfaceProps,
  InterfaceState
> {
  private static INITIAL_STATE = {
    email: "",
    error: null,
    password: ""
  };

  private static propKey(propertyName: string, value: any): object {
    return { [propertyName]: value };
  }

  constructor(props: InterfaceProps) {
    super(props);

    this.state = { ...SignInForm.INITIAL_STATE };
  }

  public onSubmit = (event: any) => {
    const { email, password } = this.state;

    const { history, organization } = this.props;

    auth
      .doSignInWithEmailAndPassword(email, password)
      .then(async (authUser: any) => {
        doAddOrganizationUser(organization, authUser.user.uid);
        doLoginUser(authUser.user.uid);
        this.setState(() => ({ ...SignInForm.INITIAL_STATE }));
        history.push(routes.HOME);
      })
      .catch(error => {
        this.setState(SignInForm.propKey("error", error));
      });

    event.preventDefault();
  };

  public render() {
    const { email, password, error } = this.state;

    const isInvalid = password === "" || email === "";

    return (
      <Form onSubmit={event => this.onSubmit(event)}>
        <Form.Item label={"Email Address"}>
          <Input
            value={email}
            onChange={event => this.setStateWithEvent(event, "email")}
            type="text"
          />
        </Form.Item>
        <Form.Item label={"Password"}>
          <Input
            value={password}
            onChange={event => this.setStateWithEvent(event, "password")}
            type="password"
          />
        </Form.Item>
        <Button
          type="primary"
          block={true}
          disabled={isInvalid}
          htmlType="submit"
        >
          Sign In
        </Button>

        {error && <p>{error.message}</p>}
      </Form>
    );
  }

  private setStateWithEvent(event: any, columnType: string): void {
    this.setState(SignInForm.propKey(columnType, (event.target as any).value));
  }
}
