import React, { Component } from "react";
import SpeechRecognition from "react-speech-recognition";
const options = {
  autoStart: false
};

const SharedSpeechContext = React.createContext({
  key: "",
  startTalking: (key, defaultValue, handleChange) => null,
  stopTalking: () => null,
  handleChange: content => null,
  browserSupportsSpeechRecognition: true
});

class SharedSpeechProviderComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isTalking: false,
      key: "",
      transcript: "",
      defaultValue: ""
    };
  }

  startTalking = (key, defaultValue, handleChange) => {
    this.props.startListening();
    this.setState({
      key,
      defaultValue,
      handleChange,
      isTalking: true
    });
  };
  stopTalking = () => {
    this.props.abortListening();
    this.props.resetTranscript();
    this.setState({
      key: "",
      defaultValue: "",
      handleChange: content => null,
      isTalking: false
    });
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { transcript, finalTranscript } = this.props;
    const { key, defaultValue, handleChange } = this.state;

    const input = document.getElementById(key);

    if (input) {
      input.value = defaultValue + transcript;
      input.scrollTop = input.scrollHeight;
    }

    if (finalTranscript) {
      handleChange(defaultValue + finalTranscript);
    }
  }

  render() {
    const { children, browserSupportsSpeechRecognition } = this.props;
    const { key } = this.state;

    return (
      <SharedSpeechContext.Provider
        value={{
          key: key,
          startTalking: this.startTalking,
          stopTalking: this.stopTalking,
          isTalking: this.state.isTalking,
          transcript: this.state.transcript,
          browserSupportsSpeechRecognition
        }}
      >
        {children}
      </SharedSpeechContext.Provider>
    );
  }
}

export const SharedSpeechProvider = SpeechRecognition(options)(
  SharedSpeechProviderComponent
);
export const SharedSpeechConsumer = SharedSpeechContext.Consumer;
