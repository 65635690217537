import * as React from "react";
import { IExercise, IPrompt, IWorkbook } from "../../firebase/models";
import { Popconfirm, message, Icon } from "antd";
import {
  doCreateExercise,
  doCreatePrompt,
  doCreateWorkbook
} from "../../firebase/db";
import { ReactNode } from "react";

interface InterfaceProps {
  exercises: IExercise[];
  prompts: IPrompt[];
  workbook: IWorkbook;
  children?: ReactNode;
}

export class CloneWorkbook extends React.Component<InterfaceProps> {
  confirm = async () => {
    const { exercises, workbook, prompts } = this.props;

    const { key, ...workbookClone } = workbook;
    console.log("key", key);
    const workbookCloneKey = await doCreateWorkbook({
      ...workbookClone,
      name: workbookClone.name + " Clone"
    });
    exercises.forEach(async exercise => {
      const { key, ...exerciseClone } = exercise;
      const promptClones = prompts.filter(prompt => prompt.exercise === key);
      const exerciseKey = await doCreateExercise({
        ...exerciseClone,
        workbook: workbookCloneKey
      });
      promptClones.forEach(promptData => {
        const { key, ...promptClone } = promptData;
        console.log(key);
        doCreatePrompt({
          ...promptClone,
          workbook: workbookCloneKey,
          exercise: exerciseKey
        });
      });
    });

    message.info("Workbook Cloned");
  };

  render() {
    const { children } = this.props;

    return (
      <Popconfirm
        icon={<Icon type="question-circle-o" style={{ color: "blue" }} />}
        title="Are you sure you want clone this workbook?"
        onConfirm={this.confirm}
        okText="Yes"
        cancelText="No"
      >
        {children ? (
          children
        ) : (
          <Icon type="snippets" style={{ color: "blue" }} />
        )}
      </Popconfirm>
    );
  }
}
