import * as React from "react";
import * as Showdown from "showdown";
import styled from "styled-components";
import { LinkModal } from "./LinkModal";
export const previewStyle = `
    white-space: pre-wrap;
    
    .clicked-button {
      font-weight: bold;
      color: black;
    }
    
    h1, h2, h3, h4, h5, h6 {
      margin-top: 5px;
      margin-bottom: 0;
      font-weight: 500;
      color: #1D2B4C;
      font-family: inherit !important;
    }
    
    pre {
      padding: 0;
    overflow: auto;
    font-size: 100%;
    background-color: inherit;
    border-radius: 3px;
    text-indent: 20px;
    }
    
    .buttons {
      column-count: 2;
      button {
        display: block;
        margin-bottom: 10px;
      }
    }
    
    
    code {
        font-family: inherit;
      font-size: inherit;
      padding: 0;
    }
    
    h1 {
      font-size: 40px !important;
      // line-height: 40px !important;
    }
    
    h2 {
      font-size: 36px !important;
      // line-height: 36px !important;
    }
    
   h3 {
      font-size: 32px !important;
      // line-height: 32px !important;
    }
    
    h4 {
      font-size: 28px !important;
      // line-height: 28px !important;
    }
   p {
    margin-bottom: 0;
   }
   
   ul, ol {
    margin-top: -50px !important;
    margin-bottom: -30px;
  }
  
  li {
    margin-top: -20px;
  }
   
   
   button {
    line-height: 1.499;
    position: relative;
    display: inline-block;
    font-weight: 400;
    text-align: left;
    background-image: none;
    border: 1px solid transparent;
    box-shadow: 0 2px 0 rgba(0,0,0,0.015);
    cursor: pointer;
    transition: all .3s cubic-bezier(.645, .045, .355, 1);
    user-select: none;
    touch-action: manipulation;
    margin-bottom: 4px;
    font-weight: 500;
    height: auto;
    padding: 3px 15px;
    font-size: 22px;
    border-radius: 4px;
    color: #fff;
    background-color: #2eb0a5;
    border-color: #2eb0a5;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
   }
   
`;
const Container = styled.div`
  ${previewStyle}
`;
export interface ReactMdePreviewProps {
  className?: string;
  emptyPreviewHtml?: string;
  markdown?: string;
  appendContent?: (content: string) => void;
}

export interface ReactMdePreviewState {
  loading: boolean;
  iframeSrc: string;
  html?: string;
}

export class MarkdownPreview extends React.Component<
  ReactMdePreviewProps,
  ReactMdePreviewState
> {
  private markdownRef = React.createRef<HTMLDivElement>();

  constructor(props: ReactMdePreviewProps) {
    super(props);
    this.state = {
      loading: true,
      iframeSrc: ""
    };
    this.updateHtml();
  }

  converter = new Showdown.Converter({
    tables: true,
    simplifiedAutoLink: true,
    strikethrough: true,
    omitExtraWLInCodeBlocks: true,
    tasklists: true
  });

  componentDidUpdate(prevProps: ReactMdePreviewProps): void {
    if (this.props.markdown !== prevProps.markdown) {
      this.updateHtml();
    }
  }

  updateHtml = () => {
    const { markdown = "" } = this.props;
    Promise.resolve(this.converter.makeHtml(markdown)).then(previewHtml => {
      this.setState({
        html: previewHtml,
        loading: false
      });
      this.setClickHandlerForButtons();
      this.setClickHandlerForLinks();
    });
  };

  setClickHandlerForLinks = () => {
    if (this.markdownRef && this.markdownRef.current) {
      const linksInMarkdown = this.markdownRef.current.querySelectorAll("a");
      if (linksInMarkdown.length) {
        for (let i = 0; i < linksInMarkdown.length; ++i) {
          linksInMarkdown[i].addEventListener("click", el => {
            el.preventDefault();
            if (el && el.target) {
              const href =
                (el.target as HTMLElement).getAttribute("href") || "";
              if (href) this.setState({ iframeSrc: href });
            }
          });
        }
      }
    }
  };

  setClickHandlerForButtons = () => {
    const { appendContent } = this.props;

    if (!appendContent) return;
    if (this.markdownRef && this.markdownRef.current) {
      const buttonsInMarkdown = this.markdownRef.current.querySelectorAll(
        "button"
      );
      if (buttonsInMarkdown.length) {
        for (let i = 0; i < buttonsInMarkdown.length; ++i) {
          buttonsInMarkdown[i].addEventListener("click", function(el) {
            if (el && el.target) {
              (el.target as HTMLElement).className += "clicked-button";
              const text =
                (el.target as HTMLElement).getAttribute("text") || "";
              const textContent =
                text + (el.target as HTMLElement).textContent || "";
              appendContent(textContent);
            }
          });
        }
      }
    }
  };

  hideCallback = () => {
    this.setState({ iframeSrc: "" });
  };

  render() {
    const { emptyPreviewHtml } = this.props;
    const { html, loading, iframeSrc } = this.state;
    const finalHtml = loading ? emptyPreviewHtml : html;
    return (
      <Container ref={this.markdownRef}>
        <div
          className="mde-preview-content"
          dangerouslySetInnerHTML={{
            __html: finalHtml || "<span>&nbsp;</span>"
          }}
        />
        <LinkModal hideCallback={this.hideCallback} iframeSrc={iframeSrc} />
      </Container>
    );
  }
}
