import { Icon, message, Upload } from "antd";
import { UploadFile } from "antd/lib/upload/interface";
import * as firebase from "firebase";
import * as React from "react";

interface IProps {
  dbRef: string;
  imageUrl?: string;
  handleUpload: (imageUrl: string) => void;
}

interface IState {
  loading: boolean;
  imageUrl: string;
}

function beforeUpload(file: File) {
  const isLt2M = file.size / 1024 / 1024 < 10;
  if (!isLt2M) {
    message.error("File must smaller than 10MB!");
  }
  return isLt2M;
}

export class Avatar extends React.Component<IProps, IState> {
  state = {
    loading: false,
    imageUrl: this.props.imageUrl || "",
  };

  upload = async (file: UploadFile) => {
    const { dbRef, handleUpload } = this.props;
    this.setState({ loading: true, imageUrl: "" });
    const storageRef = firebase
      .storage()
      .ref()
      .child(dbRef);
    const snapshot = await storageRef.put(file);
    const downloadUrl = await snapshot.ref.getDownloadURL();
    await handleUpload(downloadUrl);
    this.setState({
      imageUrl: downloadUrl,
      loading: false,
    });
  };

  handleChange = (info: any) => {
    if (info.file.status === "uploading") {
      this.setState({ loading: true });
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      this.setState({
        loading: false,
      });
    }
  };

  render() {
    const uploadButton = (
      <div>
        <Icon type={this.state.loading ? "loading" : "plus"} />
        <div className="ant-upload-text">Upload</div>
      </div>
    );
    const imageUrl = this.state.imageUrl;
    return (
      <Upload
        name="avatar"
        listType="picture-card"
        className="avatar-uploader"
        showUploadList={false}
        action={this.upload}
        beforeUpload={beforeUpload}
        onChange={this.handleChange}
      >
        {imageUrl ? <img src={imageUrl} alt="avatar" /> : uploadButton}
      </Upload>
    );
  }
}
