import * as React from "react";
import * as _ from "lodash";
import { DragDropContext } from "react-beautiful-dnd";
import styled from "styled-components";
import Column, { ColContainer } from "./Column";
import { IExercise, IPrompt, IWorkbook } from "../../firebase/models";
import { CreateExercise } from "../ExerciseStudio/CreateExercise";
// import { Icon } from "antd";
// import { ExerciseStudioForm } from "../ExerciseStudio/ExerciseStudioForm";
// import { IPrompt } from "../../firebase/models";

const Container = styled.div``;

const Wrapper = styled.div``;

const Padding = styled.div`
  padding: 8px;
  width: 100%;
`;

interface InterfaceProps {
  exercises: Record<string, IExercise>;
  prompts: Record<string, IPrompt>;
  workbook: IWorkbook;
  exerciseOrder: string[];
  handleDragEnd: (record: any) => void;
}

export class DragDrop extends React.Component<InterfaceProps> {
  render() {
    const { workbook, handleDragEnd } = this.props;
    const { prompts, exerciseOrder } = this.props;
    return (
      <Wrapper>
        <DragDropContext onDragEnd={handleDragEnd}>
          <Container>
            {exerciseOrder.map(exerciseKey => {
              const exercise = this.props.exercises[exerciseKey];
              const filteredPrompts = _.sortBy(
                _.values(prompts),
                "priority"
              ).filter(prompt => prompt.exercise === exerciseKey);
              return (
                <Column
                  key={exercise.name}
                  workbook={workbook}
                  exercise={exercise}
                  prompts={filteredPrompts}
                />
              );
            })}
            <ColContainer>
              <Padding>
                <CreateExercise
                  exerciseCount={exerciseOrder.length}
                  workbook={workbook}
                />
              </Padding>
            </ColContainer>
          </Container>
        </DragDropContext>
      </Wrapper>
    );
  }
}
