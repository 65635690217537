import * as React from "react";
import * as routes from "../../constants/routes";
import { auth, db } from "../../firebase";
import { RouteComponentProps, withRouter } from "react-router";
import { Button, Form, Input } from "antd";
import { IOrganization } from "../../firebase/models";
import { doAddOrganizationUser } from "../../firebase/db";

interface InterfaceProps extends RouteComponentProps<{}> {
  email?: string;
  error?: any;
  passwordOne?: string;
  passwordTwo?: string;
  username?: string;
  organization: Partial<IOrganization>;
}

interface InterfaceState {
  email: string;
  error: any;
  passwordOne: string;
  passwordTwo: string;
  username: string;
}

class SignUpForm extends React.Component<InterfaceProps, InterfaceState> {
  private static INITIAL_STATE = {
    email: "",
    error: null,
    passwordOne: "",
    passwordTwo: "",
    username: ""
  };

  private static propKey(propertyName: string, value: any): object {
    return { [propertyName]: value };
  }

  constructor(props: InterfaceProps) {
    super(props);
    this.state = { ...SignUpForm.INITIAL_STATE };
  }

  public onSubmit(event: any) {
    event.preventDefault();

    const { email, passwordOne, username } = this.state;
    const { history, organization } = this.props;

    auth
      .doCreateUserWithEmailAndPassword(email, passwordOne, username)
      .then((authUser: any) => {
        // Create a user in your own accessible Firebase Database too
        db.doCreateUser(
          authUser.user.uid,
          username,
          email,
          new Date().toString(),
          "customer"
        )
          .then(() => {
            doAddOrganizationUser(organization, authUser.user.uid);

            this.setState(() => ({ ...SignUpForm.INITIAL_STATE }));
            history.push(routes.HOME);
          })
          .catch(error => {
            this.setState(SignUpForm.propKey("error", error));
          });
      })
      .catch(error => {
        this.setState(SignUpForm.propKey("error", error));
      });
  }

  public render() {
    const { username, email, passwordOne, passwordTwo, error } = this.state;

    const isInvalid =
      passwordOne !== passwordTwo ||
      passwordOne === "" ||
      email === "" ||
      username === "";

    return (
      <Form onSubmit={event => this.onSubmit(event)}>
        <Form.Item label={"Full Name"}>
          <Input
            value={username}
            onChange={event => this.setStateWithEvent(event, "username")}
            type="text"
          />
        </Form.Item>

        <Form.Item label={"Email Address"}>
          <Input
            value={email}
            onChange={event => this.setStateWithEvent(event, "email")}
            type="text"
          />
        </Form.Item>

        <Form.Item label={"Password"}>
          <Input
            value={passwordOne}
            onChange={event => this.setStateWithEvent(event, "passwordOne")}
            type="password"
          />
        </Form.Item>

        <Form.Item label={"Confirm Password"}>
          <Input
            value={passwordTwo}
            onChange={event => this.setStateWithEvent(event, "passwordTwo")}
            type="password"
          />
        </Form.Item>
        <Button
          type="primary"
          block={true}
          disabled={isInvalid}
          htmlType="submit"
        >
          Sign Up
        </Button>

        {error && <p>{error.message}</p>}
      </Form>
    );
  }

  private setStateWithEvent(event: any, columnType: string) {
    this.setState(SignUpForm.propKey(columnType, (event.target as any).value));
  }
}

export const WrappedSignUpForm = withRouter(SignUpForm);
