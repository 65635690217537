import * as React from "react";
import { Button, Modal } from "antd";
import styled from "styled-components";
import { TeamMembers } from "./TeamMembers";
import { Link } from "react-router-dom";
import * as routes from "../../constants/routes";
import { ITeam, IUser } from "../../firebase/models";

interface Interface {
  teams: ITeam[];
  authUser: IUser;
}

const Container = styled.div`
  margin-top: 10px;
`;

export class TeamModal extends React.Component<Interface> {
  state = {
    visible: false
  };

  hide = () => {
    this.setState({
      visible: false
    });
  };

  show = () => {
    this.setState({
      visible: true
    });
  };

  render() {
    return (
      <div>
        <Container>
          <Button block={true} onClick={this.show} type="primary">
            Need Help?
          </Button>
        </Container>
        <Modal
          width={1100}
          title={null}
          footer={null}
          onCancel={this.hide}
          visible={this.state.visible}
        >
          <h4>Your Advisor/Facilitator Team</h4>
          <TeamMembers teams={this.props.teams} />
          &nbsp;
          {this.props.authUser.role === "ADMIN" && (
            <Link to={routes.STUDIO}>
              <Button type={"primary"} icon={"file-add"}>
                Admin Page
              </Button>
            </Link>
          )}
        </Modal>
      </div>
    );
  }
}
