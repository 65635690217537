import * as React from "react";
import { auth } from "../firebase";
import { Button } from "antd";
import styled from "styled-components";
const Container = styled.div`
  margin-top: 10px;
`;
export const SignOutButton = () => (
  <Container>
    <Button block={true} type="danger" icon="logout" onClick={auth.doSignOut}>
      Sign Out
    </Button>
  </Container>
);
