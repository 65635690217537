import * as React from "react";
import { Button, Form, Input } from "antd";
import { auth } from "../../firebase";
import styled from "styled-components";

const SuccessMessage = styled.div`
  font-weight: 700;
  font-size: 24px;
  color: #1d2b4c;
  line-height: 1.1;
  font-family: Frank Ruhl Libre, serif;
`;

interface InterfaceProps {}

interface InterfaceState {
  email: string;
  submitted: boolean;
}

export class SignInWithEmailForm extends React.Component<
  InterfaceProps,
  InterfaceState
> {
  constructor(props: InterfaceProps) {
    super(props);
    this.state = { email: "", submitted: false };
  }

  public onSubmit = (event: any) => {
    event.preventDefault();
    this.setState({ submitted: true });
    localStorage.setItem("emailForSignIn", this.state.email);
    auth.doSignInWithLink(this.state.email);
  };

  private static propKey(propertyName: string, value: any): object {
    return { [propertyName]: value };
  }

  private setStateWithEvent(event: any, columnType: string): void {
    this.setState(
      SignInWithEmailForm.propKey(columnType, (event.target as any).value)
    );
  }

  render() {
    const { email, submitted } = this.state;
    const isInvalid = email === "";
    return submitted ? (
      <SuccessMessage>Please check your email to login.</SuccessMessage>
    ) : (
      <Form onSubmit={event => this.onSubmit(event)}>
        <Form.Item label={"Email Address:"}>
          <Input
            value={email}
            onChange={event => this.setStateWithEvent(event, "email")}
            type="email"
            required
          />
        </Form.Item>
        <Button
          type="primary"
          block={true}
          disabled={isInvalid}
          htmlType="submit"
        >
          Sign In
        </Button>
      </Form>
    );
  }
}
