import * as React from "react";
import { Tabs, Typography } from "antd";
import { IOrganization, IWorkbook } from "../../firebase/models";
import {
  doCreateOrganization,
  doDeleteOrganization,
  doUpdateOrganization
} from "../../firebase/db";
import * as _ from "lodash";
import { DeleteOrganization } from "./DeleteOrganization";
import { OrganizationForm } from "./OrganizationForm";
import { GridRow } from "../../components/GridRow";
import { TeamStudio } from "../TeamStudio";
const TabPane = Tabs.TabPane;

interface InterfaceProps {
  organizations: Record<string, IOrganization>;
  workbooks: IWorkbook[];
}

interface InterfaceState {
  activeKey?: string;
  organizations: Record<string, IOrganization>;
}

export class OrganizationTabs extends React.Component<
  InterfaceProps,
  InterfaceState
> {
  constructor(props: any) {
    super(props);
    this.state = {
      // activeKey: this.props.organizations[0].key,
      organizations: this.props.organizations
    };
  }

  onChange = (activeKey: string) => {
    this.setState({ activeKey: undefined });
    this.setState({ activeKey });
  };

  onEdit = (targetKey: any, action: any) => {
    this[action](targetKey);
  };

  add = () => {
    doCreateOrganization({ name: "New Organization" });
  };

  remove = (targetKey: string) => {
    const { organizations } = this.state;
    doDeleteOrganization(organizations[targetKey]);
  };

  handleChange = (name: string, organization: IOrganization) => {
    const { organizations } = this.state;

    const newOrganization = { ...organization, name };
    doUpdateOrganization(newOrganization);

    this.setState({
      organizations: {
        ...organizations,
        [organization.key]: { ...newOrganization }
      }
    });
  };

  render() {
    const { organizations, activeKey } = this.state;
    const { workbooks } = this.props;

    return (
      <div>
        <Tabs
          onChange={this.onChange}
          activeKey={this.state.activeKey}
          type="editable-card"
          tabPosition={"left"}
          onEdit={this.onEdit}
        >
          {_.values(organizations).map(organization => (
            <TabPane
              tab={
                <span>
                  <Typography.Text
                    editable={{
                      onChange: (name: string) =>
                        this.handleChange(name, organization)
                    }}
                  >
                    {organization.name}
                  </Typography.Text>{" "}
                  <DeleteOrganization organization={organization} />
                </span>
              }
              closable={false}
              key={organization.key}
            >
              {activeKey && (
                <GridRow
                  cols={[
                    <OrganizationForm
                      key="form"
                      workbooks={workbooks}
                      organization={organization}
                    />,
                    <TeamStudio organization={organization} key="team" />
                  ]}
                />
              )}
            </TabPane>
          ))}
        </Tabs>
      </div>
    );
  }
}
