import * as React from "react";
import { Row, Col } from "antd";
import { ReactNode } from "react";

interface InterfaceProps {
  cols: ReactNode[];
  type?: "flex";
  align?: "top" | "middle" | "bottom";
  justify?: "start" | "center" | "end";
  spans?: number[] /* AntD Uses a 24 Col System, Spans Should Probably Add Up To That */;
}

const COL_COUNT = 24;

/* cols are equidistant by default on large screens, but stretch the screen on tablet screens or below */
/* use the spans prop for custom sizes */
export const GridRow = (props: InterfaceProps) => {
  return (
    <Row
      type={props.type}
      align={props.align}
      justify={props.justify}
      gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
    >
      {props.cols.map((col, idx) => {
        const span = props.spans
          ? props.spans[idx]
          : COL_COUNT / props.cols.length;
        return (
          <Col key={idx} sm={24} md={COL_COUNT} lg={span}>
            {col}
          </Col>
        );
      })}
    </Row>
  );
};
