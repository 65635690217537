import * as React from "react";
import { firebase } from "../firebase";
import { AuthUserContext } from "./AuthUserContext";
import { IUser } from "./models";
import { onceGetUser } from "./db";

interface InterfaceProps {
  authUser?: IUser;
}

interface InterfaceState {
  authUser: IUser | null;
}

export const withAuthentication = (Component: any) => {
  class WithAuthentication extends React.Component<
    InterfaceProps,
    InterfaceState
  > {
    constructor(props: any) {
      super(props);

      this.state = {
        authUser: null
      };
    }

    public componentDidMount() {
      firebase.auth.onAuthStateChanged(async authUser => {
        if (authUser) {
          const user = await onceGetUser(authUser);
          this.setState(() => ({ authUser: { ...user.val(), ...authUser } }));
        } else {
          this.setState(() => ({ authUser: null }));
        }
      });
    }

    public render() {
      const { authUser } = this.state;

      return (
        <AuthUserContext.Provider value={authUser}>
          <Component />
        </AuthUserContext.Provider>
      );
    }
  }

  return WithAuthentication;
};
