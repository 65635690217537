import * as React from "react";
import { IExercise, IPrompt, IWorkbook } from "../../firebase/models";
import * as db from "../../firebase/db";
import { PromptStudioForm } from "./PromptStudioForm";

interface InterfaceProps {
  fetchData?: () => void;
  workbook: IWorkbook;
  exercise: IExercise;
  priority?: number;
}

export class CreatePrompt extends React.Component<InterfaceProps> {
  create = (prompt: Partial<IPrompt>) => {
    db.doCreatePrompt({
      ...prompt,
      priority: this.props.priority || 1,
      exercise: this.props.exercise.key,
      workbook: this.props.workbook.key
    });
  };

  render() {
    return (
      <div>
        <PromptStudioForm handleSubmit={this.create} />
      </div>
    );
  }
}
