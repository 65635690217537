import { Button, Col, Icon, Row } from "antd";
import { UploadFile } from "antd/lib/upload/interface";
import * as _ from "lodash";
import * as React from "react";
import { isMobile } from "react-device-detect";
import styled from "styled-components";
import { Collapse } from "../../components/Collapse";
import { MarkdownPreview } from "../../components/MarkdownPreview";
import { PictureWall } from "../../components/PictureWall";
import { doLockExercise } from "../../firebase/db";
import {
  IAnswer,
  IExercise,
  IPrompt,
  IUser,
  StepEnum,
} from "../../firebase/models";
import { subDomain } from "../../firebase/utils";
import { ExerciseHeader } from "./ExerciseHeader";
import { LockUnlock } from "./LockUnlock";
import { Message } from "./Message";
import { FlexDivideHeader, Label, Prompt } from "./Prompt";

export const Container = styled.div`
  + .exercise {
    margin-top: 20px !important;
  }

  .ant-collapse {
    border: 0 !important;
    background: #f0f7f6;
    border-radius: 0 !important;
    margin-bottom: 16px;
  }

  .ant-collapse-content {
    padding-top: 20px;
  }

  .ant-alert {
    background: #f0f7f6;
    border: 1px solid #d9d9d9;
    margin-top: 25px;
  }

  .ant-collapse-content-box {
    padding-top: 0 !important;
  }
`;

export const ButtonContainer = styled.div`
  margin-top: 10px;
  text-align: center;
`;
export const PicContainer = styled.div`
  margin-top: 30px;
`;

export const TextCenter = styled.div`
  text-align: center;
`;

interface InterfaceProps {
  exercise: IExercise;
  prompts: IPrompt[];
  allPrompts: IPrompt[];
  currentPage: number;
  answers: Record<string, IAnswer>;
  uploads: Record<string, Record<string, UploadFile>>;
  authUser: IUser;
  dontShareFiles: string[];

  shareOrUnShare: (value: boolean) => void;
  shareFile: (id: string) => void;
  hideFile: (id: string) => void;
  sharedExercises: IExercise[];
  updateSharedContent?: (prompt: IPrompt, value: string) => void;
}

interface InterfaceState {
  collapsed: boolean;
  locked?: boolean;
  lockedAtSynthesis?: boolean;
}

export class Exercise extends React.Component<InterfaceProps, InterfaceState> {
  setInitialLockedState = (locked?: boolean) => {
    let actualLocked = true;
    if (locked === undefined) {
      actualLocked = subDomain() === "introduction" ? false : true;
    } else {
      actualLocked = locked;
    }
    return actualLocked;
  };

  toggleLocked = (exercise: IExercise, authUser: IUser) => {
    const lockedState = !this.state.locked;
    const element = document.getElementById(exercise.key);
    if (!lockedState) {
      this.setState({ locked: lockedState });
      doLockExercise(exercise, authUser, lockedState);
    } else if (isMobile) {
      lockedState && element && element.scrollIntoView();
      this.setState({ locked: lockedState });
      doLockExercise(exercise, authUser, lockedState);
    } else {
      let scrollTimeout: any;
      lockedState && element && element.scrollIntoView({ behavior: "smooth" });
      const setState = () => this.setState.bind(this);
      addEventListener("scroll", function _listener() {
        clearTimeout(scrollTimeout);
        scrollTimeout = setTimeout(() => {
          removeEventListener("scroll", _listener);
          setState()({ locked: lockedState });
          doLockExercise(exercise, authUser, lockedState);
        }, 100);
      });
    }
  };

  toggleLockedAtSynthesis = () => {
    const lockedState = !this.state.lockedAtSynthesis;
    this.setState({ lockedAtSynthesis: lockedState });
  };

  state = {
    collapsed: false,
    locked: this.setInitialLockedState(this.props.exercise.locked),
    lockedAtSynthesis: false,
  };

  render() {
    const { collapsed } = this.state;
    const sharedExerciseKeys = this.props.sharedExercises.map(
      (exercise) => exercise.key
    );

    const isLocked =
      (this.props.currentPage === StepEnum.Work && this.state.locked) ||
      (this.props.currentPage === StepEnum.Synthesis &&
        this.state.lockedAtSynthesis) ||
      !sharedExerciseKeys.includes(this.props.exercise.key);

    const panelKey = "1";

    const uploads = this.props.uploads || {};

    const toggleLocked = () =>
      this.props.currentPage === StepEnum.Work
        ? this.toggleLocked(this.props.exercise, this.props.authUser)
        : this.toggleLockedAtSynthesis();

    const lockUnlock = (
      <LockUnlock
        currentPage={this.props.currentPage}
        isLocked={isLocked}
        toggleLocked={toggleLocked}
      />
    );

    const fileList = _.values(uploads[this.props.exercise.key] || {});
    return (
      <Container id={this.props.exercise.key} className="exercise">
        <Collapse
          locked={isLocked}
          header={
            <ExerciseHeader
              collapsed={collapsed}
              isLocked={isLocked}
              shareOrUnShare={this.props.shareOrUnShare}
              prompts={this.props.allPrompts}
              authUser={this.props.authUser}
              lockUnlock={lockUnlock}
              currentPage={this.props.currentPage}
              title={this.props.exercise.name}
            />
          }
          key={panelKey}
        >
          {this.props.exercise.description &&
            this.props.currentPage === StepEnum.Work && (
              <Message>
                <MarkdownPreview
                  markdown={this.props.exercise.description || ""}
                />
              </Message>
            )}
          {this.props.exercise.reflection &&
            this.props.currentPage === StepEnum.Synthesis && (
              <Message>
                <MarkdownPreview
                  markdown={this.props.exercise.reflection || ""}
                />
              </Message>
            )}
          {this.props.exercise.reviewTips &&
            this.props.currentPage === StepEnum.Review && (
              <Message>
                <MarkdownPreview
                  markdown={this.props.exercise.reviewTips || ""}
                />
              </Message>
            )}
          <div className={this.props.prompts.length ? "tooltip-7" : ""}>
            {this.props.prompts.map((prompt, index) => {
              return (
                <div key={index}>
                  <Prompt
                    updateSharedContent={this.props.updateSharedContent}
                    exercise={this.props.exercise}
                    authUser={this.props.authUser}
                    index={index + 1}
                    disabled={isLocked}
                    currentPage={this.props.currentPage}
                    key={prompt.key}
                    prompt={prompt}
                    answer={this.props.answers[prompt.key]}
                  />
                </div>
              );
            })}
          </div>

          {!this.props.exercise.carryForward && (
            <PicContainer>
              {this.props.currentPage === StepEnum.Review &&
              fileList.length === 0 ? null : (
                <Row type="flex" align="top">
                  <Col sm={2}>
                    <TextCenter>
                      <Button type="primary" shape="circle" size="large">
                        <Icon type={"camera"} style={{ fontSize: ".9em" }} />
                      </Button>
                    </TextCenter>
                  </Col>
                  <Col sm={22}>
                    <FlexDivideHeader>
                      <Label>
                        {this.props.currentPage === StepEnum.Review
                          ? "Choose which documents you wish to share."
                          : "Add your pictures or supporting documents."}
                      </Label>
                    </FlexDivideHeader>

                    <PictureWall
                      authUser={this.props.authUser}
                      shareFile={this.props.shareFile}
                      dontShareFiles={this.props.dontShareFiles}
                      hideFile={this.props.hideFile}
                      exercise={this.props.exercise}
                      currentPage={this.props.currentPage}
                      fileList={uploads[this.props.exercise.key] || {}}
                    />
                  </Col>
                </Row>
              )}
            </PicContainer>
          )}
          <div>
            <ButtonContainer>
              <LockUnlock
                currentPage={this.props.currentPage}
                isLocked={isLocked}
                toggleLocked={toggleLocked}
              />
            </ButtonContainer>
          </div>
        </Collapse>
      </Container>
    );
  }
}
