import * as React from "react";
import styled from "styled-components";
import { DraggableBox } from "./DraggableBox";
import { Droppable } from "react-beautiful-dnd";
import { IExercise, IPrompt, IWorkbook } from "../../firebase/models";
import { Button, Checkbox, Icon, Switch, Row, Col } from "antd";
import { CreatePrompt } from "../PromptStudio/CreatePrompt";
import { doUpdateExercise } from "../../firebase/db";
import { DeleteExercise } from "../ExerciseStudio/DeleteExercise";
import { priorityNumber } from "../../firebase/utils";
import { MarkdownEditorModal } from "../../components/MarkdownEditorModal";
import { MarkdownPreview } from "../../components/MarkdownPreview";
import { Message } from "../Workbook/Message";
import { PriorityChooser } from "./PriorityChooser";

interface IListProps {
  isDraggingOver: boolean;
}

interface IColContainerProps {
  background?: string;
}

interface InterfaceProps {
  exercise: IExercise;
  prompts: IPrompt[];
  workbook: IWorkbook;
}

export const ColContainer = styled.div`
  margin-bottom: 50px
  border: 1px solid lightgrey;
  border-radius: 6px;
  display: flex;
  position: relative;
  padding-bottom: 80px;
  background: ${(props: IColContainerProps) => props.background}
`;

const Body = styled.div`
  width: 100%;
`;
const Title = styled.div`
  font-weight: 600;
  padding: 68px;
  font-size: 1.2em;
  position: relative;
  background: #f0f7f6;
  padding: 12px 16px 12px 16px;
  color: rgba(0, 0, 0, 0.85);
  cursor: pointer;

  h1 {
    margin-top: 0;
    margin-bottom: 0;
    line-height: 72px;
  }
  .ant-typography {
    padding-right: 50px;
    margin-bottom: 0;
  }

  .ant-typography-edit {
    position: absolute;
    right: 37px;
    top: 10px;
  }
`;

const Options = styled.div`
  padding: 0 8px 8px 8px;
`;
const Footer = styled.div`
  font-weight: 600;
  padding: 16px 8px 8px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
`;
const List = styled.div`
  padding: 8px;
  transition: background-color 0.2s ease;
  background-color: ${(props: IListProps) =>
    props.isDraggingOver ? "skyblue" : "white"}
  flex-grow: 1;
`;
const BelowTheFold = styled.div`
  position: absolute;
  bottom: 0px;
  background: white;
  left: 8px;
  right: 8px;
`;

const RightSide = styled.div`
  margin-bottom: 10px;
`;

export default class Column extends React.Component<InterfaceProps> {
  state = {
    exercise: this.props.exercise,
    showForm: false
  };

  toggleForm = () => this.setState({ showForm: !this.state.showForm });

  changeTitle = (name: string) => {
    const { exercise } = this.state;
    const newExercise = {
      ...exercise,
      name
    };

    doUpdateExercise(newExercise);
    this.setState({ exercise: newExercise });
  };

  changeDescription = (description: string) => {
    const { exercise } = this.state;
    const newExercise = {
      ...exercise,
      description
    };

    doUpdateExercise(newExercise);
    this.setState({ exercise: newExercise });
  };

  changeReflection = (reflection: string) => {
    const { exercise } = this.state;
    const newExercise = {
      ...exercise,
      reflection
    };

    doUpdateExercise(newExercise);
    this.setState({ exercise: newExercise });
  };

  toggleCarryForward = (carryForward: boolean) => {
    const { exercise } = this.state;
    const newExercise = {
      ...exercise,
      carryForward
    };
    doUpdateExercise(newExercise);
    this.setState({ exercise: newExercise });
  };

  toggleRecommendSharing = (recommendSharing: boolean) => {
    const { exercise } = this.state;
    const newExercise = {
      ...exercise,
      recommendSharing
    };
    doUpdateExercise(newExercise);
    this.setState({ exercise: newExercise });
  };

  toggleSynthesis = (synthesis: boolean) => {
    const { exercise } = this.state;
    const newExercise = {
      ...exercise,
      synthesis
    };
    doUpdateExercise(newExercise);
    this.setState({ exercise: newExercise });
  };

  render() {
    const { showForm, exercise } = this.state;
    const { prompts, workbook } = this.props;
    return (
      <ColContainer>
        <Body>
          <Title>
            <h1>
              <MarkdownPreview markdown={exercise.name} />
            </h1>

            <Row type={"flex"} justify="space-between">
              <Col>
                <MarkdownEditorModal
                  value={exercise.name}
                  save={this.changeTitle}
                >
                  <RightSide>
                    <Button icon="edit" type="primary">
                      Edit Exercise Name
                    </Button>
                  </RightSide>
                </MarkdownEditorModal>
              </Col>
              <Col>
                <PriorityChooser
                  exercise={exercise}
                  priority={exercise.priority}
                />
              </Col>
              <Col>
                <DeleteExercise exercise={exercise}>
                  <Button icon="delete" type="danger">
                    Delete Exercise
                  </Button>
                </DeleteExercise>
              </Col>
            </Row>
          </Title>
          <Options>
            <Message>
              <h3>Step 1 Exercise Introduction</h3>
              <MarkdownPreview markdown={exercise.description} />
              <MarkdownEditorModal
                value={exercise.description}
                save={this.changeDescription}
              >
                <RightSide>
                  <Button icon="edit" type="primary">
                    Edit Step 1 Exercise Introduction
                  </Button>
                </RightSide>
              </MarkdownEditorModal>
            </Message>

            <br />
            <Message>
              <h3>Step 2 Exercise Reflection Tips</h3>
              <MarkdownPreview markdown={exercise.reflection} />
              <MarkdownEditorModal
                value={exercise.reflection}
                save={this.changeReflection}
              >
                <RightSide>
                  <Button icon="edit" type="primary">
                    Edit Step 2 Exercise Reflection Tips
                  </Button>
                </RightSide>
              </MarkdownEditorModal>
            </Message>
          </Options>

          <Options>
            <Checkbox.Group>
              <Switch
                key="synthesis"
                checked={exercise.synthesis}
                onChange={e => this.toggleSynthesis(e)}
              />{" "}
              Has Step 2 Synthesis &nbsp;&nbsp;&nbsp;&nbsp;
              <Switch
                key="carryForward"
                checked={exercise.carryForward}
                onChange={e => this.toggleCarryForward(e)}
              />{" "}
              Step 2 Show Prompts &nbsp;&nbsp;&nbsp;&nbsp;
              <Switch
                key="sharing"
                checked={exercise.recommendSharing}
                onChange={e => this.toggleRecommendSharing(e)}
              />{" "}
              Recommend Sharing
            </Checkbox.Group>
          </Options>
          <Droppable droppableId={exercise.key} type="PROMPT">
            {(provided, snapshot) => (
              <List
                ref={provided.innerRef}
                {...provided.droppableProps}
                isDraggingOver={snapshot.isDraggingOver}
              >
                {prompts.map((prompt, index) => (
                  <DraggableBox
                    key={prompt.key}
                    prompt={prompt}
                    index={index}
                  />
                ))}
                {provided.placeholder}
              </List>
            )}
          </Droppable>
        </Body>
        <Footer>
          {showForm ? (
            <BelowTheFold>
              <CreatePrompt
                priority={priorityNumber(prompts)}
                exercise={exercise}
                workbook={workbook}
              />
            </BelowTheFold>
          ) : (
              <a onClick={this.toggleForm}>
                <Icon type={"plus"} /> Add Another Prompt
              </a>
            )}
        </Footer>
      </ColContainer>
    );
  }
}
