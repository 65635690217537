import * as React from "react";
import { TeamMember } from "./TeamMember";
import styled from "styled-components";
import { GridRow } from "../../components/GridRow";
import { ITeam } from "../../firebase/models";

const Container = styled.div`
  list-style: none;
  padding: 10px;
  border-radius: 2px;
  background: #59a9a0;
  color: white;
  margin: 20px 0;
`;

interface InterfaceProps {
  teams: ITeam[];
}

class TeamMembersComponent extends React.Component<InterfaceProps> {
  render() {
    const { teams = [] } = this.props;

    return (
      <div>
        <Container>
          <GridRow
            spans={[12, 12, 12, 12, 12, 12, 12, 12]}
            cols={teams.map(team => (
              <TeamMember key={team.key} team={team} />
            ))}
          />
        </Container>
      </div>
    );
  }
}

export const TeamMembers = TeamMembersComponent;
