import * as React from "react";
import {
  IAnswer,
  IPrompt,
  ITeam,
  IUser,
  IWorkbook,
  StepEnum
} from "../../firebase/models";
import { Prompt } from "./Prompt";
import { Affix } from "antd";
import { TeamModal } from "../Team/TeamModal";
import media from "../../utils/media-query-template.js";
const myMedia = media as {
  desktop: (...args: any[]) => string;
  tablet: (...args: any[]) => string;
  phone: (...args: any[]) => string;
};
import { createGlobalStyle } from "styled-components";

import styled from "styled-components";
import { SignOutButton } from "../../components/SignOutButton";

const Name = styled.div`
  margin-top: 35px;
  background: white;
`;
const Container = styled.div<{ showMobile?: boolean }>`
  display: ${(props: any) => (props.showMobile ? "none" : "block")};

  ${myMedia.desktop`
         display: ${(props: any) => (props.showMobile ? "block" : "none")};
      `}

  .mde-preview-content {
    padding-top: 20px;
    font-weight: bold;
    font-family: Frank Ruhl Libre, serif;
  }
`;

const MobileTip = styled.div`
  display: none;
  ${myMedia.desktop`
        display: block;
      `}
`;

const GlobalStyle = createGlobalStyle`
 .ant-affix {
     ${myMedia.desktop`
        position: relative !important;
      `}
   }
`;

interface InterfaceProps {
  workbook: IWorkbook;
  answers: Record<string, IAnswer>;
  authUser: IUser;
  teams: ITeam[];
  showMobile: boolean;
}

export const NotePad = (props: InterfaceProps) => {
  const notePrompt: IPrompt = {
    name: "",
    workbook: props.workbook.key,
    key: props.workbook.key + "notes",
    exercise: props.workbook.key + "notes"
  };

  return (
    <Affix offsetTop={10}>
      <Container showMobile={props.showMobile}>
        <GlobalStyle />

        <div className={props.showMobile ? "" : "tooltip-4"}>
          <Name>
            <b>My Notes & Reminders</b>
            <MobileTip>
              Tip: Capture your responses or thoughts on the go. Then move your
              content to the appropriate response areas when you have more time.
            </MobileTip>
          </Name>
          <Prompt
            authUser={props.authUser}
            currentPage={StepEnum.Work}
            key={notePrompt.key}
            prompt={notePrompt}
            answer={props.answers[notePrompt.key]}
          />
        </div>
        <div className={props.showMobile ? "" : "tooltip-5"}>
          <TeamModal authUser={props.authUser} teams={props.teams} />
        </div>
        <SignOutButton />
      </Container>
    </Affix>
  );
};
