import * as React from "react";
import { db } from "../../firebase";
import { withAuthorization } from "../../firebase/withAuthorization";
import { Redirect, RouteComponentProps } from "react-router";
import { IWorkbook, IUser } from "../../firebase/models";
import { WorkbookTabs } from "./WorkbookTabs";
import { StudioNav } from "./StudioNav";
import * as _ from "lodash";
import { withKeys } from "../../firebase/utils";
import * as routes from "../../constants/routes";

interface InterfaceState {
  workbooks: Record<string, IWorkbook>;
}

interface IMatchParams {
  key: string;
}

interface InterfaceProps extends RouteComponentProps<IMatchParams> {
  authUser: IUser;
}

class WorkbookStudioComponent extends React.Component<InterfaceProps, InterfaceState> {
  state = {
    workbooks: {}
  };

  fetchData = async () => {
    const workbooks = await db.onceGetWorkbooks();
    this.setState({
      workbooks: withKeys(workbooks)
    });

    db.onAddedGetWorkbooks((snapshot: any) => {
      this.setState({
        workbooks: {
          ...this.state.workbooks,
          [snapshot.key]: {
            ...snapshot.val(),
            key: snapshot.key
          }
        }
      });
    });
  };

  async componentDidMount() {
    this.fetchData();
  }

  render() {
    const { workbooks } = this.state;
    const { authUser } = this.props;

    if (!authUser.role || authUser.role !== "ADMIN"){
      return <Redirect to={routes.SIGN_IN} />
    }

    return (
      <div>
        <StudioNav />
        <h2>Workbook Editor</h2>

        <WorkbookTabs
          key={Object.keys(workbooks).length}
          workbooks={workbooks}
        />
      </div>
    );
  }
}

const authCondition = (authUser: any) => !!authUser;

export const WorkbookStudio = withAuthorization(authCondition)(
  WorkbookStudioComponent
);
