import * as React from "react";
import { Button, Form, Input, message } from "antd";
import { ITeam } from "../../firebase/models";
import styled from "styled-components";
import { doUpdateTeam } from "../../firebase/db";
import * as db from "../../firebase/db";
import { Avatar } from "../../components/Avatar";

interface InterfaceProps {
  team: ITeam;
}

type InterfaceState = ReturnType<typeof getInitialState>;

const StyledForm = styled(Form)``;

const getInitialState = (props: InterfaceProps) => {
  return {
    name: props.team.name,
    key: props.team.key,
    phone: props.team.phone,
    email: props.team.email,
    role: props.team.role,
    organization: props.team.organization
  };
};

export class TeamForm extends React.Component<InterfaceProps, InterfaceState> {
  readonly state = getInitialState(this.props);

  handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement;
    this.setState({
      [target.name]: target.value
    } as any);
  };

  handleUpload = (imageUrl: string) => {
    return db.doUpdateTeam({ ...this.props.team, imageUrl });
  };

  handleSubmit = () => {
    doUpdateTeam(this.state)
      .then(() => message.success("Team Updated!"))
      .catch(() => message.error("Team Failed to Update"));
  };

  render() {
    const { team } = this.props;

    const { phone, email, role, name } = this.state;

    return (
      <div>
        <Avatar
          dbRef={team.key}
          handleUpload={this.handleUpload}
          imageUrl={team.imageUrl}
        />

        <StyledForm layout={"horizontal"}>
          <Form.Item label="Full Name">
            <Input
              value={name}
              name="name"
              onChange={this.handleChange}
              type="text"
            />
          </Form.Item>

          <Form.Item label="Role">
            <Input
              value={role}
              name="role"
              onChange={this.handleChange}
              type="text"
            />
          </Form.Item>

          <Form.Item label="Phone">
            <Input
              value={phone}
              name="phone"
              onChange={this.handleChange}
              type="text"
            />
          </Form.Item>

          <Form.Item label="Email">
            <Input
              value={email}
              name="email"
              onChange={this.handleChange}
              type="text"
            />
          </Form.Item>

          <Form.Item>
            <Button type="primary" onClick={this.handleSubmit}>
              Update
            </Button>
          </Form.Item>
        </StyledForm>
      </div>
    );
  }
}
