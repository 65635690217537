import { Button, Form, Input, Modal, Spin } from "antd";
import { UploadFile } from "antd/lib/upload/interface";
import * as _ from "lodash";
import * as React from "react";
import Joyride from "react-joyride";
import { Route, RouteComponentProps, withRouter } from "react-router";
import { AuthUserContext } from "../../firebase/AuthUserContext";
import * as db from "../../firebase/db";
import { onceGetTeams } from "../../firebase/db";
import {
  IAnswer,
  IExercise,
  IOrganization,
  IPrompt,
  ITeam,
  IUser,
  IWorkbook,
  StepEnum,
} from "../../firebase/models";
import {
  sortByPriority,
  subDomain,
  withKeys,
  withKeysObject,
} from "../../firebase/utils";
import { withAuthorization } from "../../firebase/withAuthorization";
import { Exercises } from "./Exercises";
// import { Link } from "react-router-dom";
import { NextPage } from "./NextPage";
import { WorkbookSteps } from "./WorkbookSteps";

interface IMatchParams {
  page: string;
  key: string;
}

interface InterfaceProps extends RouteComponentProps<IMatchParams> {
  authUser: IUser;
}

interface InterfaceState {
  workbook: IWorkbook;
  exercises: IExercise[];
  prompts: IPrompt[];
  answers: Record<string, IAnswer>;
  uploads: Record<string, Record<string, UploadFile>>;
  organization: IOrganization;
  teams: ITeam[];
  usernameLoading: boolean;
  usernameVisible: boolean;
}

export const consolidationPromptKey = "synthesis";

class WorkbookComponent extends React.Component<
  InterfaceProps,
  InterfaceState
> {
  constructor(props: any) {
    super(props);

    this.state = {
      workbook: { key: "", name: "" },
      exercises: [],
      prompts: [],
      answers: {},
      uploads: {},
      organization: { key: "", name: "", workbooks: {} },
      teams: [],
      usernameLoading: false,
      usernameVisible: false,
    };
  }

  componentDidMount() {
    // track last login
    db.doLoginUser(this.props.authUser.uid);

    this.fetchData().then((showUsernameModal) => {
      if (showUsernameModal) {
        this.showModal();
      }
    });

    this.props.history.listen((location: any, action: any) => {
      // TODO: Limit this fetching
      this.fetchData().then((showUsernameModal) => {
        if (showUsernameModal) {
          this.showModal();
        }
      });
    });
  }

  fetchData = async () => {
    const { match, authUser } = this.props;

    const workbookKey = match.params.key;

    const workbook = await db.onceGetWorkbook(workbookKey);
    const prompts = await db.onceGetWorkbookPrompts(workbookKey);
    const exercisesSnapshot = await db.onceGetWorkbookExercises(workbookKey);
    const user = await db.onceGetUser(authUser);
    const organizationSnapshot = await db.onceGetOrganization(subDomain());
    const organization = _.values(organizationSnapshot.val())[0] || { key: "" };

    const teams = await onceGetTeams(organization.key);
    const lockedExercises = user.val().lockedExercises || {};
    const exercises: IExercise[] = _.sortBy(
      _.values(withKeys(exercisesSnapshot)),
      "priority"
    );
    this.setState({
      exercises: exercises.map((exercise) => ({
        ...exercise,
        locked: lockedExercises[exercise.key],
      })),
      prompts: sortByPriority(_.values(withKeys(prompts))),
      answers: withKeysObject(user.val().answers),
      uploads: user.val().uploads,
      organization: _.values(organizationSnapshot.val())[0] || {
        key: "",
        workbooks: {},
      },
      teams: _.values(teams.val()),
      workbook: { ...workbook.val(), key: workbook.key },
    });
    // we want to return whether or not the username modal should be rendered.
    if (authUser.username) {
      // username is found, DONT show modal
      return false;
    }
    // no username show modal
    return true;
  };

  synthesisPromptsAll = (): IPrompt[] => {
    const { workbook, prompts, answers, exercises } = this.state;

    // For each synthesis exercise, create a custom synthesis prompt with a default value of some of the other prompts
    return exercises.map((exercise) => ({
      name: `Your Responses`,
      key: `${exercise.key}-synthesis`,
      exercise: `${exercise.key}`,
      workbook: workbook.key,
      synthesis: true,
      answers: prompts
        .filter((prompt) => prompt.exercise === exercise.key)
        .map((prompt) => answers[prompt.key])
        .filter((answer) => !!answer),
    }));
  };

  synthesisPrompts = (): IPrompt[] => {
    const { workbook, prompts, answers } = this.state;

    // For each synthesis exercise, create a custom synthesis prompt with a default value of some of the other prompts
    return this.synthesisExercises().map((exercise) => ({
      name: `Your Responses`,
      key: `${exercise.key}-synthesis`,
      exercise: `${exercise.key}`,
      workbook: workbook.key,
      synthesis: true,

      // used to generate default value before editing in Prompt.tsx
      answers: prompts
        .filter((prompt) => prompt.exercise === exercise.key)
        .map((prompt) => answers[prompt.key])
        .filter((answer) => !!answer),
    }));
  };

  synthesisExercises = (): IExercise[] => {
    const { exercises } = this.state;

    return exercises.filter((exercise) => exercise.synthesis);
  };

  consolidationExercise = (): IExercise => {
    const { workbook } = this.state;

    return {
      name: "Your Document Creation Space",
      workbook: workbook.key,
      key: "consolidation",
      description: "Consolidate your everything!",
    };
  };

  consolidationPrompt = (): IPrompt => {
    const { workbook } = this.state;
    const exercise = this.consolidationExercise();

    return {
      name: `Your Responses`,
      key: consolidationPromptKey,
      exercise: `${exercise.key}`,
      workbook: workbook.key,
      synthesis: true,
      // loop through each of the synthesisPrompts, grab their default answers and flatten
      answers: _.flatten(
        this.synthesisPrompts().map((prompt) => prompt.answers || [])
      ),
    };
  };

  carryForwardExercises = (): IExercise[] => {
    const { exercises } = this.state;

    return exercises.filter((exercise) => exercise.carryForward);
  };

  carryForwardPrompts = (): IPrompt[] => {
    const { prompts } = this.state;
    const keys = this.carryForwardExercises().map((e) => e.key);

    return prompts.filter((prompt) => keys.includes(prompt.exercise));
  };

  showModal = () => {
    this.setState({
      usernameVisible: true,
    });
  };

  handleOk = (e?: any) => {
    if (e) {
      e.preventDefault();
    }
    const usernameNode = document.querySelector(
      ".usernamefield"
    ) as HTMLInputElement;
    if (usernameNode) {
      const username = usernameNode.value;
      if (username) {
        // set username in firebase
        db.doUsernameUpdate(this.props.authUser.uid, username);
        // remove modal
        this.setState({ usernameLoading: false, usernameVisible: false });
      } else {
        this.setState({ usernameLoading: false });
      }
    } else {
      this.setState({ usernameLoading: false });
    }
  };

  render() {
    const { match } = this.props;
    const {
      workbook,
      exercises,
      prompts,
      answers,
      uploads,
      organization,
      teams,
      usernameLoading,
      usernameVisible,
    } = this.state;

    console.log(this.props);

    return (
      <>
        {!workbook || !exercises.length || !prompts.length ? (
          <Spin size="large" />
        ) : (
          <AuthUserContext.Consumer>
            {(authUser) => {
              if (!authUser) return null;
              return (
                <div>
                  <Modal
                    visible={usernameVisible}
                    title="Title"
                    onOk={this.handleOk}
                    closable={false}
                    // cancelButtonProps={{ style: { display: "none" } }}
                    footer={[
                      <Button
                        key="submit"
                        type="primary"
                        loading={usernameLoading}
                        onClick={this.handleOk}
                      >
                        Submit
                      </Button>,
                    ]}
                  >
                    <Form>
                      <Form.Item label={"Tell us your name:"}>
                        <Input type="text" className="usernamefield" required />
                      </Form.Item>
                    </Form>
                  </Modal>
                  <WorkbookSteps page={match.params.page} workbook={workbook} />
                  <Route
                    path={`${match.path}/:key/${StepEnum.Work}`}
                    component={() => (
                      <>
                        {subDomain() === "introduction" && !usernameVisible && (
                          <Joyride
                            continuous
                            scrollToFirstStep
                            styles={{
                              buttonNext: {
                                backgroundColor: "#2eb0a5",
                              },
                            }}
                            run={true}
                            spotlightPadding={0}
                            disableOverlayClose
                            steps={[
                              {
                                disableBeacon: true,
                                disableOverlayClose: false,
                                target: ".tooltip-1",
                                title: "Welcome",
                                content:
                                  "This quick tutorial will show you how the LivingWisely portal and its features work. Note how the program is divided into 4 steps. This helps you ease into the workbook with top-of-mind responses in the first step and then we’ll guide you to go deeper and refine your thoughts until you’re ready to create your masterpiece!",
                                placement: "bottom-start",
                                spotlightClicks: true,
                              },
                              {
                                target: ".tooltip-2",
                                title: "Multitaskers",
                                content:
                                  "If you ordered more than one workbook, you may access each of them here by clicking on the arrow at the far right, then select the one you wish to open.",
                                placement: "left",
                                spotlightClicks: true,
                              },
                              {
                                target: ".tooltip-3",
                                title: "Success",
                                content:
                                  "We all need helpful instructions and examples when we begin a new project.We believe you’ll find everything you need to ensure a successful experience.",
                                placement: "right",
                                styles: {
                                  options: {
                                    width: 450,
                                  },
                                },
                              },
                              {
                                target: ".tooltip-7",
                                title: "Give it a Try",
                                content:
                                  "This is an interactive prompt. Click the Start Recording button now and begin speaking – say whatever comes to mind. Then put your cursor in the box and edit your statement. Respond to these four prompts and know everything is saved automatically every step of the way!",
                                placement: "top",
                                spotlightClicks: true,
                                styles: {
                                  options: {
                                    width: 300,
                                  },
                                },
                              },
                              {
                                target: ".tooltip-4",
                                title: "Personal Reminders",
                                content:
                                  "Capture your reminders or notes along the way and you can get to them later.",
                                placement: "left",
                                spotlightClicks: true,
                              },
                              {
                                target: ".tooltip-5",
                                title: "Help is Here",
                                content:
                                  "Don't hesitate to ask for help whenever you have a question or run into an issue. While we are not always online, we will respond as promptly as possible during typical working hours.",
                                placement: "left",
                                spotlightClicks: true,
                              },
                              {
                                target: ".minicta",
                                title: "Ready for the Next Step?",
                                content: (
                                  <>
                                    <div>
                                      Your content will be automatically
                                      forwarded to Step 2.
                                    </div>
                                    <hr />
                                    <NextPage
                                      page={StepEnum.Synthesis}
                                      text="Step 2"
                                      relative={false}
                                    />
                                  </>
                                ),
                                placement: "top",
                                spotlightClicks: true,
                                isFixed: true,
                                styles: {
                                  buttonNext: {
                                    display: "none",
                                  },
                                  buttonBack: {
                                    display: "none",
                                  },
                                },
                              },
                            ]}
                          />
                        )}
                        <Exercises
                          answers={answers}
                          uploads={uploads}
                          exercises={exercises}
                          authUser={authUser}
                          organization={organization}
                          teams={teams}
                          workbook={workbook}
                          currentPage={StepEnum.Work}
                          nextPage={StepEnum.Synthesis}
                          prompts={prompts}
                        />
                      </>
                    )}
                  />
                  <Route
                    path={`${match.path}/:key/${StepEnum.Synthesis}`}
                    component={() => (
                      <>
                        {subDomain() === "introduction" && (
                          <Joyride
                            continuous
                            scrollToFirstStep
                            styles={{
                              buttonNext: {
                                backgroundColor: "#2eb0a5",
                              },
                            }}
                            run={true}
                            spotlightPadding={0}
                            disableOverlayClose
                            steps={[
                              {
                                disableBeacon: true,
                                disableOverlayClose: false,
                                target: ".tooltip-7",
                                title: "Step 2",
                                content:
                                  "The prompts and tips within this step will help you enhance your initial ideas and become more creative, reflective and expressive with your thoughts and words.",
                                placement: "top",
                                spotlightClicks: true,
                                styles: {
                                  options: {
                                    width: 450,
                                  },
                                },
                              },
                              {
                                target: ".tooltip-2-2",
                                title: "Word Cloud",
                                content:
                                  "This is a sample graphic highlighting the words most frequently used in your content.It helps identify key themes as well as if you missed anything or anyone along the way.",
                                placement: "top",
                                spotlightClicks: true,
                                styles: {
                                  options: {
                                    width: 450,
                                  },
                                },
                              },
                              {
                                target: ".minicta",
                                title: "Let's Continue",
                                content: (
                                  <>
                                    <div>
                                      Your content will be automatically saved
                                      and forwarded to the next step when you’re
                                      ready.
                                    </div>
                                    <hr />
                                    <NextPage
                                      page={
                                        workbook.extraStep
                                          ? StepEnum.Consolidate
                                          : StepEnum.Review
                                      }
                                      text="Next"
                                      relative={false}
                                    />
                                  </>
                                ),
                                placement: "top",
                                spotlightClicks: true,
                                isFixed: true,
                                styles: {
                                  buttonNext: {
                                    display: "none",
                                  },
                                  buttonBack: {
                                    display: "none",
                                  },
                                },
                              },
                            ]}
                          />
                        )}
                        <Exercises
                          answers={answers}
                          uploads={uploads}
                          workbook={workbook}
                          authUser={authUser}
                          teams={teams}
                          organization={organization}
                          exercises={[
                            ...this.carryForwardExercises(),
                            ...this.synthesisExercises(),
                          ]}
                          currentPage={StepEnum.Synthesis}
                          nextPage={
                            workbook.extraStep
                              ? StepEnum.Consolidate
                              : StepEnum.Review
                          }
                          allPrompts={prompts}
                          synthesisPrompts={this.synthesisPrompts()}
                          prompts={[
                            ...this.carryForwardPrompts(),
                            ...this.synthesisPrompts(),
                          ]}
                        />
                      </>
                    )}
                  />

                  {workbook.extraStep && (
                    <Route
                      path={`${match.path}/:key/${StepEnum.Consolidate}`}
                      component={() => (
                        <>
                          {subDomain() === "introduction" && (
                            <Joyride
                              continuous
                              styles={{
                                buttonNext: {
                                  backgroundColor: "#2eb0a5",
                                },
                              }}
                              run={true}
                              spotlightPadding={0}
                              disableOverlayClose
                              steps={[
                                {
                                  disableBeacon: true,
                                  disableOverlayClose: false,
                                  target: ".tooltip-4-1",
                                  title: "Step 3",
                                  content:
                                    "You can use this space to edit, enhance and finalize your words and create a document to communicate what matters most to you and your loved ones.",
                                  placement: "bottom-start",
                                  spotlightClicks: true,
                                  styles: {
                                    options: {
                                      width: 450,
                                    },
                                  },
                                },
                                {
                                  target: ".tooltip-9",
                                  title: "Onward Again",
                                  content: (
                                    <>
                                      <div>
                                        Update your information within this step
                                        anytime throughout your subscription
                                        period as this is where your final
                                        content lives. When your document is
                                        ready to share, everything will forward
                                        to the final step.
                                      </div>
                                      <hr />
                                      <NextPage
                                        page={StepEnum.Review}
                                        text="Next"
                                        relative={false}
                                      />
                                    </>
                                  ),
                                  placement: "top",
                                  spotlightClicks: true,
                                  styles: {
                                    buttonNext: {
                                      display: "none",
                                    },
                                    buttonBack: {
                                      display: "none",
                                    },
                                  },
                                },
                              ]}
                            />
                          )}
                          <Exercises
                            answers={answers}
                            uploads={uploads}
                            workbook={workbook}
                            authUser={authUser}
                            allPrompts={prompts}
                            teams={teams}
                            organization={organization}
                            currentPage={StepEnum.Consolidate}
                            nextPage={StepEnum.Review}
                            exercises={[this.consolidationExercise()]}
                            synthesisPrompts={[this.consolidationPrompt()]}
                            prompts={[this.consolidationPrompt()]}
                          />
                        </>
                      )}
                    />
                  )}
                  <Route
                    path={`${match.path}/:key/${StepEnum.Review}`}
                    component={() => (
                      <>
                        {subDomain() === "introduction" && (
                          <Joyride
                            continuous
                            styles={{
                              buttonNext: {
                                backgroundColor: "#2eb0a5",
                              },
                            }}
                            run={true}
                            spotlightPadding={0}
                            disableOverlayClose
                            steps={[
                              {
                                disableBeacon: true,
                                disableOverlayClose: false,
                                target: ".tooltip-3-1",
                                title: "Step 4",
                                content:
                                  "At any time, you may share whatever you wish, with whomever you wish, or print and save your documents for yourself.",
                                placement: "bottom-start",
                                spotlightClicks: true,
                                styles: {
                                  options: {
                                    width: 350,
                                  },
                                },
                              },
                              {
                                target: ".tooltip-3-2",
                                title: "Options",
                                content:
                                  "You can share your content with different people using the Refresh button. Click the Save & Print or Share Document buttons to send your content anytime. Or get a free quote from our Preservation Team to edit and design your documents or to create a beautiful lasting keepsake.",
                                placement: "top",
                                spotlightClicks: true,
                                styles: {
                                  options: {
                                    width: 350,
                                  },
                                },
                              },
                              {
                                target: "div",
                                title: "Let's Go",
                                content: (
                                  <>
                                    <div>
                                      Thank you for taking LivingWisely for a
                                      test drive. Click here and you will return
                                      to the shopping cart to order one or more
                                      of your favorite subscriptions or
                                      resources. Enjoy your journey!
                                    </div>
                                    <br />
                                    <Button
                                      type={"primary"}
                                      href="https://livingwisely.org/individuals/cart"
                                    >
                                      Order Now
                                    </Button>
                                  </>
                                ),

                                placement: "center",
                                spotlightClicks: true,
                                styles: {
                                  buttonNext: {
                                    display: "none",
                                  },
                                  buttonBack: {
                                    display: "none",
                                  },
                                },
                              },
                            ]}
                          />
                        )}
                        <Exercises
                          answers={answers}
                          uploads={uploads}
                          workbook={workbook}
                          authUser={authUser}
                          allPrompts={prompts}
                          teams={teams}
                          organization={organization}
                          currentPage={StepEnum.Review}
                          exercises={[
                            ...exercises,
                            this.consolidationExercise(),
                          ]}
                          prompts={[
                            ...this.synthesisPromptsAll(),
                            this.consolidationPrompt(),
                          ]}
                        />
                      </>
                    )}
                  />
                </div>
              );
            }}
          </AuthUserContext.Consumer>
        )}
      </>
    );
  }
}

const authCondition = (authUser: any) => !!authUser;

export const Workbook = withAuthorization(authCondition)(
  withRouter(WorkbookComponent)
);
