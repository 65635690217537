import * as React from "react";
import { Steps } from "antd";
import styled from "styled-components";
import { IWorkbook, StepEnum } from "../../firebase/models";
import { nextPage } from "../../firebase/utils";
import { Route } from "react-router";
import { Link } from "react-router-dom";
import { isMobile, isTablet } from "react-device-detect";

const Step = Steps.Step;

const Container = styled.div`
    margin-bottom: 40px;
    background: white;
    
    .ant-steps {
      display: grid;
      ${(props: ContainerProps) => {
        if (props.workbook.extraStep) {
          return `
            grid-template-columns: repeat(4, 1fr);
            @media all and (max-width: 900px) {
              grid-template-columns: repeat(2, 1fr);
            }
          `;
        } else {
          return `
            grid-template-columns: repeat(3, 1fr);
          `;
        }
      }}
      @media all and (max-width: 600px) {
        grid-template-columns: 1fr;
        .manbreak {
          display: none;
        }
      } 
    .ant-steps-item-title::after {
    
    }
    
    .wrapheader {
      white-space:  normal;
    }
    
    
    

   
   .ant-steps-item-process > .ant-steps-item-content > .ant-steps-item-title {
    text-decoration: underline !important;
  }

  
  h3 {
    margin-top: -4px;
    margin-bottom: 0;
  }
  
  .ant-steps-item-description {
    max-width: 60% !important;
    font-size: 14px;
  }


`;

const Wrapper = styled.div`
  margin-top: 40px;
`;

const Description = styled.div`
  font-size: 19px;
  font-style: italic;
  font-weight: bold;
`;

interface ContainerProps {
  workbook: IWorkbook;
}

interface InterfaceProps {
  workbook: IWorkbook;
  page: string;
}

const normalizeCurrentStep = (step: number) => {
  switch (step) {
    case 2:
      return 3;
    case 3:
      return 2;
  }

  return step;
};

export const WorkbookSteps = (props: InterfaceProps) => {
  let currentStep = Number(props.page);
  if (props.workbook.extraStep) {
    currentStep = normalizeCurrentStep(currentStep);
  }

  return (
    <Wrapper>
      <Container workbook={props.workbook} className="tooltip-1">
        <Route
          render={({ match }) => (
            <Steps
              current={currentStep}
              direction={isMobile || isTablet ? "vertical" : "horizontal"}
            >
              <Step
                title={
                  <h3 className="wrapheader">
                    <Link to={nextPage(match.url, StepEnum.Work)}>
                      Begin Your <br className="manbreak" />
                      Workbook
                    </Link>
                  </h3>
                }
                description={
                  <Description>
                    Brainstorm your initial ideas and thoughts.
                  </Description>
                }
              />
              <Step
                className="tooltip-2-1"
                title={
                  <h3 className="wrapheader">
                    <Link to={nextPage(match.url, StepEnum.Synthesis)}>
                      Review, Reflect <br className="manbreak" />& Prioritize
                    </Link>
                  </h3>
                }
                description={
                  <Description>
                    Consider what matters most and deepen your responses.
                  </Description>
                }
              />
              {props.workbook.extraStep && (
                <Step
                  className="tooltip-4-1"
                  title={
                    <h3 className="wrapheader">
                      {currentStep >= 1 ? 
                        (
                          <Link to={nextPage(match.url, StepEnum.Consolidate)}>
                            Create Your <br className="manbreak" /> Document
                          </Link>
                        )
                        :
                        (
                          <span style={{color: "rgba(46, 176, 165, 0.7)"}}>
                            Create Your <br className="manbreak" /> Document
                          </span>
                        )
                      }
                    </h3>
                  }
                  description={
                    <Description style={{opacity: (currentStep >= 1 ? 1 : 0.7)}}>
                      Edit & enhance your responses into a document.
                    </Description>
                  }
                />
              )}
              <Step
                className="tooltip-3-1"
                title={
                  <h3 className="wrapheader">
                    {(props.workbook.extraStep && currentStep >= 2) || (!props.workbook.extraStep && currentStep >= 1) ? 
                      (
                        <Link to={nextPage(match.url, StepEnum.Review)}>
                        Share as <br className="manbreak" /> You Wish
                        </Link>
                      )
                      :
                      (
                        <span style={{color: "rgba(46, 176, 165, 0.7)"}}>
                          Share as <br className="manbreak" /> You Wish
                        </span>
                      )
                    }
                  </h3>
                }
                description={
                  <Description style={{opacity: (currentStep >= 2 ? 1 : 0.7)}}>
                    You decide when, how much and with whom to share.
                  </Description>
                }
              />
            </Steps>
          )}
        />
      </Container>
    </Wrapper>
  );
};
