import {
  Col,
  Icon,
  message,
  Modal,
  Radio,
  Row,
  Typography,
  Upload,
} from "antd";
import { UploadFile } from "antd/lib/upload/interface";
import * as firebase from "firebase";
import * as _ from "lodash";
import * as React from "react";
import styled from "styled-components";
import uuid from "uuid/v4";
import * as db from "../firebase/db";
import { IExercise, StepEnum } from "../firebase/models";

const SIZE = "150px";
const Container = styled.div`
    height:  ${SIZE};
    margin-top 10px;
    margin-left: 5px;


`;

const UploadWrapper = styled.div`
  margin: 0 auto;
  .ant-upload-list-item,
  .ant-upload-select {
    width: ${SIZE} !important;
    height: ${SIZE} !important;
    margin-right: 30px !important;
    float: none !important;
  }

  .ant-upload-list-picture-card {
    float: none;
  }

  &.review-step .ant-upload-select {
    display: none;
  }
`;

interface IProps {
  authUser: any;
  exercise: IExercise;
  fileList: Record<string, UploadFile>;
  currentPage?: number;
  shareFile: (value: string) => void;
  hideFile: (value: string) => void;
  dontShareFiles: string[];
}

interface IState {
  loading: boolean;
  previewImage?: string;
  previewVisible: boolean;
  fileList: UploadFile[];
}

function beforeUpload(file: File) {
  const isLt2M = file.size / 1024 / 1024 < 10;
  if (!isLt2M) {
    message.error("File must smaller than 10MB!");
  }
  return isLt2M;
}

export class PictureWall extends React.Component<IProps, IState> {
  state = {
    loading: false,
    fileList: _.values(this.props.fileList || []),
    previewImage: "",
    previewVisible: false,
  };

  upload = async (file: UploadFile) => {
    const { authUser, exercise } = this.props;
    this.setState({ loading: true });
    const exerciseKey = exercise.key.replace("-final", ""); // TODO: make this a variable and do something else too... makes sure we have only one uploader for both final and draft syntheiss
    const storageRef = firebase
      .storage()
      .ref()
      .child(authUser.uid)
      .child(exerciseKey)
      .child(file.name);
    const snapshot = await storageRef.put(file);
    const url = await snapshot.ref.getDownloadURL();
    const upload = {
      url,
      uid: uuid(),
      size: file.size,
      name: file.name,
      type: file.type,
    };

    await db.doPushUpload(authUser, exercise, upload);
    this.setState({
      loading: false,
      fileList: [...this.state.fileList, upload],
    });
  };

  updateName = async (name: string, upload: UploadFile) => {
    const { authUser, exercise } = this.props;

    await db.doUpdateCaption(authUser, exercise, upload, name);
    this.setState({
      loading: false,
      fileList: this.state.fileList.map((file) => ({
        ...file,
        name: upload.uid === file.uid ? name : file.name,
      })),
    });
  };

  onRemove = (file: UploadFile) => {
    const { authUser, exercise } = this.props;

    db.doDeleteUpload(authUser, exercise, file);
    this.setState({
      loading: false,
      fileList: this.state.fileList.filter((item) => item.uid !== file.uid),
    });
  };

  handlePreview = (file: UploadFile) => {
    this.setState({
      previewImage: file.url || file.thumbUrl,
      previewVisible: true,
    });
  };

  handleCancel = () => this.setState({ previewVisible: false });

  render() {
    const { currentPage, shareFile, hideFile } = this.props;

    const { fileList, previewVisible, previewImage } = this.state;
    const uploadButton = (
      <div>
        <Icon type={this.state.loading ? "loading" : "plus"} />
        <div className="ant-upload-text">Upload</div>
      </div>
    );
    return (
      <UploadWrapper
        className={`clearfix ${currentPage === StepEnum.Review &&
          "review-step"}`}
      >
        <Row type="flex">
          <Col>
            <Upload
              name="avatar"
              listType="picture-card"
              className="avatar-uploader"
              fileList={fileList}
              action={this.upload}
              beforeUpload={beforeUpload}
              onRemove={this.onRemove}
              onPreview={this.handlePreview}
            >
              {uploadButton}
            </Upload>
          </Col>
          <Col style={{ minWidth: 250 }}>
            {fileList.map((file) => (
              <Container>
                <Typography.Text
                  editable={{
                    onChange: (name: string) => this.updateName(name, file),
                  }}
                >
                  {file.name}
                </Typography.Text>
                {currentPage === StepEnum.Review && (
                  <div>
                    <Radio.Group defaultValue={true} buttonStyle="solid">
                      <Radio.Button
                        value={false}
                        onClick={() => hideFile(file.uid)}
                      >
                        <Icon type="close-square" /> Don't Share
                      </Radio.Button>
                      <Radio.Button
                        value={true}
                        onClick={() => shareFile(file.uid)}
                      >
                        <Icon type="check-square" /> Share
                      </Radio.Button>
                    </Radio.Group>
                  </div>
                )}
              </Container>
            ))}
          </Col>
        </Row>
        <Modal
          visible={previewVisible}
          footer={null}
          onCancel={this.handleCancel}
        >
          <img alt="example" style={{ width: "100%" }} src={previewImage} />
        </Modal>
      </UploadWrapper>
    );
  }
}
