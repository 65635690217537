import * as React from "react";
import { Modal, Button } from "antd";

interface AppProps {
  iframeSrc?: string;
  hideCallback: () => void;
}

export class LinkModal extends React.Component<AppProps> {
  hide = () => {
    this.props.hideCallback();
  };

  render() {
    const { iframeSrc } = this.props;

    return (
      <Modal
        width={"90%"}
        title={null}
        style={{ top: 10 }}
        onCancel={this.hide}
        visible={!!iframeSrc}
        footer={[
          <Button key="back" onClick={this.hide}>
            Close
          </Button>
        ]}
      >
        <div style={{ padding: "20px 20px 0 20px" }}>
          <iframe src={iframeSrc} width={"100%"} height={600} />
        </div>
      </Modal>
    );
  }
}
