import * as React from "react";
import { Button, Form, Input } from "antd";
import { IPrompt } from "../../firebase/models";
import styled from "styled-components";

interface InterfaceProps {
  prompt?: Partial<IPrompt>;
  handleSubmit: (prompt: Partial<IPrompt>) => void;
  handleDelete?: (prompt: Partial<IPrompt>) => void;
}

type InterfaceState = ReturnType<typeof getInitialState>;

const StyledButtonGroup = styled(Button.Group)``;

const StyledInput = styled(Input)``;

const StyledForm = styled(Form)`
  .ant-form-item {
    margin-bottom: 0px !important;
  }
`;

const getInitialState = (props: InterfaceProps) => {
  return {
    name: props.prompt ? props.prompt.name : "",
    error: null
  };
};

export class PromptStudioForm extends React.Component<
  InterfaceProps,
  InterfaceState
> {
  readonly state = getInitialState(this.props);

  handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement;
    this.setState({
      [target.name]: target.value
    } as any);
  };

  isUpdate = () => !!this.props.prompt;
  isCreate = () => !this.props.prompt;

  afterSubmit = () => {
    if (this.isCreate()) {
      this.setState({
        name: ""
      });
    }
  };

  handleSubmit = () => {
    const { handleSubmit, prompt } = this.props;
    const { name } = this.state;
    const key = prompt ? prompt.key : undefined;
    const promptParam = this.isCreate() ? { name } : { name, key };
    handleSubmit(promptParam);
    this.afterSubmit();
  };

  render() {
    const { name, error } = this.state;
    const { prompt, handleDelete } = this.props;
    const isInvalid = name === "";

    return (
      <StyledForm layout={"vertical"}>
        <Form.Item>
          <StyledInput
            value={name}
            name="name"
            placeholder="Prompt Question"
            onChange={this.handleChange}
            type="text"
          />
        </Form.Item>

        <Form.Item>
          <StyledButtonGroup>
            <Button
              type="primary"
              disabled={isInvalid}
              onClick={this.handleSubmit}
            >
              {this.isUpdate() ? "Update" : "Create"}
            </Button>

            {handleDelete && prompt && (
              <Button
                type="danger"
                disabled={isInvalid}
                onClick={() => handleDelete(prompt)}
              >
                Delete
              </Button>
            )}
          </StyledButtonGroup>
        </Form.Item>

        {error && <p>{error}</p>}
      </StyledForm>
    );
  }
}
