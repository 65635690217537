import * as React from "react";
import { IExercise, IWorkbook } from "../../firebase/models";
import * as db from "../../firebase/db";
// import styled from "styled-components"
import { ExerciseStudioForm } from "./ExerciseStudioForm";

interface InterfaceProps {
  fetchData?: () => void;
  workbook: IWorkbook;
  exerciseCount: number;
}

export class CreateExercise extends React.Component<InterfaceProps> {
  create = (exercise: Partial<IExercise>) => {
    db.doCreateExercise({
      ...exercise,
      workbook: this.props.workbook.key,
      priority: this.props.exerciseCount,
      synthesis: true
    });
  };

  render() {
    return <ExerciseStudioForm handleSubmit={this.create} />;
  }
}
