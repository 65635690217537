import * as React from "react";
import { Form, Icon, Input, message, Modal, Select } from "antd";
import { GradientButton } from "../../components/Styled";
import { IExercise, ITeam, IUser } from "../../firebase/models";
import styled from "styled-components";
import { TeamMember } from "../Team/TeamMember";
import axios from "axios";
import _ from "lodash";
import { UploadFile } from "antd/lib/upload/interface";

interface IState {
  visible: boolean;
  emails: string[];
  email1: string;
  email2: string;
  email3: string;
}

interface IProps {
  teams: ITeam[];
  authUser: IUser;
  uploads: Record<string, Record<string, UploadFile>>;
  sharedExercises: IExercise[];
  sharedContent: { promptKey: string; content: string }[];
  dontShareFiles: string[];
}

export class ShareButton extends React.Component<IProps, IState> {
  state = {
    visible: false,
    emails: [],
    email1: "",
    email2: "",
    email3: ""
  };

  hide = () => {
    this.setState({
      visible: false
    });
  };

  show = () => {
    this.setState({
      visible: true
    });
  };

  sendEmail = async () => {
    const {
      authUser,
      sharedContent,
      sharedExercises,
      uploads,
      dontShareFiles
    } = this.props;
    const { email1, email2, email3, emails } = this.state;
    const allEmails = [...emails, email1, email2, email3].filter(
      email => !!email
    );
    const uploadArr: UploadFile[] = [];
    _.values(uploads).forEach(record =>
      _.values(record).forEach(upload => uploadArr.push(upload))
    );
    const API_URL = "/.netlify/functions/send-mail";
    try {
      await axios.post(API_URL, {
        emails: allEmails,
        displayName: authUser.displayName || authUser.username,
        sharedContent,
        uploads: uploadArr.filter(file => !dontShareFiles.includes(file.uid)),
        sharedExercises
      });
      message.success("Email sent!");
      this.hide();
    } catch (e) {
      message.error(
        "Error sending your email! As a workaround, please print and email manually."
      );
    }
  };

  render() {
    const options = [
      { value: this.props.authUser.email, label: "Email Yourself" },
      ...this.props.teams.map(team => ({
        value: (team as any).email,
        label: <TeamMember small={true} team={team as ITeam} />
      }))
    ];

    const defaultOptions = options.map(team => {
      return (
        <Select.Option value={(team as any).value}>
          {(team as any).label}
        </Select.Option>
      );
    });

    return (
      <Container>
        <GradientButton onClick={this.show}>
          <Icon type={"share-alt"} /> Share Document
        </GradientButton>
        <Modal
          width={1100}
          okText={"Share"}
          onOk={this.sendEmail}
          title={"Share Your Document"}
          onCancel={this.hide}
          visible={this.state.visible}
        >
          <SelectContainer>
            <Form.Item label="Please select your facilitator or professional from the list to share your document. Alternatively, type your email addresses in the inputs below.">
              <Select
                size="large"
                mode="tags"
                style={{ width: "80%" }}
                onChange={emails =>
                  this.setState({ emails: emails as string[] })
                }
              >
                {defaultOptions}
              </Select>
            </Form.Item>

            <Form.Item label="Custom Email #1">
              <Input
                style={{ width: "80%" }}
                onChange={(e: any) => this.setState({ email1: e.target.value })}
              />
            </Form.Item>

            <Form.Item label="Custom Email #2">
              <Input
                style={{ width: "80%" }}
                onChange={(e: any) => this.setState({ email2: e.target.value })}
              />
            </Form.Item>

            <Form.Item label="Custom Email #3">
              <Input
                style={{ width: "80%" }}
                onChange={(e: any) => this.setState({ email3: e.target.value })}
              />
            </Form.Item>
          </SelectContainer>
        </Modal>
      </Container>
    );
  }
}

const Container = styled.span`
  padding-left: 5px;
`;

const SelectContainer = styled.div`
  .ant-select-lg .ant-select-selection--multiple {
    min-height: 80px !important;
  }
`;
