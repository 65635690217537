import * as React from "react";
import { ITeam } from "../../firebase/models";
import { Popconfirm, message, Icon } from "antd";
import { doDeleteTeam } from "../../firebase/db";

interface InterfaceProps {
  team: ITeam;
}

export class DeleteTeam extends React.Component<InterfaceProps> {
  confirm = async () => {
    await doDeleteTeam(this.props.team);
    message.info("Team Deleted");
  };

  render() {
    return (
      <Popconfirm
        icon={<Icon type="question-circle-o" style={{ color: "red" }} />}
        title="Are you sure you want delete this team?"
        onConfirm={this.confirm}
        okText="Yes"
        cancelText="No"
      >
        <Icon type="delete" style={{ color: "red" }} />
      </Popconfirm>
    );
  }
}
