import * as React from "react";
import ReactMde, { commands, MarkdownUtil } from "react-mde";
import * as Showdown from "showdown";
import styled from "styled-components";
import "draft-js/dist/Draft.css";
import "react-mde/lib/styles/css/react-mde-all.css";
import { Command } from "react-mde/lib/definitions/types";
import {
  TextApi,
  TextState
} from "react-mde/lib/definitions/types/CommandOptions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBold,
  faHeading,
  faItalic,
  faStrikethrough,
  faLink,
  faQuoteRight,
  faCode,
  faImage,
  faListUl,
  faListOl,
  faTasks,
  faUserEdit,
  faBatteryEmpty
} from "@fortawesome/free-solid-svg-icons";

const icons = {
  bold: <FontAwesomeIcon icon={faBold} />,
  header: <FontAwesomeIcon icon={faHeading} />,
  italic: <FontAwesomeIcon icon={faItalic} />,
  strikethrough: <FontAwesomeIcon icon={faStrikethrough} />,
  link: <FontAwesomeIcon icon={faLink} />,
  quote: <FontAwesomeIcon icon={faQuoteRight} />,
  code: <FontAwesomeIcon icon={faCode} />,
  space: <FontAwesomeIcon icon={faBatteryEmpty} />,
  image: <FontAwesomeIcon icon={faImage} />,
  "ordered-list": <FontAwesomeIcon icon={faListOl} />,
  "unordered-list": <FontAwesomeIcon icon={faListUl} />,
  tasks: <FontAwesomeIcon icon={faTasks} />,
  button: <FontAwesomeIcon icon={faUserEdit} />
};

const iconProvider = (name: string) => icons[name];

const buttonCommand: Command = {
  name: "button",
  buttonProps: { "aria-label": "Add bold text" },
  execute: (state0: TextState, api: TextApi) => {
    // Adjust the selection to encompass the whole word if the caret is inside one
    const newSelectionRange = MarkdownUtil.selectWord({
      text: state0.text,
      selection: state0.selection
    });
    const state1 = api.setSelectionRange(newSelectionRange);
    // Replaces the current selection with the bold mark up
    api.replaceSelection(`<button>${state1.selectedText}</button>`);
    // Adjust the selection to not contain the **
    // api.setSelectionRange({
    //   start: state2.selection.end - 2 - state1.selectedText.length,
    //   end: state2.selection.end - 2
    // });
  },
  keyCommand: "button"
};

const spaceCommand: Command = {
  name: "space",
  buttonProps: { "aria-label": "Add space text" },
  execute: (state0: TextState, api: TextApi) => {
    // Adjust the selection to encompass the whole word if the caret is inside one
    // const newSelectionRange = MarkdownUtil.selectWord({text: state0.text, selection: state0.selection});
    // const state1 = api.setSelectionRange(newSelectionRange);
    // Replaces the current selection with the bold mark up
    api.replaceSelection(`&nbsp;&nbsp;&nbsp;&nbsp;`);
    // Adjust the selection to not contain the **
    // api.setSelectionRange({
    //   start: state2.selection.end - state1.selectedText.length,
    //   end: state2.selection.end
    // });
  },
  keyCommand: "space"
};

const listCommands = [
  { commands: [spaceCommand, buttonCommand] },
  ...commands.getDefaultCommands()
];

const Container = styled.div``;
export interface AppState {
  value: string;
  tab: "write" | "preview";
}

export interface AppProps {
  value: string;
  handleValueChange: (value: string) => void;
}

export class MarkdownEditor extends React.Component<AppProps, AppState> {
  converter: Showdown.Converter;

  constructor(props: any) {
    super(props);
    this.state = {
      value: props.value,
      tab: "write"
    };
    this.converter = new Showdown.Converter({
      tables: true,
      simplifiedAutoLink: true,
      strikethrough: true,
      tasklists: true
    });
  }

  handleTabChange = (tab: "write" | "preview") => {
    this.setState({ tab });
  };

  render() {
    return (
      <Container className="container">
        <ReactMde
          getIcon={iconProvider}
          commands={listCommands}
          minEditorHeight={600}
          onChange={this.props.handleValueChange}
          onTabChange={this.handleTabChange}
          value={this.props.value}
          generateMarkdownPreview={markdown =>
            Promise.resolve(this.converter.makeHtml(markdown))
          }
          selectedTab={this.state.tab}
        />
      </Container>
    );
  }
}
