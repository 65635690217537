import { Icon, Radio } from "antd";
import React from "react";
import { isMobile } from "react-device-detect";
import { StepEnum } from "../../firebase/models";

interface InterfaceProps {
  currentPage: number;
  toggleLocked: () => void;
  isLocked?: boolean;
  closeText?: string;
}

export const LockUnlock = (props: InterfaceProps) => {
  const lockText =
    props.closeText ||
    (props.currentPage === StepEnum.Work ? "Close & Forward" : "Close Section");
  return [StepEnum.Work, StepEnum.Synthesis].includes(props.currentPage) ? (
    <Radio.Group
      size={isMobile ? "default" : "large"}
      value={props.isLocked}
      buttonStyle="solid"
    >
      <Radio.Button value={false} onClick={props.toggleLocked}>
        <Icon type="unlock" /> Open Section
      </Radio.Button>
      {!props.isLocked && (
        <Radio.Button value={true} onClick={props.toggleLocked}>
          <Icon type="lock" /> {lockText}
        </Radio.Button>
      )}
    </Radio.Group>
  ) : null;
};
