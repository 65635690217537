import * as React from "react";
import { Button, Form, Input } from "antd";
import { IExercise } from "../../firebase/models";
import styled from "styled-components";

interface InterfaceProps {
  exercise?: IExercise;
  handleSubmit: (exercise: Partial<IExercise>) => void;
  handleDelete?: (exercise: Partial<IExercise>) => void;
}

type InterfaceState = ReturnType<typeof getInitialState>;

const StyledButtonGroup = styled(Button.Group)``;

const StyledInput = styled(Input)``;

const StyledForm = styled(Form)`
  .ant-form-item {
    margin-bottom: 0px !important;
  }
`;

const getInitialState = (props: InterfaceProps) => {
  return {
    name: props.exercise ? props.exercise.name : "",
    error: null
  };
};

export class ExerciseStudioForm extends React.Component<
  InterfaceProps,
  InterfaceState
> {
  readonly state = getInitialState(this.props);

  handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement;
    this.setState({
      [target.name]: target.value
    } as any);
  };

  isUpdate = () => !!this.props.exercise;
  isCreate = () => !this.props.exercise;

  afterSubmit = () => {
    if (this.isCreate()) {
      this.setState({
        name: ""
      });
    }
  };

  handleSubmit = () => {
    const { handleSubmit, exercise } = this.props;
    const { name } = this.state;
    const key = exercise ? exercise.key : undefined;
    const exerciseParam = this.isCreate() ? { name } : { name, key };
    handleSubmit(exerciseParam);
    this.afterSubmit();
  };

  render() {
    const { name, error } = this.state;
    const { exercise, handleDelete } = this.props;
    const isInvalid = name === "";

    return (
      <StyledForm layout={"vertical"}>
        <Form.Item>
          <StyledInput
            value={name}
            name="name"
            placeholder="Exercise Name"
            onChange={this.handleChange}
            type="text"
          />
        </Form.Item>

        <Form.Item>
          <StyledButtonGroup>
            <Button
              type="primary"
              disabled={isInvalid}
              onClick={this.handleSubmit}
            >
              {this.isUpdate() ? "Update" : "Create"}
            </Button>

            {handleDelete && exercise && (
              <Button
                type="danger"
                disabled={isInvalid}
                onClick={() => handleDelete(exercise)}
              >
                Delete
              </Button>
            )}
          </StyledButtonGroup>
        </Form.Item>

        {error && <p>{error}</p>}
      </StyledForm>
    );
  }
}
