import * as React from "react";
import { db } from "../../firebase";
import { withAuthorization } from "../../firebase/withAuthorization";
import { Redirect, RouteComponentProps } from "react-router";
import { IOrganization, IUser } from "../../firebase/models";
import { StudioNav } from "../WorkbookStudio/StudioNav";
import * as _ from "lodash";
import { withKeys } from "../../firebase/utils";
// import moment from "moment";
import { Avatar, Table, Tag } from 'antd';
import moment from 'moment';
import { Button, Icon, Input, Radio } from 'antd';
import Highlighter from 'react-highlight-words';
import * as routes from "../../constants/routes";



interface InterfaceState {
  organizations: IOrganization[]
  users: Record<string, IUser>
  searchText: string
  userroleRadioValue: number;
}

interface IMatchParams {
  key: string;
}

interface InterfaceProps extends RouteComponentProps<IMatchParams> {
  authUser: IUser;
}

class UserStudioC extends React.Component<InterfaceProps, InterfaceState> {

  state = {
    organizations: [],
    users: {},
    searchText: "",
    userroleRadioValue: 1,
    columns: () => [
      {
        title: '',
        key: 'index',
        render: (value: any, record: any, index: any) => <Avatar>{index + 1}</Avatar>,
      },
      {
        title: 'Organization',
        dataIndex: 'organization',
        width: 200,
        render: (value: any, record: any) => {
          return <div>{record.organizations.map((org: IOrganization) =>
            <Tag color="green" style={{marginBottom: -5}}>{org}</Tag>)}
            </div>
        },
        filters: this.state.organizations.map((org: IOrganization) => ({text: org.name || "", value: org.name || ""})),
        onFilter: (value: any, record: any) => record.organizations.includes(value),
      },

      {
        title: 'Name',
        dataIndex: 'username',
        ...this.getColumnSearchProps('username'),
      },

      {
        title: 'Joined',
        dataIndex: 'created_at',
        width: 200,
        render: (value: any, record: any) => {
          return <div>
            <div><small style={{display: "block", marginTop: -5}}><span>{record.created_at ? moment(record.created_at).format("ll") : ""}</span></small></div>
          </div>
        },
        sorter: function (a: any, b: any) {
          // @ts-ignore
          return (new Date(b.created_at || new Date()) - new Date(a.created_at || new Date()));
        }
      },
      {
        title: 'Active',
        dataIndex: 'logged_in',
        width: 200,
        render: (value: any, record: any) => {
          return <div>
            <small style={{display: "block", marginTop: -5}}><span>{record.logged_in ?  moment(record.logged_in).format("ll") : ""}</span></small>
          </div>
        },
        sorter: function (a: any, b: any) {
          // @ts-ignore
          return new Date(b.logged_in || new Date()) - new Date(a.logged_in || new Date());
        }
      },

      {
        title: 'Email',
        dataIndex: 'email',
        ...this.getColumnSearchProps('email'),
      },
      {
        title: 'Answers',
        dataIndex: 'answers',
        render: (value: any, record: any) => {
          return _.values(value).length ? <Tag color="blue">{_.values(value).length}</Tag> : <Tag color="red">x</Tag>
        },
        sorter: function (a: any, b: any) {
          // @ts-ignore
          return (_.values(b.answers) || []).length - (_.values(a.answers) || []).length
        }
      },
    ]
  }

  searchInput = React.createRef<any>()


  getColumnSearchProps = (dataIndex: any) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters } : {setSelectedKeys: any, selectedKeys: any, confirm: any, clearFilters: any}) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={this.searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 248, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon="search"
          size="small"
          style={{ width: "48%", marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: "48%" }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered: any) => (
      <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value: any, record: any) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible: any) => {
      if (visible) {
        setTimeout(() => this.searchInput && this.searchInput.current.select());
      }
    },
    render: (text: any) => {
      if (text){
        return (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[this.state.searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        )
      }
      return <></>
    },
  });

  handleSearch = (selectedKeys: any, confirm: any) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };

  handleReset = (clearFilters: any) => {
    clearFilters();
    this.setState({ searchText: '' });
  };

  fetchData = async () => {

    const organizations = await db.onceGetOrganizations();
    const users = await db.onceGetUsers();

    this.setState({
      organizations: _.values(withKeys(organizations)),
      users: withKeys(users),
    });

  }

  async componentDidMount() {
    this.fetchData()
  }

  onRadioChange = (e: any) => {
    console.log('radio checked', e.target.value);
    this.setState({
      userroleRadioValue: e.target.value,
    });
  };

  render() {
    const {users, organizations, columns, userroleRadioValue} = this.state;
    const { authUser } = this.props;

    if (!authUser.role || authUser.role !== "ADMIN") {
      return <Redirect to={routes.SIGN_IN} />
    }

    let usersArr = new Array<any>()
    switch (userroleRadioValue) {
      case 1:
        usersArr = _.values(users).filter((user: any) => user.userrole && user.userrole === "customer")
        break;
      case 2:
        usersArr = _.values(users).filter((user: any) => user.userrole && user.userrole === "tourist")
        break;
      case 3:
        usersArr = _.values(users).filter((user: any) => !user.userrole)
        break;
    }

    return (
      <div>
        <StudioNav/>
        <h2>User Stats</h2>
        <Radio.Group onChange={this.onRadioChange} value={userroleRadioValue}>
          <Radio value={1}>Customers</Radio>
          <Radio value={2}>Tourists</Radio>
          <Radio value={3}>In Limbo</Radio>
        </Radio.Group>


        <Table pagination={false} columns={columns()} dataSource={usersArr.map((user: any) => ({
        ...user, organizations: organizations.filter((org: IOrganization) => (org.users || {})[user.key]).map((org: any) => org.name)
        }))}  />


      </div>
    );
  }
}

const authCondition = (authUser: any) => !!authUser;

export const UserStudio = withAuthorization(authCondition)(UserStudioC);
