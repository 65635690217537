import * as React from "react";
import { Tabs, Typography } from "antd";
import { IWorkbook } from "../../firebase/models";
import {
  doCreateWorkbook,
  doDeleteWorkbook,
  doUpdateWorkbook
} from "../../firebase/db";
import * as _ from "lodash";
import { EditableWorkbook } from "./EditableWorkbook";
import styled from "styled-components";
const Container = styled.div``;
const TabPane = Tabs.TabPane;

interface InterfaceProps {
  workbooks: Record<string, IWorkbook>;
}

interface InterfaceState {
  activeKey?: string;
  workbooks: Record<string, IWorkbook>;
}

export class WorkbookTabs extends React.Component<
  InterfaceProps,
  InterfaceState
> {
  constructor(props: any) {
    super(props);
    this.state = {
      // activeKey: this.props.workbooks[0].key,
      workbooks: this.props.workbooks
    };
  }

  onChange = (activeKey: string) => {
    this.setState({ activeKey: undefined });
    this.setState({ activeKey });
  };

  onEdit = (targetKey: any, action: any) => {
    this[action](targetKey);
  };

  add = () => {
    doCreateWorkbook({ name: "New EditableWorkbook" });
  };

  remove = (targetKey: string) => {
    const { workbooks } = this.state;
    doDeleteWorkbook(workbooks[targetKey]);
  };

  handleChange = (name: string, workbook: IWorkbook) => {
    const { workbooks } = this.state;

    const newWorkbook = { ...workbook, name };
    doUpdateWorkbook(newWorkbook);

    this.setState({
      workbooks: {
        ...workbooks,
        [workbook.key]: { ...newWorkbook }
      }
    });
  };

  render() {
    const { workbooks, activeKey } = this.state;

    const workbooksOrdered = _.orderBy(
      _.values(workbooks),
      [workbook => workbook.name.toLowerCase()],
      ["asc"]
    );

    return (
      <Container>
        <Tabs
          onChange={this.onChange}
          activeKey={this.state.activeKey}
          type="editable-card"
          tabPosition={"left"}
          onEdit={this.onEdit}
        >
          {workbooksOrdered.map(workbook => (
            <TabPane
              tab={
                <Typography.Text mark={workbook.name.includes("BASE")}>
                  {workbook.name}
                </Typography.Text>
              }
              closable={false}
              key={workbook.key}
            >
              {activeKey && (
                <EditableWorkbook
                  handleChange={this.handleChange}
                  workbookKey={activeKey}
                />
              )}
            </TabPane>
          ))}
        </Tabs>
      </Container>
    );
  }
}
