import _ from "lodash";

export const withKeys = (snapshot: any) => {
  const obj = {};
  snapshot.forEach((childSnapshot: any) => {
    obj[childSnapshot.key] = {
      key: childSnapshot.key,
      ...childSnapshot.val()
    };
  });
  return obj;
};

export const withKeysObject = (object: any) => {
  const obj = {};
  Object.entries(object || {}).forEach(([key, value]) => {
    obj[key] = { ...value, key };
  });
  return obj;
};
export const mapObjectToArrWithKeys = (object: any) => {
  return Object.entries(object).map(([key, value]) => ({
    key,
    ...value
  }));
};

export const nextPage = (url: string, page: string | number) =>
  url.replace(/.$/, String(page));

export const priorityNumber = (prompts: any[]) => 1 + prompts.length / 100;

const NETLIFY = "netlify";
export const subDomain = () => {
  if (window.location.origin.includes(NETLIFY)) {
    return "admin";
  } else {
    const full = window.location.host;
    const parts = full.split(".");
    return parts[0];
  }
};

export const sortByPriority = (arr: Array<any>) => {
  return _.sortBy(arr, "priority");
};
