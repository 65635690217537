import { Button, Col, Icon, List, Popover, Row } from "antd";
import * as React from "react";
import { ReactNode } from "react";
import { isMobile } from "react-device-detect";
import styled from "styled-components";
import { MarkdownPreview } from "../../components/MarkdownPreview";
import { IPrompt, IUser, StepEnum } from "../../firebase/models";

interface InterfaceProps {
  title: string;
  currentPage?: number;
  collapsed?: boolean;
  prompts?: IPrompt[];
  isLocked?: boolean;
  isLockedAtSynthesis?: boolean;
  authUser?: IUser;
  lockUnlock?: ReactNode | false;
  shareOrUnShare?: (value: boolean) => void;
}

const Label = styled.div`
  padding: 5px;
  font-weight: 500;
  color: inherit;

  h3 {
    margin-top: 12px;
  }
`;

const RightSide = styled.div`
  text-align: right;
  white-space: nowrap;
  margin-bottom: 5px;
`;

const Container = styled.div`
  width: 300px;
  max-height: 300px;
  overflow: auto;

  .ant-list-item-meta-title {
    margin-top: 0;
  }
`;

const HeaderContainer = styled.div`
  @media (min-width: 996px) {
    button:not(.ant-btn-sm) {
      height: 80px;
      font-size: 22px;
      line-height: 78px;
    }
  }

  small {
    font-size: 0.7em;
    display: block;
  }
`;

const PopoverReviewPrompts = (props: Partial<InterfaceProps>) => {
  return (
    <Container>
      <List
        size="small"
        dataSource={props.prompts}
        renderItem={(prompt, index) => {
          return (
            <List.Item>
              <List.Item.Meta
                title={
                  <span>
                    <MarkdownPreview markdown={prompt.name} />
                  </span>
                }
              />
            </List.Item>
          );
        }}
      />
    </Container>
  );
};

const MARKDOWN_IMG_REGEX = /\!\[\]\(.+\)/gm;

export const ExerciseHeader = (props: InterfaceProps) => {
  const showShareSwitch = props.currentPage === StepEnum.Review;
  const showDisplayName = props.currentPage === StepEnum.Review;
  const showLock =
    (props.currentPage === StepEnum.Work && props.isLocked) ||
    (props.currentPage === StepEnum.Synthesis && props.isLocked);
  const showReviewPrompts =
    (props.currentPage === StepEnum.Synthesis ||
      props.currentPage === StepEnum.Review) &&
    !props.isLocked;
  const images = (props.title.match(MARKDOWN_IMG_REGEX) || []).join(" ");
  const hasRightSide = showShareSwitch || showLock || showReviewPrompts;

  console.log(props, hasRightSide);
  return (
    <HeaderContainer>
      <Row>
        <Col md={hasRightSide ? 12 : 24}>
          <Label>
            <h3>
              {showDisplayName && (
                <small>
                  {props.authUser &&
                    (props.authUser.displayName || props.authUser.username)}
                </small>
              )}
              <MarkdownPreview
                markdown={props.title.replace(MARKDOWN_IMG_REGEX, "")}
              />
            </h3>
          </Label>
        </Col>
        <Col md={hasRightSide ? 12 : 0}>
          <RightSide>
            {showShareSwitch && (
              <RightSide>
                <Button
                  size="small"
                  type={props.isLocked ? "primary" : "default"}
                  onClick={() =>
                    props.shareOrUnShare &&
                    props.shareOrUnShare(!!props.isLocked)
                  }
                >
                  {props.isLocked && <Icon type="close-square" />} Don't Share
                </Button>
              </RightSide>
            )}

            {showLock && <RightSide>{props.lockUnlock}</RightSide>}

            {showReviewPrompts && (
              <RightSide>
                <Popover
                  trigger="click"
                  placement="bottomRight"
                  content={<PopoverReviewPrompts {...props} />}
                >
                  <Button size={isMobile ? "small" : "default"} icon={"bulb"}>
                    Review Prompts
                  </Button>
                </Popover>
              </RightSide>
            )}
          </RightSide>
        </Col>
      </Row>
      {images && (
        <p style={{ paddingTop: 10 }}>
          <MarkdownPreview
            markdown={(props.title.match(MARKDOWN_IMG_REGEX) || []).join(" ")}
          />
        </p>
      )}
    </HeaderContainer>
  );
};
