import * as React from "react";
import { Card, Icon, Tag } from "antd";
import { AccountAvatar } from "./AccountAvatar";
import styled from "styled-components";
import { ITeam } from "../../firebase/models";

const StyledTag = styled(Tag)`
  margin-top: 5px !important;
`;

const StyledCard = styled(Card)`
  // border-radius: 6px !important;
`;

interface InterfaceProps {
  team: ITeam;
  small?: boolean;
}

export const TeamMember = (props: InterfaceProps) => (
  <StyledCard size="small">
    <div>
      <AccountAvatar team={props.team}>
        <div>
          {!props.small && <div>{props.team.role}</div>}
          <div>
            <StyledTag>
              <Icon type="mail" /> {props.team.email}
            </StyledTag>
          </div>
          {!props.small && (
            <div>
              <StyledTag>
                <Icon type="phone" /> {props.team.phone}
              </StyledTag>
            </div>
          )}
        </div>
      </AccountAvatar>
    </div>
  </StyledCard>
);
