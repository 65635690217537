import * as React from "react";
import { IOrganization } from "../../firebase/models";
import { Popconfirm, message, Icon } from "antd";
import { doDeleteOrganization } from "../../firebase/db";

interface InterfaceProps {
  organization: IOrganization;
}

export class DeleteOrganization extends React.Component<InterfaceProps> {
  confirm = async () => {
    await doDeleteOrganization(this.props.organization);
    message.info("Organization Deleted");
  };

  render() {
    return (
      <Popconfirm
        icon={<Icon type="question-circle-o" style={{ color: "red" }} />}
        title="Are you sure you want delete this organization?"
        onConfirm={this.confirm}
        okText="Yes"
        cancelText="No"
      >
        <Icon type="delete" style={{ color: "red" }} />
      </Popconfirm>
    );
  }
}
