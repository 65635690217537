import * as React from "react";
import * as _ from "lodash";
import { db } from "../../firebase";
import { withAuthorization } from "../../firebase/withAuthorization";
import { Redirect, RouteComponentProps } from "react-router";
import { IOrganization, IWorkbook, IUser } from "../../firebase/models";
import { OrganizationTabs } from "./OrganizationTabs";
import { withKeys } from "../../firebase/utils";
import { StudioNav } from "../WorkbookStudio/StudioNav";
import * as routes from "../../constants/routes";

interface InterfaceState {
  organizations: Record<string, IOrganization>;
  workbooks: IWorkbook[];
}

interface IMatchParams {
  key: string;
}

interface InterfaceProps extends RouteComponentProps<IMatchParams> {
  authUser: IUser;
}

class OrganizationStudioComponent extends React.Component<
  InterfaceProps,
  InterfaceState
> {
  state = {
    organizations: {},
    workbooks: []
  };

  fetchData = async () => {
    const organizations = await db.onceGetOrganizations();
    const workbooks = await db.onceGetWorkbooks();
    this.setState({
      workbooks: _.values(withKeys(workbooks)),
      organizations: withKeys(organizations)
    });

    db.onAddedOrganizations((snapshot: any) => {
      this.setState({
        organizations: {
          ...this.state.organizations,
          [snapshot.key]: {
            ...snapshot.val(),
            key: snapshot.key
          }
        }
      });
    });
  };

  async componentDidMount() {
    this.fetchData();
  }

  render() {
    const { organizations, workbooks } = this.state;
    const { authUser } = this.props;

    //redirect if not admin
    if (!authUser.role || authUser.role !== "ADMIN") {
      return <Redirect to={routes.SIGN_IN} />
    }

    return (
      <div>
        <StudioNav />
        <h2>Organization Editor</h2>
        <OrganizationTabs
          key={Object.keys(organizations).length}
          organizations={organizations}
          workbooks={workbooks}
        />
      </div>
    );
  }
}

const authCondition = (authUser: any) => !!authUser;

export const OrganizationStudio = withAuthorization(authCondition)(
  OrganizationStudioComponent
);
