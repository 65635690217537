import { auth } from "./firebase";

// Sign Up
export const doCreateUserWithEmailAndPassword = (
  email: string,
  password: string,
  displayName: string
) =>
  auth
    .createUserWithEmailAndPassword(email, password)
    .then((userCredentials: any) => {
      userCredentials.user.updateProfile({
        displayName
      });
      return userCredentials;
    });

// Sign In
export const doSignInWithEmailAndPassword = (email: string, password: string) =>
  auth.signInWithEmailAndPassword(email, password);

// Sign out
export const doSignOut = () => auth.signOut();

// Password Reset
export const doPasswordReset = (email: string) =>
  auth.sendPasswordResetEmail(email);

// Password Change
export const doPasswordUpdate = async (password: string) => {
  if (auth.currentUser) {
    await auth.currentUser.updatePassword(password);
  }
  throw Error("No auth.currentUser!");
};

export const doSignInWithLink = (email: string) => {
  var actionCodeSettings = {
    // The URL to redirect to for sign-in completion. This is also the deep
    // link for mobile redirects. The domain (www.example.com) for this URL
    // must be whitelisted in the Firebase Console.
    url:
      window.location.protocol +
      "//" +
      window.location.host +
      "?email=" +
      email,
    handleCodeInApp: true
  };
  return auth.sendSignInLinkToEmail(email, actionCodeSettings);
};
