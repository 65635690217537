import * as React from "react";
import {
  Form,
  Icon,
  Input,
  message,
  Modal,
  Radio,
  InputNumber,
  Avatar,
  Row,
  Col
} from "antd";
import { GradientButton } from "../../components/Styled";
import { IExercise, IUser } from "../../firebase/models";
import styled from "styled-components";
import axios from "axios";
import _ from "lodash";
import { UploadFile } from "antd/lib/upload/interface";
import { RadioChangeEvent } from "antd/lib/radio";
import EthSampleImg from "../../images/document-preservation/PhotoSample.jpg";
import LetterFolderSampleImg from "../../images/document-preservation/LegacyFolder.png";
import BookSample from "../../images/document-preservation/BookSample.jpg";

interface IState {
  visible: boolean;
  emails: string[];
  phone: string;
  city: string;
  state: string;
  radioValue: string;
  custom: string;
  photos: number;
  quantity: number;
}

interface IProps {
  authUser: IUser;
  uploads: Record<string, Record<string, UploadFile>>;
  sharedExercises: IExercise[];
  sharedContent: { promptKey: string; content: string }[];
  dontShareFiles: string[];
}

const options = {
  BASIC: "Basic Enhancements",
  LETTERSFOLDER: "Letter & Folder",
  COLORDISPLAY: "Color Display",
  PERSONALBOOK: "Personal Book",
  CUSTOMPROJECT: "Custom Project"
};

export class DocumentPreservation extends React.Component<IProps, IState> {
  state = {
    visible: false,
    emails: ["tracie@livingwisely.org"],
    phone: "",
    city: "",
    state: "",
    custom: "",
    radioValue: options.BASIC,
    photos: 0,
    quantity: 0
  };

  hide = () => {
    this.setState({
      visible: false
    });
  };

  show = () => {
    this.setState({
      visible: true
    });
  };

  sendEmail = async () => {
    const {
      authUser,
      sharedContent,
      sharedExercises,
      uploads,
      dontShareFiles
    } = this.props;
    const { emails } = this.state;
    const allEmails = [...emails];
    const uploadArr: UploadFile[] = [];
    _.values(uploads).forEach(record =>
      _.values(record).forEach(upload => uploadArr.push(upload))
    );
    const API_URL = "/.netlify/functions/send-mail";
    try {
      await axios.post(API_URL, {
        emails: allEmails,
        displayName: authUser.displayName || authUser.username,
        sharedContent,
        uploads: uploadArr.filter(file => !dontShareFiles.includes(file.uid)),
        extraContent: `
          ${authUser.displayName || authUser.username} | ${authUser.email} | ${
          this.state.phone
          } | ${this.state.city} | ${this.state.state}
          | Selected Option: ${this.state.radioValue}
          | Num Photos: ${this.state.photos} 
          | Num Copies: ${this.state.quantity} 
          ${this.state.custom ? this.state.custom : ""} 
         `,
        sharedExercises
      });
      message.success("Email sent!");
      this.hide();
    } catch (e) {
      message.error(
        "Error sending your email! As a workaround, please print and email manually."
      );
    }
  };

  onChange = (e: RadioChangeEvent) => {
    this.setState({
      radioValue: e.target.value
    });
  };

  render() {
    const radioStyle = {
      display: "flex",
      whiteSpace: "normal",
      marginBottom: 30
      // height: '30px',
      // lineHeight: '30px',
    } as React.CSSProperties;

    const quantities = (
      <div>
        <Form.Item label="Approx Number of Photos">
          <InputNumber
            size="large"
            style={{ width: "80%" }}
            value={this.state.photos}
            onChange={(val: any) => this.setState({ photos: val })}
          />
        </Form.Item>

        <Form.Item label="Approx Number of Copies">
          <InputNumber
            size="large"
            style={{ width: "80%" }}
            value={this.state.quantity}
            onChange={(val: any) => this.setState({ quantity: val })}
          />
        </Form.Item>
      </div>
    );

    return (
      <Container>
        <GradientButton onClick={this.show}>
          <Icon type={"compass"} /> Document Preservation
        </GradientButton>
        <Modal
          width={1100}
          okText={"Request Estimate"}
          onOk={this.sendEmail}
          title={"Document Preservation"}
          onCancel={this.hide}
          visible={this.state.visible}
        >
          <SelectContainer>
            Congratulations on the completion of your LegacyJourney document and
            thank you for your interest in our Document Preservation Services!
            <br />
            <br />
            We recognize that each person has a unique set of needs, length of
            content and number of photos they would like to include in their
            preserved document.
            <br />
            <br />
            Let us know how we may assist you and after reviewing your
            materials, we will provide a free estimate.
            <br />
            <br />
            There is no obligation and your documents remain private within our
            professional Preservation Team which is located within the U.S.
            <hr />
            <h4>Contact Information:</h4>
            <Form.Item label="Phone">
              <Input
                style={{ width: "80%" }}
                onChange={(e: any) => this.setState({ phone: e.target.value })}
              />
            </Form.Item>
            <Form.Item label="City">
              <Input
                style={{ width: "80%" }}
                onChange={(e: any) => this.setState({ city: e.target.value })}
              />
            </Form.Item>
            <Form.Item label="State">
              <Input
                style={{ width: "80%" }}
                onChange={(e: any) => this.setState({ state: e.target.value })}
              />
            </Form.Item>
            <hr />
            <h4>
              Please select one of these four service options for your estimate:
            </h4>
            <Radio.Group onChange={this.onChange} value={this.state.radioValue}>
              <Radio style={radioStyle} value={options.BASIC}>
                <Row>
                  <Col span={18}>
                    <b>Basic Enhancements</b>: includes edits and suggestions to
                    enhance your document so that it's ready for you to print
                    and share (minimum order $50).
                  </Col>
                </Row>
              </Radio>
              <Radio style={radioStyle} value={options.LETTERSFOLDER}>
                <Row>
                  <Col span={18}>
                    <b>Letter & Folder</b>: your document is typeset and printed
                    on archival paper and inserted into a hand-painted folder
                    (minimum order $100)
                    {this.state.radioValue === options.LETTERSFOLDER
                      ? quantities
                      : null}
                  </Col>

                  <Col span={6}>
                    <Avatar
                      size={100}
                      shape="square"
                      src={LetterFolderSampleImg}
                    />
                  </Col>
                </Row>
              </Radio>
              <Radio style={radioStyle} value={options.COLORDISPLAY}>
                <Row>
                  <Col span={18}>
                    <b>Color Display</b>: your document is designed and printed
                    in color on UV protection photo paper, ready for framing
                    (minimum order $150)
                    {this.state.radioValue === options.COLORDISPLAY
                      ? quantities
                      : null}
                  </Col>

                  <Col span={6}>
                    <Avatar size={100} shape="square" src={EthSampleImg} />
                  </Col>
                </Row>
              </Radio>
              <Radio style={radioStyle} value={options.PERSONALBOOK}>
                <Row>
                  <Col span={18}>
                    <b>Personal Book</b>: your document is professionally
                    edited, designed and printed in a personal book (minimum
                    order $300).
                    {this.state.radioValue === options.PERSONALBOOK
                      ? quantities
                      : null}
                  </Col>

                  <Col span={6}>
                    <Avatar size={100} shape="square" src={BookSample} />
                  </Col>
                </Row>
              </Radio>
              <Radio style={radioStyle} value={options.CUSTOMPROJECT}>
                <Row>
                  <Col span={18}>
                    <b>Custom Project</b>: share your initial thoughts,
                    requests, deadlines and best times to call you to discuss a
                    variety of options to preserve, share and celebrate your
                    unique project
                    {this.state.radioValue === options.CUSTOMPROJECT ? (
                      <Input.TextArea
                        style={{ width: "80%" }}
                        value={this.state.custom}
                        onChange={(e: any) =>
                          this.setState({ custom: e.target.value })
                        }
                      />
                    ) : null}
                  </Col>
                </Row>
              </Radio>
            </Radio.Group>
          </SelectContainer>
          <hr />
          We will respond within 3-4 business days. Thank you!
          <br />
          Your <i>LivingWisely</i> Preservation Team
        </Modal>
      </Container>
    );
  }
}

const Container = styled.span`
  padding-left: 5px;
  padding-right: 5px;
`;

const SelectContainer = styled.div`
  .ant-select-lg .ant-select-selection--multiple {
    min-height: 80px !important;
  }

  .ant-radio {
    padding-top: 10px;

    &::after {
      display: none;
    }
  }
`;
