import * as React from "react";
import styled from "styled-components";

const Footer = styled.footer`
  position: absolute !important;
  bottom: 0;
  width: 100%;
`;

export const FooterNav = () => (
  <Footer className="site-footer">
    <div className="container">
      <div className="site-footer-inner">
        <div className="footer-links list-reset">
          <div>
            <img
              className="celebrations-of-life"
              src={require("../images/whiteCelebrationslogoregisteredtrademark.png")}
            />
            <div className="footer-justify">
              <br />
              215 10th Avenue South, Suite 812
              <br />
              Minneapolis, MN 55415
              <br />
              651.600.6412 &middot; 651.600.6413
              <br />
              connect@livingwisely.org
              <br />
            </div>
          </div>
        </div>
        <div className="footer-copyright">
          © 2019 - All intellectual property and content included in this
          website,
          <br />
          along with the LivingWisely™ name/logo, the LegacyJourney® name,
          <br />
          and the Celebrations of Life® name/logo are exclusive properties
          <br />
          of Celebrations of Life Services Inc. (Version 1.01)
          <br />
          <br />
          LivingWisely™ is a division of Celebrations of Life Services Inc.
        </div>
      </div>
    </div>
  </Footer>
);
