import * as Sentry from "@sentry/browser";
import * as _ from "lodash";
import * as React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import styled from "styled-components";
import * as routes from "../constants/routes";
import { db, firebase } from "../firebase";
import { subDomain } from "../firebase/utils";
import { withAuthentication } from "../firebase/withAuthentication";
import { Account } from "../pages/Account";
import { Home } from "../pages/Home";
import { Image as ImagePage } from "../pages/Image";
import { Landing } from "../pages/Landing";
import { LoginPage } from "../pages/Login";
import { OrganizationStudio } from "../pages/OrganizationStudio";
import { PasswordForget } from "../pages/PasswordForget";
import { UserStudio } from "../pages/UserStudio";
import { WorkbookStudio } from "../pages/WorkbookStudio";
import { SharedSpeechProvider } from "../providers/SharedSpeech.js";
import media from "../utils/media-query-template.js";
import { FooterNav } from "./FooterNav";
import { HeaderNav } from "./HeaderNav";
import { ScrollToTop } from "./ScrollToTop";

const myMedia = media as {
  desktop: (...args: any[]) => string;
  tablet: (...args: any[]) => string;
  phone: (...args: any[]) => string;
};

const Main = styled.main`
  padding-bottom: 550px;
  ${myMedia.desktop`
      padding-bottom: 600px;
      `}
  position: relative;
`;

const Container = styled.div`
  padding-left: 50px;
  padding-right: 50px;
  width: 100%;
  margin: 0 auto;
  ${myMedia.desktop`
        padding-right: 0;
        padding-left: 0;
      `}
`;

interface InterfaceState {
  authUser: any;
}

class AppComponent extends React.Component<{}, InterfaceState> {
  constructor(props: any) {
    super(props);

    this.state = {
      authUser: null,
    };
  }

  public componentDidMount() {
    window.onmessage = function(e) {
      try {
        var payload = JSON.parse(e.data);
        localStorage.setItem("emailForSignIn", payload.data.emailForSignIn);
        localStorage.setItem(
          "usernameForSignIn",
          payload.data.usernameForSignIn
        );
      } catch (e) {
        Sentry.captureException(e);
      }
    };
    firebase.auth.onAuthStateChanged((authUser) => {
      authUser
        ? this.setState(() => ({ authUser }))
        : this.setState(() => ({ authUser: null }));
    });

    this.handleSignInWithEmailLink();
  }

  public getUrlParameter(name: string) {
    name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
    var regex = new RegExp("[\\?&]" + name + "=([^&#]*)");
    var results = regex.exec(location.search);
    return results === null
      ? ""
      : decodeURIComponent(results[1].replace(/\+/g, " "));
  }

  public handleSignInWithEmailLink() {
    // Confirm the link is a sign-in with email link.
    if (firebase.auth.isSignInWithEmailLink(window.location.href)) {
      // Additional state parameters can also be passed via URL.
      // This can be used to continue the user's intended action before triggering
      // the sign-in operation.
      // Get the email if available. This should be available if the user completes
      // the flow on the same device where they started it.
      let email = window.localStorage.getItem("emailForSignIn");
      let username = window.localStorage.getItem("usernameForSignIn");
      if (!email) {
        email = this.getUrlParameter("email");
      }
      // The client SDK will parse the code from the link for you.
      firebase.auth
        .signInWithEmailLink(email || "", window.location.href)
        .then(async function(result) {
          // Clear email from storage.
          // window.localStorage.removeItem("emailForSignIn");
          // You can access the new user via result.user
          const user = result.user;
          let uid = "";
          if (user) {
            uid = user.uid;
          }
          const organizationSnapshot = await db.onceGetOrganization(
            subDomain()
          );
          const organization = _.values(organizationSnapshot.val())[0] || {
            key: "",
          };
          db.doAddOrganizationUser(organization, uid);
          console.log("organization ", organization);

          db.doUpsertUser(
            uid,
            username || "",
            email || "",
            new Date().toString()
          );

          // Additional user info profile not available via:
          // result.additionalUserInfo.profile == null
          // You can check if the user is new or existing:
          // result.additionalUserInfo.isNewUser
        })
        .catch(function(error) {
          // Some error occurred, you can inspect the code: error.code
          // Common errors could be invalid email and invalid or expired OTPs.
        });
    }
  }

  public render() {
    const { authUser } = this.state;

    return (
      <BrowserRouter>
        <ScrollToTop>
          <Main>
            <SharedSpeechProvider>
              <Container>
                {authUser && <HeaderNav authUser={authUser} />}

                <Switch>
                  <Route
                    exact={true}
                    path={routes.IMAGE}
                    component={ImagePage}
                  />
                  <Route
                    exact={true}
                    path={routes.LANDING}
                    component={Landing}
                  />
                  <Route
                    exact={true}
                    path={routes.SIGN_IN}
                    component={LoginPage}
                  />
                  <Route
                    exact={true}
                    path={routes.STUDIO + routes.KEY}
                    component={WorkbookStudio}
                  />
                  <Route
                    exact={true}
                    path={routes.ORG_STUDIO + routes.KEY}
                    component={OrganizationStudio}
                  />
                  <Route
                    exact={true}
                    path={routes.USER_STUDIO}
                    component={UserStudio}
                  />
                  <Route
                    exact={true}
                    path={routes.PASSWORD_FORGET}
                    component={PasswordForget}
                  />
                  <Route
                    exact={true}
                    path={routes.KEY + routes.PAGE}
                    component={Home}
                  />
                  <Route
                    exact={true}
                    path={routes.ACCOUNT}
                    component={Account}
                  />
                </Switch>
              </Container>
            </SharedSpeechProvider>

            <FooterNav />
          </Main>
        </ScrollToTop>
      </BrowserRouter>
    );
  }
}

export const App = withAuthentication(AppComponent);
