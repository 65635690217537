import { Document, Packer, Paragraph, TextRun } from "docx";
import { saveAs } from "file-saver";
import moment from "moment";
const removeMd = require("remove-markdown");
let textRunMap = (arr) => {
  const run = [];
  arr.forEach((a, i) => {
    if (arr.length === 1 || i === 0) {
      run.push(new TextRun({ text: `${a}`, size: 28 }));
    } else {
      run.push(new TextRun({ text: `${a}`, break: 1, size: 28 }));
    }
  });
  return run;
};
// const image = new ImageRun({
//   data: Buffer.from(base64Sprite, "base64"),
//   transformation: {
//     width: 20,
//     height: 20,
//   },
// });
export const makeDoc = async (
  print,
  displayName,
  sharedExercises,
  sharedContent
) => {
  const children = sharedExercises.map((exercise, index) => {
    const header = index === 0 ? `${displayName} - ` : "";
    const title = new TextRun({
      text: removeMd(header + exercise.name.trim()).replace(/\*/g, ""),
      break: 1,
      size: 32,
      bold: true,
      underline: {},
    });
    const subContent = sharedContent
      .map((item) => {
        if (item.exerciseKey === exercise.key && item.content) {
          const splitText = item.content.split(/\r?\n/);
          const textRun = textRunMap(splitText);
          return new TextRun({ children: textRun, break: 2 });
        } else {
          return null;
        }
      })
      .filter((content) => content);
    return new Paragraph({
      children: [title, ...subContent],
    });
  });
  const doc = new Document({
    creator: "LivingWisely",
    title: "Your Document",
    sections: [
      {
        children,
      },
    ],
  });
  if (print) {
    return Packer.toBlob(doc).then((blob) => {
      saveAs(blob, `LivingWisely-${moment().format("MM-DD-hh-mm-ss")}.docx`);
    });
  } else {
    return await Packer.toBase64String(doc);
  }
};
