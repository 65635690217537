import * as React from "react";
import { Tabs, Typography } from "antd";
import { IOrganization, ITeam } from "../../firebase/models";
import { doCreateTeam, doDeleteTeam, doUpdateTeam } from "../../firebase/db";
import * as _ from "lodash";
import { DeleteTeam } from "./DeleteTeam";
import { TeamForm } from "./TeamForm";
import styled from "styled-components";
const Container = styled.div`
  h3 {
    text-align: right;
  }
  .ant-tabs .ant-tabs-right-bar {
    min-width: 150px;
    max-width: 250px;
  }
`;

const TabPane = Tabs.TabPane;

interface InterfaceProps {
  teams: Record<string, ITeam>;
  organization: IOrganization;
}

interface InterfaceState {
  activeKey?: string;
  teams: Record<string, ITeam>;
}

export class TeamTabs extends React.Component<InterfaceProps, InterfaceState> {
  constructor(props: any) {
    super(props);
    this.state = {
      // activeKey: this.props.teams[0].key,
      teams: this.props.teams
    };
  }

  onChange = (activeKey: string) => {
    this.setState({ activeKey: undefined });
    this.setState({ activeKey });
  };

  onEdit = (targetKey: any, action: any) => {
    this[action](targetKey);
  };

  add = () => {
    const { organization } = this.props;
    doCreateTeam({ name: "New Team", organization: organization.key });
  };

  remove = (targetKey: string) => {
    const { teams } = this.state;
    doDeleteTeam(teams[targetKey]);
  };

  handleChange = (name: string, team: ITeam) => {
    const { teams } = this.state;

    const newTeam = { ...team, name };
    debugger;
    doUpdateTeam(newTeam);

    this.setState({
      teams: {
        ...teams,
        [team.key]: { ...newTeam }
      }
    });
  };

  render() {
    const { teams, activeKey } = this.state;

    return (
      <Container>
        <h3>Manage Team</h3>
        <Tabs
          onChange={this.onChange}
          activeKey={this.state.activeKey}
          type="editable-card"
          tabPosition="right"
          onEdit={this.onEdit}
        >
          {_.values(teams).map(team => (
            <TabPane
              tab={
                <span>
                  <Typography.Text
                    editable={{
                      onChange: (name: string) => this.handleChange(name, team)
                    }}
                  >
                    {team.name}
                  </Typography.Text>{" "}
                  <DeleteTeam team={team} />
                </span>
              }
              closable={false}
              key={team.key}
            >
              {activeKey && <TeamForm team={team} />}
            </TabPane>
          ))}
        </Tabs>
      </Container>
    );
  }
}
