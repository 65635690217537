import * as React from "react";
import { GridRow } from "./GridRow";
import styled from "styled-components";
import { SelectWorkbook } from "../pages/Workbook/SelectWorkbook";
import media from "../utils/media-query-template.js";
import { IUser } from "../firebase/models";
const myMedia = media as {
  desktop: (...args: any[]) => string;
  tablet: (...args: any[]) => string;
  phone: (...args: any[]) => string;
};

const Image = styled.img`
  margin-left: -70px;
  background: white;
  ${myMedia.desktop`
    margin-left: 0px;
      `}
`;

interface InterfaceProps {
  authUser: IUser;
}

export const HeaderNav = (props: InterfaceProps) => {
  return (
    <GridRow
      type="flex"
      justify="center"
      align="bottom"
      cols={[
        <Image
          key="image"
          width={400}
          src={require("../images/LivingWisely_Logo_Tagline[3186].png")}
        />,
        <SelectWorkbook authUser={props.authUser} key="workbooks" />
      ]}
    />
  );
};
