import * as React from "react";
import { ReactNode } from "react";
import { Button, Modal, message } from "antd";
import styled from "styled-components";
import { MarkdownEditor } from "./MarkdownEditor";
import { previewStyle } from "./MarkdownPreview";

const Container = styled.span`
  cursor: pointer;
`;

const styling = `.mde-preview { ${previewStyle} }`;
const Preview = styled.div`
  ${styling}
`;

interface AppProps {
  value?: string;
  save: (value: string) => void;
  children?: ReactNode;
}

export class MarkdownEditorModal extends React.Component<AppProps> {
  state = {
    visible: false,
    value: this.props.value || ""
  };

  handleValueChange = (value: string) => {
    this.setState({ value });
  };

  hide = () => {
    this.setState({
      visible: false
    });
  };

  show = () => {
    this.setState({
      visible: true
    });
  };

  saveMarkdown = () => {
    message.info("Value saved!");
    this.props.save(this.state.value);
  };

  render() {
    const { children } = this.props;

    return (
      <span>
        <Container onClick={this.show}>
          {children ? children : <Button icon="edit" type="primary" />}
        </Container>
        <Modal
          width={"90%"}
          title={null}
          style={{ top: 10 }}
          onCancel={this.hide}
          okText="Save"
          onOk={this.saveMarkdown}
          visible={this.state.visible}
        >
          <Preview>
            <MarkdownEditor
              handleValueChange={this.handleValueChange}
              value={this.state.value}
            />
          </Preview>
        </Modal>
      </span>
    );
  }
}
