import * as React from "react";
import { IPrompt } from "../../firebase/models";
import { Popconfirm, message, Icon } from "antd";
import { doDeletePrompt } from "../../firebase/db";
import { ReactNode } from "react";

interface InterfaceProps {
  prompt: IPrompt;
  children?: ReactNode;
}

export class DeletePrompt extends React.Component<InterfaceProps> {
  confirm = async () => {
    await doDeletePrompt(this.props.prompt);
    message.info("Prompt Deleted");
  };

  render() {
    const { children } = this.props;

    return (
      <Popconfirm
        icon={<Icon type="question-circle-o" style={{ color: "red" }} />}
        title="Are you sure you want delete this prompt?"
        onConfirm={this.confirm}
        okText="Yes"
        cancelText="No"
      >
        {children ? children : <Icon type="delete" style={{ color: "red" }} />}
      </Popconfirm>
    );
  }
}
