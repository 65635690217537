import { db } from "./firebase";
import {
  IPrompt,
  IWorkbook,
  IExercise,
  IAnswer,
  IUser,
  IOrganization,
  ITeam
} from "./models";
import { UploadFile } from "antd/lib/upload/interface";
import { subDomain } from "./utils";

// User API
export const doUpsertUser = (
  id: string,
  username: string,
  email: string,
  date: string
) => {
  const newUserRole = subDomain() === "introduction" ? "tourist" : "customer";

  db.ref("/users/" + id)
    .once("value")
    .then(snapshot => {
      const user = snapshot.val();
      if (user && user.email) {
        // If user exists in database
        // call doLoginUser
        if (!user.userrole || user.userrole === "tourist") {
          doLoginUser(id, newUserRole);
        } else {
          doLoginUser(id);
        }

      } else {
        // call doCreateUser
        doCreateUser(id, username, email, date, newUserRole);
      }
    });
};

export const doCreateUser = (
  id: string,
  username: string,
  email: string,
  date: string,
  userrole: string
) =>
  db.ref(`users/${id}`).set({
    email,
    username,
    created_at: date,
    userrole
  });

export const doLoginUser = (id: string, userrole?: string) => {
  if (userrole) {
    db.ref(`users/${id}`).update({
      logged_in: new Date(),
      userrole
    });
  } else {
    db.ref(`users/${id}`).update({
      logged_in: new Date()
    });
  }
};
export const doUsernameUpdate = (id: string, username: string) => {
  db.ref(`users/${id}`).update({
    username
  });
};
export const onceGetIndividualSubscriptions = async (authUser: IUser) => {
  const uriEmail = encodeURIComponent(authUser.email || "");
  const subscriptions = await fetch(
    `/.netlify/functions/get-users-subscriptions?email=${uriEmail}`
  );
  return subscriptions.json();
};

export const doPushUpload = (
  user: IUser,
  exercise: IExercise,
  upload: UploadFile
) =>
  db.ref(`users/${user.uid}/uploads/${exercise.key}/${upload.uid}`).set(upload);

export const doUpdateCaption = (
  user: IUser,
  exercise: IExercise,
  upload: UploadFile,
  name: string
) =>
  db
    .ref(`users/${user.uid}/uploads/${exercise.key}/${upload.uid}`)
    .update({ name });

export const doDeleteUpload = (
  user: IUser,
  exercise: IExercise,
  upload: UploadFile
) => db.ref(`users/${user.uid}/uploads/${exercise.key}/${upload.uid}`).remove();

export const onceGetUser = (user: IUser) =>
  db.ref(`users/${user.uid}`).once("value");

// Prompt API
export const doCreatePrompt = (prompt: Partial<IPrompt>) =>
  db
    .ref(`prompts`)
    .push()
    .set(prompt);

export const doUpdatePrompt = (prompt: Partial<IPrompt>) =>
  db.ref(`prompts/${prompt.key}`).update(prompt);

export const doDeletePrompt = (prompt: Partial<IPrompt>) =>
  db.ref(`prompts/${prompt.key}`).remove();

export const onAddedPrompts = (key: string, cb: any) =>
  db
    .ref("prompts")
    .orderByChild("workbook")
    .equalTo(key)
    .limitToLast(1)
    .on("child_added", cb);
export const onRemovedPrompts = (cb: any) =>
  db.ref("prompts").on("child_removed", cb);
export const onChangedPrompts = (cb: any) =>
  db.ref("prompts").on("child_changed", cb);

export const onceGetPrompts = () => db.ref("prompts").once("value");
export const onceGetWorkbookPrompts = (key: string) =>
  db
    .ref("prompts")
    .orderByChild("workbook")
    .equalTo(key)
    .once("value");

// EditableWorkbook API
export const doCreateWorkbook = async (workbook: Partial<IWorkbook>) => {
  const newRef = db.ref(`workbooks`).push();
  await newRef.set(workbook);
  return newRef.key || "";
};

export const doDeleteWorkbook = (workbook: Partial<IWorkbook>) => {
  return db.ref(`workbooks/${workbook.key}`).remove();
};

export const doUpdateWorkbook = (workbook: Partial<IWorkbook>) => {
  return db.ref(`workbooks/${workbook.key}`).update(workbook);
};

export const onceGetWorkbooksInOrganization = () =>
  db.ref("workbooks").once("value");
export const onceGetWorkbooks = () => db.ref("workbooks").once("value");
export const onceGetWorkbook = (key: string) =>
  db
    .ref("workbooks")
    .child(key)
    .once("value");
export const oncegetDefaultAndAvailableWorkbooks = () =>
  db
    .ref("workbooks")
    .orderByChild("lastOpened")
    .limitToLast(1)
    .once("value");

export const onAddedGetWorkbooks = (cb: any) =>
  db.ref("workbooks").on("child_added", cb);

// Exercise API
export const doCreateExercise = async (exercise: Partial<IExercise>) => {
  const newRef = db.ref(`exercises`).push();
  await newRef.set(exercise);
  return newRef.key || "";
};

export const doDeleteExercise = (exercise: Partial<IExercise>) => {
  return db.ref(`exercises/${exercise.key}`).remove();
};

export const doUpdateExercise = (exercise: Partial<IExercise>) => {
  return db.ref(`exercises/${exercise.key}`).update(exercise);
};

export const onRemovedExercises = (cb: any) =>
  db.ref("exercises").on("child_removed", cb);

export const onceGetWorkbookExercises = (key: string) =>
  db
    .ref("exercises")
    .orderByChild("workbook")
    .equalTo(key)
    .once("value");

export const onceGetExercises = () => db.ref("exercises").once("value");

export const onAddedExercises = (key: string, cb: any) =>
  db
    .ref("exercises")
    .orderByChild("workbook")
    .equalTo(key)
    .limitToLast(1)
    .on("child_added", cb);

// Answer API

interface IHandleChange {
  prompt: IPrompt;
  answer: Partial<IAnswer>;
  authUser: IUser;
}

interface IIsSet {
  prompt: IPrompt;
  authUser: IUser;
}

export const doSetAnswer = ({ prompt, answer, authUser }: IHandleChange) => {
  return db.ref(`users/${authUser.uid}/answers/${prompt.key}`).set(answer);
};

export const isAnswerSet = async ({ prompt, authUser }: IIsSet) => {
  let retBool = false;
  await db.ref(`users/${authUser.uid}/answers/${prompt.key}`)
    .once("value")
    .then(snapshot => {
      const answer = snapshot.val();
      retBool = answer !== null;
    });
  return retBool;
};

export const doLockExercise = (
  exercise: IExercise,
  authUser: IUser,
  locked: boolean
) => {
  return db
    .ref(`users/${authUser.uid}/lockedExercises/${exercise.key}`)
    .set(locked);
};

export const onAddedAnswers = (user: Partial<IUser>, cb: any) =>
  db.ref(`users/${user.uid}/answers`).on("child_changed", cb);

// Organization API
export const doAddOrganizationUser = (
  organization: Partial<IOrganization>,
  uid: string
) => db.ref(`organizations/${organization.key}/users/${uid}`).set(true);

export const doCreateOrganization = (organization: Partial<IOrganization>) =>
  db
    .ref(`organizations`)
    .push()
    .set(organization);

export const doUpdateOrganization = (organization: Partial<IOrganization>) =>
  db.ref(`organizations/${organization.key}`).update(organization);

export const doDeleteOrganization = (organization: Partial<IOrganization>) =>
  db.ref(`organizations/${organization.key}`).remove();

export const onAddedOrganizations = (cb: any) =>
  db.ref("organizations").on("child_added", cb);
export const onceGetOrganizations = () => db.ref("organizations").once("value");
export const onceGetUsers = () => db.ref("users").once("value");
export const onRemovedOrganizations = (cb: any) =>
  db.ref("organizations").on("child_removed", cb);
export const onChangedOrganizations = (cb: any) =>
  db.ref("organizations").on("child_changed", cb);

export const onceGetOrganization = (subDomain: string) =>
  db
    .ref(`organizations`)
    .orderByChild("subDomain")
    .equalTo(subDomain)
    .limitToLast(1)
    .once("value");
export const onceGetWorkbookOrganizations = (key: string) =>
  db
    .ref("organizations")
    .orderByChild("workbook")
    .equalTo(key)
    .once("value");

// Team API
export const doCreateTeam = (team: Partial<ITeam>) =>
  db
    .ref(`teams`)
    .push()
    .set(team);

export const doUpdateTeam = (team: Partial<ITeam>) =>
  db.ref(`teams/${team.key}`).update(team);

export const doDeleteTeam = (team: Partial<ITeam>) =>
  db.ref(`teams/${team.key}`).remove();

export const onAddedTeams = (key: string, cb: any) =>
  db
    .ref("teams")
    .orderByChild("organization")
    .equalTo(key)
    .limitToLast(1)
    .on("child_added", cb);
export const onChangedTeams = (key: string, cb: any) =>
  db
    .ref("teams")
    .orderByChild("organization")
    .equalTo(key)
    .limitToLast(1)
    .on("child_changed", cb);
export const onRemovedTeams = (cb: any) =>
  db.ref("teams").on("child_removed", cb);

export const onceGetTeams = (key: string) =>
  db
    .ref("teams")
    .orderByChild("organization")
    .equalTo(key)
    .once("value");
export const onceGetOrganizationTeams = (key: string) =>
  db
    .ref("teams")
    .orderByChild("organization")
    .equalTo(key)
    .once("value");
