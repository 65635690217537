import * as React from "react";
import { withAuthorization } from "../../firebase/withAuthorization";
import { Redirect, Route, RouteComponentProps, Switch } from "react-router";
import { Workbook } from "../Workbook";
import { getDefaultAndAvailableWorkbooks } from "../Workbook/SelectWorkbook";
import { IUser } from "../../firebase/models";
import { firebase } from "../../firebase";

interface InterfaceState {
  defaultWorkbookKey?: string;
}

interface IMatchParams {
  key: string;
}

interface InterfaceProps extends RouteComponentProps<IMatchParams> {
  authUser: IUser;
}

class HomeComponent extends React.Component<InterfaceProps, InterfaceState> {
  constructor(props: any) {
    super(props);

    this.state = {
      defaultWorkbookKey: undefined
    };

    this.fetchData();
  }

  fetchData = async () => {
    const { authUser } = this.props;
    const { defaultWorkbookKey } = await getDefaultAndAvailableWorkbooks(
      authUser
    );
    this.setState({ defaultWorkbookKey });
  };

  public render() {
    const { defaultWorkbookKey } = this.state;
    const { match } = this.props;
    return (
      <div>
        <Switch>
          {match.params.key && (
            <Route
              key={match.params.key}
              exact={true}
              path={`${match.path}`}
              render={() => <Workbook />}
            />
          )}
          {defaultWorkbookKey && !match.params.key && (
            <Redirect to={`/${defaultWorkbookKey}/0`} />
          )}
        </Switch>
      </div>
    );
  }
}

const authCondition = (authUser: any) => { 
  if (firebase.auth.isSignInWithEmailLink(window.location.href)){

    return true;
  }
  return !!authUser;
};

export const Home = withAuthorization(authCondition)(HomeComponent);
