import * as React from "react";
import * as _ from "lodash";
import { Avatar, Card, Col, Row, Spin, Tabs } from "antd";
import styled from "styled-components";
import { TeamMembers } from "../Team/TeamMembers";
import { WrappedSignUpForm } from "../SignUp/SignUpForm";
import { SignInForm } from "../SignIn/SignInForm";
import { SignInWithEmailForm } from "../SignIn/SignInWithEmailForm";
import { RouteComponentProps, withRouter } from "react-router";
import * as db from "../../firebase/db";
import { IOrganization, ITeam } from "../../firebase/models";
import { subDomain } from "../../firebase/utils";
import { onceGetTeams } from "../../firebase/db";
import { PASSWORD_FORGET } from "../../constants/routes";
import { Link } from "react-router-dom";
import media from "../../utils/media-query-template.js";
const myMedia = media as {
  desktop: (...args: any[]) => string;
  tablet: (...args: any[]) => string;
  phone: (...args: any[]) => string;
};

const Image = styled.img`
  width: 400px;
  margin: auto;
  position: relative;
  padding-bottom: 25px;
  margin-left: -85px ${myMedia.desktop`

    margin-left: -15px
      `};
`;
const Header = styled.div`
  background-color: #59a9a0;
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  padding: 4px;
`;

const LeftCol = styled.div`
  padding-right: 80px;
`;

const LoginContainer = styled.div`
  background: white;
  z-index: 10;
  min-height: 100vh;
`;
const Contentcontainer = styled.div`
  max-width: 1400px;
  margin: auto;
  display: block;
  width: auto;
  padding: 20px 170px;
  ${myMedia.desktop`
        padding: 15px 15px;
      `}
`;
const Tabssection = styled.div`
  margin: auto;
  background: #59a9a0;
  padding: 10px;
  border-radius: 4px;
`;
const Welcometext = styled.div`
  font-size: 44px;
  line-height: 1;
  text-align: left;
  margin: 0px 0 30px 0;
  clear: both;
  color: #1d2b4c;
  font-family: Frank Ruhl Libre, serif;
  font-weight: 700;
`;
const Welcometextsmall = styled.div`
  font-size: 22px;
  text-align: left;
  white-space: pre-wrap;
`;

const WelcometextSub = styled.div`
  font-size: 24px;
  // margin-top: 14px;
  color: #1d2b4c;
  line-height: 1.1;
  font-family: Frank Ruhl Libre, serif;
  font-weight: 700;
`;
const Rowsection = styled.div`
  padding-top: 10px;
`;

interface InterfaceState {
  organization?: Partial<IOrganization>;
  teams: ITeam[];
}

class LoginPageComponent extends React.Component<
  RouteComponentProps<{ organization: string }>,
  InterfaceState
> {
  constructor(props: any) {
    super(props);

    this.state = {
      organization: undefined,
      teams: []
    };

    this.fetchData();
  }

  fetchData = async () => {
    const organizationSnapshot = await db.onceGetOrganization(subDomain());
    const organization = _.values(organizationSnapshot.val())[0] || { key: "" };

    const teams = await onceGetTeams(organization.key);
    this.setState({
      organization: { ...organization, key: organization.key },
      teams: _.values(teams.val())
    });
  };

  render() {
    const { organization, teams } = this.state;
    return !organization ? (
      <Spin size={"large"} />
    ) : (
      <LoginContainer>
        <Header />
        <Contentcontainer>
          <Rowsection>
            <Row>
              <Image
                src={require("../../images/LivingWisely_Logo_Tagline[3186].png")}
              />
              <Col xl={8} />
            </Row>
            <Row>
              <Col xl={14}>
                <LeftCol>
                  <Welcometext>
                    {organization.introductionTitle}
                    <br />
                  </Welcometext>
                  <Welcometextsmall>
                    {organization.introduction}
                  </Welcometextsmall>
                </LeftCol>
              </Col>
              <Col xl={10}>
                <Tabssection>
                  <Card>
                    <Row type="flex" gutter={16} align={"middle"}>
                      <Col xl={6}>
                        <Avatar
                          shape="square"
                          size={65}
                          icon="user"
                          src={organization.imageUrl}
                        />
                      </Col>
                      <Col xl={18}>
                        {subDomain() === "introduction" ||
                        subDomain() === "individuals" ? (
                          <WelcometextSub>
                            Access Your LivingWisely Programs
                          </WelcometextSub>
                        ) : (
                          <WelcometextSub>
                            Login or Sign Up for Your Account
                          </WelcometextSub>
                        )}
                      </Col>
                    </Row>
                    {subDomain() === "introduction" ||
                    subDomain() === "individuals" ? (
                      <SignInWithEmailForm />
                    ) : (
                      <Tabs>
                        <Tabs.TabPane tab="Login" key="1">
                          <SignInForm
                            organization={organization}
                            history={this.props.history}
                          />
                          <Link to={PASSWORD_FORGET}>Forgot Password?</Link>
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="Sign Up" key="2">
                          <WrappedSignUpForm organization={organization} />
                        </Tabs.TabPane>
                      </Tabs>
                    )}
                  </Card>
                </Tabssection>
              </Col>
            </Row>
          </Rowsection>
          <br />
          <br />
          <br />

          <WelcometextSub>
            Your Advisor/Facilitator Team
            <br />
          </WelcometextSub>
          <TeamMembers teams={teams} />
        </Contentcontainer>
      </LoginContainer>
    );
  }
}

export const LoginPage = withRouter(LoginPageComponent);
