import { Button, Col, Collapse, Icon, message, Row } from "antd";
import { UploadFile } from "antd/lib/upload/interface";
import * as React from "react";
import { isMobile } from "react-device-detect";
import styled from "styled-components";
import { GradientButton } from "../../components/Styled";
import {
  IAnswer,
  IExercise,
  IOrganization,
  IPrompt,
  ITeam,
  IUser,
  IWorkbook,
  StepEnum,
} from "../../firebase/models";
import { sortByPriority } from "../../firebase/utils";
import { makeDoc } from "../../utils/email";
import media from "../../utils/media-query-template";
import { DocumentPreservation } from "./DocumentPreservationButton";
import { Exercise } from "./Exercise";
import { ExerciseHeader } from "./ExerciseHeader";
import { Introduction } from "./Introduction";
import { HelpfulInstructions } from "./Message";
import { MiniCta, NextPage } from "./NextPage";
import { NotePad } from "./NotePad";
import { getInitialState } from "./Prompt";
import { ShareButton } from "./ShareButton";
import { WordCloud } from "./WordCloud";

const myMedia = media as {
  desktop: (...args: any[]) => string;
  tablet: (...args: any[]) => string;
  phone: (...args: any[]) => string;
};

export const ExercisesContainer = styled.div`
  margin-top: 20px !important;
  margin-bottom: 20px !important;
  .ant-collapse {
    border: 0 !important;
  }
`;

const Wrapper = styled.div`
  background: #59a9a0;
  border-radius: 6px;
  padding: 20px 20px 0;
  margin-top: 40px;

  ${myMedia.desktop`
          padding: 20px 0 0;
      `}
`;

interface InterfaceProps {
  exercises: IExercise[];
  prompts: IPrompt[];
  allPrompts?: IPrompt[];
  synthesisPrompts?: IPrompt[];
  workbook: IWorkbook;
  currentPage: number;
  nextPage?: number;
  answers: Record<string, IAnswer>;
  uploads: Record<string, Record<string, UploadFile>>;
  authUser: IUser;
  organization: IOrganization;
  teams: ITeam[];
}

interface IState {
  sharedExercises: IExercise[];
  dontShareFiles: string[];
  sharedContent: { promptKey: string; content: string }[];
}

export class Exercises extends React.Component<InterfaceProps, IState> {
  state = {
    sharedExercises: this.props.exercises,
    dontShareFiles: [],
    sharedContent: this.props.prompts.map((prompt) => {
      const answer = this.props.answers[prompt.key];
      const initialAnswer = getInitialState({
        answer,
        prompt,
        currentPage: this.props.currentPage,
      }); // TODO: Rename getInitialState to something else, perhaps store this data in index.tsx?
      const content = initialAnswer.defaultValue;
      return {
        promptKey: prompt.key,
        content,
        exerciseKey: prompt.exercise,
      };
    }),
  };

  rerenderer() {
    location.reload();
  }

  shareOrUnShare = (exercise: IExercise, value: boolean) => {
    const { sharedExercises } = this.state;
    if (value) {
      this.setState({
        sharedExercises: [...sharedExercises, exercise],
      });
    } else {
      if (exercise.recommendSharing) {
        message.warning(
          "Your advisors would greatly appreciate if you shared this section"
        );
      }

      this.setState({
        sharedExercises: sharedExercises.filter(
          (sharedExercise: IExercise) => sharedExercise.key !== exercise.key
        ),
      });
    }
  };

  shareFile = (id: string) => {
    const { dontShareFiles } = this.state;
    this.setState({
      dontShareFiles: dontShareFiles.filter((existingId) => existingId !== id),
    });
  };

  hideFile = (id: string) => {
    const { dontShareFiles } = this.state;
    this.setState({
      dontShareFiles: [...dontShareFiles, id],
    });
  };

  updateSharedContent = (prompt: IPrompt, content: string) => {
    const { sharedContent } = this.state;
    this.setState({
      sharedContent: sharedContent.map((item) => {
        if (item.promptKey === prompt.key) {
          return {
            ...item,
            content,
          };
        } else {
          return item;
        }
      }),
    });
  };

  print = () => {
    const { authUser, exercises } = this.props;
    const { sharedContent } = this.state;

    makeDoc(
      true,
      authUser.displayName || authUser.username,
      exercises,
      sharedContent
    );
  };

  render() {
    const { sharedExercises, sharedContent, dontShareFiles } = this.state;
    const props = this.props;
    const showWordCloud =
      props.currentPage === StepEnum.Synthesis ||
      props.currentPage === StepEnum.Consolidate;
    const showPreSurvey =
      props.currentPage === StepEnum.Work && props.workbook.preSurvey;
    const showPostSurvey =
      props.currentPage === StepEnum.Review && props.workbook.postSurvey;

    return (
      <div>
        <NotePad
          showMobile={true}
          teams={props.teams}
          answers={props.answers}
          authUser={props.authUser}
          workbook={props.workbook}
        />

        <div id="first-step">
          <HelpfulInstructions page={props.currentPage} />
        </div>

        <Row gutter={24} type={"flex"}>
          <Col lg={{ span: 19, order: 1 }} xs={{ span: 24, order: 2 }}>
            <Wrapper>
              {showPreSurvey && (
                <ExercisesContainer>
                  <Collapse
                    defaultActiveKey={["1"]}
                    destroyInactivePanel={true}
                  >
                    <Collapse.Panel
                      header={<ExerciseHeader title={"Pre Survey"} />}
                      key="1"
                    >
                      <iframe
                        src={props.workbook.preSurvey}
                        width={"100%"}
                        height={600}
                      />
                    </Collapse.Panel>
                  </Collapse>
                </ExercisesContainer>
              )}

              <div id="second-step">
                <Introduction
                  workbook={props.workbook}
                  currentPage={props.currentPage}
                />
              </div>

              {sortByPriority(props.exercises).map((exercise) => {
                return (
                  <Exercise
                    shareFile={this.shareFile}
                    hideFile={this.hideFile}
                    dontShareFiles={this.state.dontShareFiles}
                    key={exercise.key}
                    sharedExercises={sharedExercises}
                    shareOrUnShare={(value: boolean) =>
                      this.shareOrUnShare(exercise, value)
                    }
                    currentPage={props.currentPage}
                    exercise={exercise}
                    authUser={props.authUser}
                    updateSharedContent={this.updateSharedContent}
                    answers={props.answers}
                    uploads={props.uploads}
                    allPrompts={
                      props.allPrompts
                        ? props.allPrompts.filter(
                            (prompt) => prompt.exercise === exercise.key
                          )
                        : []
                    }
                    prompts={props.prompts.filter(
                      (prompt) => prompt.exercise === exercise.key
                    )}
                  />
                );
              })}

              {showPostSurvey && (
                <ExercisesContainer key={"survey-post"}>
                  <Collapse
                    defaultActiveKey={["1"]}
                    destroyInactivePanel={true}
                  >
                    <Collapse.Panel
                      header={<ExerciseHeader title={"Post Survey"} />}
                      key="1"
                    >
                      <iframe
                        src={props.workbook.postSurvey}
                        width={"100%"}
                        height={600}
                      />
                    </Collapse.Panel>
                  </Collapse>
                </ExercisesContainer>
              )}

              <MiniCta
                relative={true}
                className="tooltip-3-2"
                style={{
                  bottom:
                    props.currentPage === StepEnum.Consolidate
                      ? "85px"
                      : "-85px",
                }}
              >
                {props.currentPage === StepEnum.Review && (
                  <GradientButton onClick={() => window.location.reload()}>
                    <Icon type={"fire"} /> Refresh Document
                  </GradientButton>
                )}
                {props.currentPage === StepEnum.Review && (
                  <DocumentPreservation
                    sharedExercises={sharedExercises}
                    sharedContent={sharedContent}
                    uploads={props.uploads}
                    dontShareFiles={dontShareFiles}
                    authUser={props.authUser}
                  />
                )}

                {(props.currentPage === StepEnum.Review ||
                  props.currentPage === StepEnum.Consolidate) &&
                  !isMobile && (
                    <GradientButton onClick={this.print}>
                      <Icon type={"printer"} /> Download & Print
                    </GradientButton>
                  )}
                {props.currentPage === StepEnum.Review && (
                  <ShareButton
                    sharedExercises={sharedExercises}
                    sharedContent={sharedContent}
                    uploads={props.uploads}
                    dontShareFiles={dontShareFiles}
                    authUser={props.authUser}
                    teams={props.teams}
                  />
                )}
              </MiniCta>

              {showWordCloud && (
                <ExercisesContainer>
                  <Collapse
                    defaultActiveKey={["1"]}
                    destroyInactivePanel={true}
                  >
                    <Collapse.Panel
                      header={<ExerciseHeader title={"My Word Cloud"} />}
                      key="1"
                    >
                      <Button
                        block={true}
                        onClick={() => {
                          this.rerenderer();
                        }}
                        type="primary"
                        style={{ width: "300px" }}
                      >
                        Refresh
                      </Button>
                      <WordCloud
                        words={(props.synthesisPrompts || [])
                          .map(
                            (prompt) =>
                              getInitialState({
                                currentPage: props.currentPage,
                                answer: props.answers[prompt.key],
                                prompt,
                              }).defaultValue
                          )
                          .join(" ")}
                      />
                    </Collapse.Panel>
                  </Collapse>
                </ExercisesContainer>
              )}

              {props.nextPage && (
                <div className="tooltip-9">
                  <NextPage
                    page={props.nextPage}
                    text="Good job! Ready for the next step?"
                    relative={true}
                  />
                </div>
              )}
            </Wrapper>
          </Col>

          <Col sm={{ span: 5, order: 2 }} xs={{ span: 0 }}>
            <div id="third-step">
              <NotePad
                showMobile={false}
                teams={props.teams}
                answers={props.answers}
                authUser={props.authUser}
                workbook={props.workbook}
              />
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}
