import { ITeam } from "../../firebase/models";

import { List } from "antd";
import { Avatar } from "antd";
import styled from "styled-components";
import * as React from "react";

const Container = styled.div`
  margin-top: -23px;
  font-family: "Hind Vadodara", sans-serif !important;
  font-weight: 500;
`;
const Description = styled.div`
  margin-top: -6px;
  font-size: 14px;
`;

const ListItem = styled(List.Item)`
  padding: 0 !important;
`;

const AvatarStyled = styled(Avatar)`
  img {
    height: auto !important;
    width: 100% !important;
    display: block !important;
  }
`;

interface InterfaceProps {
  team: ITeam;
  children: React.ReactElement;
}

export const AccountAvatar = (props: InterfaceProps) => (
  <ListItem>
    <List.Item.Meta
      avatar={<AvatarStyled size="large" src={props.team.imageUrl} />}
      title={<Container>{props.team.name}</Container>}
      description={<Description>{props.children}</Description>}
    />
  </ListItem>
);
