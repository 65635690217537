import * as React from "react";
import { Menu, Icon } from "antd";
import { Link } from "react-router-dom";
import * as routes from "../../constants/routes";
import styled from "styled-components";
const Container = styled.div`
  margin-bottom: 20px;
`;

export class StudioNav extends React.Component {
  render() {
    return (
      <Container>
        <Menu mode="horizontal">
          <Menu.Item key="workbook">
            <Link to={routes.STUDIO}>
              <Icon type="file-add" /> Workbook Editor
            </Link>
          </Menu.Item>
          <Menu.Item key="organization">
            <Link to={routes.ORG_STUDIO}>
              <Icon type="usergroup-add" /> Organization Editor
            </Link>
          </Menu.Item>

          <Menu.Item key="users">
            <Link to={routes.USER_STUDIO}>
              <Icon type="user" /> User Stats
            </Link>
          </Menu.Item>
        </Menu>
      </Container>
    );
  }
}
