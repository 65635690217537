import * as React from "react";
import { IExercise } from "../../firebase/models";
import * as db from "../../firebase/db";
import { message, Select } from "antd";

interface InterfaceProps {
  exercise: IExercise;
  priority?: number;
}

const options = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];

export class PriorityChooser extends React.Component<InterfaceProps> {
  updatePriority = (priority: number) => {
    message.info("Exercise Order updated!");
    db.doUpdateExercise({
      ...this.props.exercise,
      priority
    });
  };

  render() {
    return (
      <div>
        <u>Order:</u>
        <Select
          onChange={this.updatePriority}
          style={{ width: 80 }}
          defaultValue={this.props.priority}
        >
          {options.map(option => (
            <Select.Option value={option}>{option}</Select.Option>
          ))}
        </Select>
      </div>
    );
  }
}
