import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";

const config = {
  apiKey: "AIzaSyDEDYRQDO1nI4NMa_yrIFQrpZu91j-l1A4",
  authDomain: "livingwiselyfirebaserealtime.firebaseapp.com",
  databaseURL: "https://livingwiselyfirebaserealtime.firebaseio.com",
  messagingSenderId: "667065077195",
  projectId: "livingwiselyfirebaserealtime",
  storageBucket: "livingwiselyfirebaserealtime.appspot.com"
};

if (!firebase.apps.length) {
  firebase.initializeApp(config);
}

export const auth = firebase.auth();
export const db = firebase.database();
