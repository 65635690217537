export const ORGANIZATION = "/:organization";

export const SIGN_UP = "/signup";
export const SIGN_IN = "/signin";
export const LANDING = "/landing";
export const HOME = "/";
export const ACCOUNT = "/account";
export const PROMPTS = "/prompts";
export const STUDIO = "/studio";
export const IMAGE = "/image/:imgKey";
export const ORG_STUDIO = "/org-studio";
export const USER_STUDIO = "/user-studio";
export const KEY = "/:key?";
export const PAGE = "/:page?";
export const PASSWORD_FORGET = "/password_forget";
