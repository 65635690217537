import { UploadFile } from "antd/lib/upload/interface";

export type IUser = {
  email: string | null;
  displayName: string | null;
  uid: string;
  // userRole: string | null;
} & {
  [prop: string]: any;
};

export interface IPrompt {
  name: string;
  options?: string[];
  key: string;
  workbook: string;
  synthesis?: boolean;
  exercise: string;
  defaultValue?: string;
  defaultAnswer?: IAnswer;
  promptContent?: string[];
  answers?: IAnswer[];
  priority?: number;
}

export interface IUploadFile extends UploadFile {
  key?: string;
}

export interface IWorkbook {
  name: string;
  key: string;
  extraStep?: boolean;
  preSurvey?: string;
  postSurvey?: string;
  introduction?: string;
  description?: string;
  imageUrl?: string;
  lastOpened?: Date;
}

export interface IOrganization {
  name: string;
  key: string;
  subDomain?: string;

  users?: string[];
  imageUrl?: string;
  introduction?: string;
  introductionTitle?: string;
  workbooks: Record<string, boolean>;
}

export interface ITeam {
  name: string;
  key: string;
  phone: string;
  email: string;
  role: string;
  organization: string;
  imageUrl?: string;
}

export interface IExercise {
  name: string;
  key: string;
  recommendSharing?: boolean;
  workbook: string;
  locked?: boolean;
  priority?: number;
  description: string; // should be called workTips, shows up in Step 1
  synthesis?: boolean;
  reflection?: string; // should be called reflectionTips, shows up in Step 2
  reviewTips?: string; // should be called reviewTips, shows up in Step 3
  carryForward?: boolean;
}

export interface IAnswer {
  content: string;
  key: string; // stored as key of associated prompt
  updatedAt?: string;
}

export enum StepEnum {
  Work,
  Synthesis,
  Review, // actually the share step
  Consolidate // actually the optional step that is sometimes before the share step
}
