import * as React from "react";
import { withRouter, RouteComponentProps } from "react-router";

interface InterfaceProps extends RouteComponentProps<{ imgKey: string }> {}

class ImageComponent extends React.Component<InterfaceProps> {
  render() {
    const { match } = this.props;

    return <img src={match.params.imgKey} />;
  }
}

export const Image = withRouter(ImageComponent);
