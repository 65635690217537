import * as React from "react";
import styled from "styled-components";
import { Draggable } from "react-beautiful-dnd";
import { IPrompt } from "../../firebase/models";
import { doUpdatePrompt } from "../../firebase/db";
import { DeletePrompt } from "../PromptStudio/DeletePrompt";
import { MarkdownEditorModal } from "../../components/MarkdownEditorModal";
import { MarkdownPreview } from "../../components/MarkdownPreview";
import { Button } from "antd";
import { FlexDivide } from "../../components/Styled";

interface IContainerProps {
  isDragging: boolean;
  ref: any;
}

interface InterfaceProps {
  prompt: IPrompt;
  index: number;
}

const Container = styled.div`
  border-radius: 2px;
  padding: 8px;
  position: relative;
  margin-bottom: 8px;
  transition: background-color 0.2s ease;
  background-color: ${(props: IContainerProps) =>
    props.isDragging ? "lightgreen" : "#eee"};
`;

const ParagraphWrapper = styled.div`
  .ant-typography {
    padding-right: 50px;
  }

  .ant-typography-edit {
    position: absolute;
    right: 37px;
    top: 10px;
  }
`;

export class DraggableBox extends React.Component<InterfaceProps> {
  state = {
    prompt: this.props.prompt
  };

  handleChange = (name: string) => {
    const { prompt } = this.state;
    const newPrompt = { ...prompt, name };
    doUpdatePrompt(newPrompt);
    this.setState({ prompt: newPrompt });
  };

  handleDefaultValueChange = (defaultValue: string) => {
    const { prompt } = this.state;
    const newPrompt = { ...prompt, defaultValue };
    doUpdatePrompt(newPrompt);
    this.setState({ prompt: newPrompt });
  };

  render() {
    const { index } = this.props;
    const { prompt } = this.state;
    return (
      <Draggable draggableId={prompt.key} index={index}>
        {(provided, snapshot) => (
          <Container
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef}
            isDragging={snapshot.isDragging}
          >
            <ParagraphWrapper>
              <FlexDivide style={{ flexWrap: "wrap" }}>
                <MarkdownPreview markdown={prompt.name} />

                <div style={{ marginLeft: "auto" }}>
                  <MarkdownEditorModal
                    value={prompt.name}
                    save={this.handleChange}
                  >
                    <Button icon="edit" type="primary">
                      Edit Prompt
                    </Button>
                  </MarkdownEditorModal>
                  &nbsp;
                  <DeletePrompt prompt={prompt}>
                    <Button icon="delete" type="danger" />
                  </DeletePrompt>
                </div>

                <div></div>
              </FlexDivide>
            </ParagraphWrapper>
          </Container>
        )}
      </Draggable>
    );
  }
}
