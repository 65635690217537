import styled from "styled-components";

export const GradientButton = styled.button`
  display: inline-flex;
  font-size: 16px;
  letter-spacing: -0.1px;
  font-weight: 600;
  line-height: 16px;
  text-decoration: none !important;
  background-color: #fff;
  background: linear-gradient(65deg, #2eb0a5 0, #aacfcb 100%);
  color: #fff !important;
  transition: background 0.15s ease;
  border: none !important;
  border-radius: 2px;
  cursor: pointer;
  justify-content: center;
  padding: 16px 32px !important;

  i {
    display: inline-block;
    padding-right: 5px;
  }

  + button {
    margin-left: 10px;
  }
`;

export const FlexDivide = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
