import * as React from "react";
import { db } from "../../firebase";
import { IOrganization, ITeam } from "../../firebase/models";
import { TeamTabs } from "./TeamTabs";
import { withKeys } from "../../firebase/utils";

interface InterfaceState {
  teams: Record<string, ITeam>;
}

interface InterfaceProps {
  organization: IOrganization;
}

class TeamStudioComponent extends React.Component<
  InterfaceProps,
  InterfaceState
> {
  state = {
    teams: {}
  };

  fetchData = async () => {
    const { organization } = this.props;

    const snapshot = await db.onceGetTeams(organization.key);

    this.setState({
      teams: withKeys(snapshot)
    });

    db.onAddedTeams(organization.key, (snapshot: any) => {
      this.setState({
        teams: {
          ...this.state.teams,
          [snapshot.key]: {
            ...snapshot.val(),
            key: snapshot.key
          }
        }
      });
    });
  };

  async componentDidMount() {
    this.fetchData();
  }

  render() {
    const { teams } = this.state;
    const { organization } = this.props;

    return (
      <div>
        <TeamTabs
          key={Object.keys(teams).length}
          teams={teams}
          organization={organization}
        />
      </div>
    );
  }
}

export const TeamStudio = TeamStudioComponent;
