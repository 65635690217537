import * as React from "react";
import { db } from "../../firebase";
import { IExercise, IPrompt, IWorkbook } from "../../firebase/models";
import { DragDrop } from "./DragDrop";
import { priorityNumber, sortByPriority, withKeys } from "../../firebase/utils";
import {
  doUpdatePrompt,
  onAddedExercises,
  onAddedPrompts,
  onRemovedExercises,
  onRemovedPrompts
} from "../../firebase/db";
import * as _ from "lodash";
import { Button, Input, Spin, Typography, Form, Switch } from "antd";
import { Avatar } from "../../components/Avatar";
import { GridRow } from "../../components/GridRow";
import { DeleteWorkbook } from "./DeleteWorkbook";
import styled from "styled-components";
import { MarkdownEditorModal } from "../../components/MarkdownEditorModal";
import { MarkdownPreview } from "../../components/MarkdownPreview";
import { Message } from "../Workbook/Message";
import { CloneWorkbook } from "./CloneWorkbook";
const RightSide = styled.div`
  float: right;
`;

const Introduction = styled.div`
  display: flex;
`;

const MessageWrapper = styled.div`
  flex: 1;

  > div {
    margin-top: 0;
    margin-bottom: 30px;
    padding-bottom: 10px;
  }
`;
interface InterfaceState {
  workbook: Partial<IWorkbook>;
  loaded: boolean;
  prompts: {
    [key: string]: IPrompt;
  };
  exercises: {
    [key: string]: IExercise;
  };
}

interface IPathParams {
  workbookKey: string;
  handleChange: (string: string, workbook: IWorkbook) => void;
}

export class EditableWorkbook extends React.Component<
  IPathParams,
  InterfaceState
> {
  state = {
    workbook: {
      name: "",
      key: "",
      description: "",
      introduction: "",
      preSurvey: "",
      postSurvey: "",
      imageUrl: "",
      extraStep: false
    },
    prompts: {},
    exercises: {},
    loaded: false
  };

  promptLength = () => {
    return priorityNumber(_.keys(this.state.prompts));
  };

  handleUpload = (imageUrl: string) => {
    return db.doUpdateWorkbook({ ...this.state.workbook, imageUrl });
  };

  fetchData = async () => {
    const { workbookKey } = this.props;
    const workbook = await db.onceGetWorkbook(workbookKey);
    const prompts = await db.onceGetWorkbookPrompts(workbookKey);
    const exercises = await db.onceGetWorkbookExercises(workbookKey);

    console.log("workbook", workbook);

    this.setState({
      loaded: true,
      workbook: { ...workbook.val(), key: workbook.key },
      exercises: withKeys(exercises),
      prompts: withKeys(prompts)
    });

    onAddedPrompts(workbookKey, (snapshot: any) => {
      this.setState({
        prompts: {
          ...this.state.prompts,
          [snapshot.key]: {
            ...snapshot.val(),
            key: snapshot.key,
            priority: this.promptLength()
          }
        }
      });
    });

    onAddedExercises(workbookKey, (snapshot: any) => {
      this.setState({
        exercises: {
          ...this.state.exercises,
          [snapshot.key]: {
            ...snapshot.val(),
            key: snapshot.key
          }
        }
      });
    });

    onRemovedPrompts((snapshot: any) => {
      const clone = { ...this.state.prompts };
      delete clone[snapshot.key];

      this.setState({
        prompts: clone
      });
    });

    onRemovedExercises((snapshot: any) => {
      const clone = { ...this.state.exercises };
      delete clone[snapshot.key];

      this.setState({
        exercises: clone
      });
    });
  };

  async componentDidMount() {
    this.fetchData();
  }

  filterByExercise = (exerciseKey: string) => {
    const prompts = _.values(this.state.prompts) as IPrompt[];
    return prompts.filter(prompt => prompt.exercise === exerciseKey);
  };

  handleDescriptionChange = (description: string) => {
    const { workbook } = this.state;
    this.setState({
      workbook: { ...workbook, description }
    });
    db.doUpdateWorkbook({ ...workbook, description });
  };

  handleNameChange = (name: string) => {
    const { workbook } = this.state;
    this.setState({
      workbook: { ...workbook, name }
    });
    this.props.handleChange(name, workbook);
  };

  handlePreSurveyChange = (preSurvey: string) => {
    const { workbook } = this.state;
    this.setState({
      workbook: { ...workbook, preSurvey }
    });
    db.doUpdateWorkbook({ ...workbook, preSurvey });
  };

  handlePostSurveyChange = (postSurvey: string) => {
    const { workbook } = this.state;
    this.setState({
      workbook: { ...workbook, postSurvey }
    });
    db.doUpdateWorkbook({ ...workbook, postSurvey });
  };

  handleIntroductionChange = (introduction: string) => {
    const { workbook } = this.state;
    this.setState({
      workbook: { ...workbook, introduction }
    });
    db.doUpdateWorkbook({ ...workbook, introduction });
  };

  exerciseOrder = () => {
    const { exercises } = this.state;

    return sortByPriority(Object.values(exercises)).map(
      exercise => exercise.key
    );
  };

  handleDragEnd = (result: {
    destination: { droppableId: string; index: number };
    source: { droppableId: string; index: number };
    draggableId: string;
  }) => {
    const { destination, source, draggableId } = result;

    if (!destination) {
      return;
    }

    const sourceExercise = source.droppableId;
    const sourceIndex = source.index;
    const destinationExercise = destination.droppableId;
    const destinationIndex = destination.index;

    if (
      destinationExercise === sourceExercise &&
      destinationIndex === sourceIndex
    ) {
      return;
    }

    const prompt = this.state.prompts[draggableId];
    const newExercise = destinationExercise || sourceExercise;
    const priorities = _.sortBy(
      this.filterByExercise(newExercise)
        .map(p => p.priority)
        .filter(priority => priority !== prompt.priority)
    );
    const newPriority =
      ((priorities[destinationIndex - 1] || 0.1) +
        (priorities[destinationIndex] || this.promptLength())) /
      2;

    doUpdatePrompt({
      key: draggableId,
      priority: newPriority,
      exercise: newExercise
    });

    this.setState({
      prompts: {
        ...this.state.prompts,
        [prompt.key]: {
          ...prompt,
          exercise: newExercise,
          priority: newPriority
        }
      }
    });
  };

  toggleDocumentStep = (new_extra_step: boolean) => {
    db.doUpdateWorkbook({
      ...this.state.workbook,
      extraStep: new_extra_step
    });

    this.setState({
      workbook: {
        ...this.state.workbook,
        extraStep: new_extra_step
      }
    });
  };

  render() {
    const { workbook, prompts, exercises, loaded } = this.state;
    return (
      <div>
        {!loaded && <Spin size="large" />}

        <GridRow
          cols={[
            loaded && (
              <Avatar
                dbRef={workbook.key}
                handleUpload={this.handleUpload}
                imageUrl={workbook.imageUrl}
              />
            ),
            loaded && (
              <span>
                <div>
                  <b>Name:</b>{" "}
                  <Typography.Text
                    editable={{
                      onChange: this.handleNameChange
                    }}
                  >
                    {workbook.name}
                  </Typography.Text>
                  <RightSide>
                    <Switch
                      key="document_step"
                      checked={this.state.workbook.extraStep}
                      onChange={e => this.toggleDocumentStep(e)}
                    />{" "}
                    <span>"Create Your Document" Step?</span>
                    <br />
                    <DeleteWorkbook workbook={workbook}>
                      <Button icon="delete" type="danger">
                        Delete Entire Workbook
                      </Button>
                    </DeleteWorkbook>
                    <br />
                    <CloneWorkbook
                      workbook={workbook}
                      prompts={_.values(prompts)}
                      exercises={_.values(exercises)}
                    >
                      <Button icon="snippets">Clone Workbook</Button>
                    </CloneWorkbook>
                  </RightSide>
                </div>
                <b>Brief Description:</b>{" "}
                <Typography.Text
                  editable={{ onChange: this.handleDescriptionChange }}
                >
                  {workbook.description || "Brief Description of Workbook"}
                </Typography.Text>
                <Introduction>
                  <MessageWrapper>
                    <Message>
                      <MarkdownPreview markdown={workbook.introduction} />
                      <MarkdownEditorModal
                        value={workbook.introduction}
                        save={this.handleIntroductionChange}
                      >
                        <Button icon="edit" type="primary">
                          Edit Workbook Introduction
                        </Button>
                      </MarkdownEditorModal>
                    </Message>
                  </MessageWrapper>
                </Introduction>
                <div>
                  <Form.Item key="preSurvey" label="Pre Survey Link">
                    <Input
                      defaultValue={workbook.preSurvey}
                      name="preSurvey"
                      onBlur={(e: any) =>
                        this.handlePreSurveyChange(e.target.value)
                      }
                    />
                  </Form.Item>

                  <Form.Item key="postSurvey" label="Post Survey Link">
                    <Input
                      defaultValue={workbook.postSurvey}
                      name="postSurvey"
                      onBlur={(e: any) =>
                        this.handlePostSurveyChange(e.target.value)
                      }
                    />
                  </Form.Item>
                </div>
              </span>
            )
          ]}
          spans={[3, 21]}
        />

        {loaded && workbook && (
          <DragDrop
            handleDragEnd={this.handleDragEnd}
            exerciseOrder={this.exerciseOrder()}
            workbook={workbook}
            exercises={exercises}
            prompts={prompts}
          />
        )}
      </div>
    );
  }
}
