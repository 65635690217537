import * as React from "react";
import * as _ from "lodash";
import { Button, Form, Input, message, Select } from "antd";
import { IOrganization, IWorkbook } from "../../firebase/models";
import styled from "styled-components";
import * as db from "../../firebase/db";
import { doUpdateOrganization } from "../../firebase/db";
import { Avatar } from "../../components/Avatar";

interface InterfaceProps {
  organization: IOrganization;
  workbooks: IWorkbook[];
}

type InterfaceState = ReturnType<typeof getInitialState>;

const StyledForm = styled(Form)`
  .ant-form-item {
    margin-bottom: 20px !important;
  }
`;

const getInitialState = (props: InterfaceProps) => {
  return {
    introduction: props.organization.introduction || "",
    subDomain: props.organization.subDomain || "",
    introductionTitle: props.organization.introductionTitle || "",
    workbooks: props.organization.workbooks || {}
  };
};

export class OrganizationForm extends React.Component<
  InterfaceProps,
  InterfaceState
> {
  readonly state = getInitialState(this.props);

  handleChange = (
    event: React.FormEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const target = event.target as HTMLInputElement;
    this.setState({
      [target.name]: target.value
    } as any);
  };

  handleUpload = (imageUrl: string) => {
    return db.doUpdateOrganization({ ...this.props.organization, imageUrl });
  };

  handleSubmit = () => {
    console.log(this.props.organization, this.state);

    doUpdateOrganization({
      ...this.props.organization,
      ...this.state
    })
      .then(() => message.success("Organization Updated!"))
      .catch(() => message.error("Organization Failed to Update"));
  };

  handleWorkbookChange = (workbooks: string[]) => {
    const dbWorkbooks = {};
    workbooks.forEach(workbook => (dbWorkbooks[workbook] = true));
    this.setState({ workbooks: dbWorkbooks });
    doUpdateOrganization({
      ...this.props.organization,
      workbooks: dbWorkbooks
    });
  };

  render() {
    const { organization, workbooks } = this.props;
    const { introduction, introductionTitle, subDomain } = this.state;

    return (
      <div>
        <Avatar
          dbRef={organization.key}
          handleUpload={this.handleUpload}
          imageUrl={organization.imageUrl}
        />

        <StyledForm layout={"vertical"}>
          <Form.Item label={"Associated Workbooks"}>
            <Select
              mode="multiple"
              defaultValue={_.keys(organization.workbooks)}
              onChange={this.handleWorkbookChange}
            >
              {workbooks.map(workbook => (
                <Select.Option key={workbook.key}>
                  {workbook.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            key="subDomain"
            label="Sub Domain"
            help="e.g. ghcflegacy or my-new-client"
          >
            <Input
              value={subDomain}
              name="subDomain"
              onChange={this.handleChange}
            />
          </Form.Item>

          <Form.Item key="introduction" label="Login Page Header">
            <Input.TextArea
              rows={2}
              value={introductionTitle}
              name="introductionTitle"
              onChange={this.handleChange}
            />
          </Form.Item>

          <Form.Item key="introduction" label="Login Page Body">
            <Input.TextArea
              rows={8}
              value={introduction}
              name="introduction"
              onChange={this.handleChange}
            />
          </Form.Item>

          <Form.Item key="submit">
            <Button type="primary" onClick={this.handleSubmit}>
              Update
            </Button>
          </Form.Item>
        </StyledForm>
      </div>
    );
  }
}
