import { Button, Col, Input, message, Row } from "antd";
import * as _ from "lodash";
import * as React from "react";
import EdiText from "react-editext";
import styled from "styled-components";
import { consolidationPromptKey } from ".";
import { MarkdownPreview } from "../../components/MarkdownPreview";
import { FlexDivide } from "../../components/Styled";
import { db } from "../../firebase";
import {
  IAnswer,
  IExercise,
  IPrompt,
  IUser,
  StepEnum,
} from "../../firebase/models";
import { SharedSpeechConsumer } from "../../providers/SharedSpeech.js";

const Container = styled.div`
  margin-top: 20px;
  .textarea,
  .content {
    white-space: pre-line;
  }
`;

export const Label = styled.div`
  width: 100%;
  margin-bottom: 5px;
  font-weight: 500;
`;

export const TextCenter = styled.div`
  text-align: center;em
`;

export const RightAlign = styled.div`
  text-align: right;
  margin-top: 10px;
`;

export const FlexDivideHeader = styled(FlexDivide)`
  margin-bottom: 5px;
  flex-wrap: wrap;
`;

const FlexDivideBody = styled(FlexDivide)`
  .ant-typography {
    white-space: pre-line;
    width: 100%;
  }
`;

interface InterfaceProps {
  prompt: IPrompt;
  exercise?: IExercise;
  answer?: IAnswer;
  currentPage: number;
  index?: number;
  disabled?: boolean;
  authUser: IUser;
  updateSharedContent?: (prompt: IPrompt, value: string) => void;
}

interface IState {
  defaultValue: string;
}

function isLater(str1?: string, str2?: string) {
  if (!str1 || !str2) return false;
  return new Date(str1) > new Date(str2);
}

const getContentOfAnswer = (answer: IAnswer) => answer.content || "";
const hasNoContent = (answer: IAnswer) =>
  !getContentOfAnswer(answer).replace(/\s+/g, "");

export const getInitialState = ({
  answer: potentialAnswer,
  prompt,
  currentPage,
}: {
  answer?: IAnswer;
  prompt: IPrompt;
  currentPage: number;
}) => {
  const answer = potentialAnswer || ({ content: "", updatedAt: "" } as IAnswer);

  // answers property set in Workbook/index.tsx to help generate default answers for synthesis, consolidation, and review steps.
  // once a prompt is edited, the answers property is more or less ignored.
  // if you delete the content of a prompt that depends on answers, it will regenerate the default content
  const synthesisAnswers = prompt.answers || [];

  const NEW_CONTENT_SYNTHESIS_SEPARATOR = "\n\n=== STEP 1 UPDATES ===\n";

  const updatedStep1Answers = synthesisAnswers
    .filter((promptAnswer) => isLater(promptAnswer.updatedAt, answer.updatedAt)) // an answer has been updated!
    .map(getContentOfAnswer) // get the content
    .filter((content) => !answer.content.includes(content)); // double check the content isn't already in the synthesis;

  const hasUpdatedAnswersInStep1 = updatedStep1Answers.length > 0;

  const isSynthesisStep = currentPage === StepEnum.Synthesis;
  const isReviewStep = currentPage === StepEnum.Review;
  const isConsolidationStep = currentPage === StepEnum.Consolidate;
  const isConsolidationPromptInReview =
    isReviewStep && prompt.key === consolidationPromptKey;

  if (
    (isSynthesisStep || isReviewStep || isConsolidationStep) &&
    hasNoContent(answer)
  ) {
    // Step 2, Step 3, or Step 4 and no answer? Do some mapping to generate one
    return {
      defaultValue: synthesisAnswers.map(getContentOfAnswer).join("\n"),
    };
  } else if (
    (isSynthesisStep || isReviewStep) &&
    hasUpdatedAnswersInStep1 &&
    !isConsolidationPromptInReview
  ) {
    // Step 2 or Step 4 and an updated answer? Append content to the original answer.
    // Exception: Consolidation Prompt in Review Step for 4-step workbooks
    // Consolidation Prompt should not be updated ever in either Review or Consolidation Step
    return {
      defaultValue:
        getContentOfAnswer(answer) +
        NEW_CONTENT_SYNTHESIS_SEPARATOR +
        updatedStep1Answers.join("\n"),
    };
  } else {
    // Otherwsie, just return the answer
    return {
      defaultValue: getContentOfAnswer(answer),
    };
  }
};

export class Prompt extends React.Component<InterfaceProps, IState> {
  constructor(props: any) {
    super(props);
    this.state = {
      defaultValue: "",
    };
  }

  doUpdateAnswer = _.debounce((content: string) => {
    const { authUser, prompt, answer } = this.props;

    const updatedAt = new Date().toString();
    const answerPayload = answer
      ? { ...answer, content, updatedAt }
      : {
          content,
          updatedAt,
        };

    console.log("updating answer!", answerPayload);
    db.doSetAnswer({ prompt, authUser, answer: answerPayload });
  }, 300);

  handleChange = (content: string) => {
    this.setState({
      defaultValue: content,
    });

    this.doUpdateAnswer(content);
  };

  appendContent = (content: string) => {
    const { defaultValue } = this.state;
    const maybeNewLine = defaultValue ? "\n" : "";
    const newContent = defaultValue + maybeNewLine + content;

    message.success("Option Selected!");
    this.setState({
      defaultValue: newContent,
    });

    this.doUpdateAnswer(newContent);
  };

  getMinRows = () => {
    const { exercise, currentPage } = this.props;

    if (currentPage === StepEnum.Review) return 9;
    else if (exercise && exercise.carryForward) return 1;
    else return 3;
  };

  getButton = () => {
    const { index, currentPage } = this.props;
    if (currentPage === StepEnum.Work && index)
      return (
        <Button size="large" type="primary" shape="circle">
          {index}
        </Button>
      );
    else if (currentPage === StepEnum.Synthesis && index)
      return <Button size="large" icon="bulb" type="primary" shape="circle" />;
    else if (currentPage === StepEnum.Review && index)
      return <Button size="large" icon="mail" type="primary" shape="circle" />;
    else return null;
  };

  async componentDidMount() {
    // const { authUser, prompt, currentPage } = this.props;

    const initialState = getInitialState(this.props);
    this.setState({
      defaultValue: initialState.defaultValue,
    });

    // if (currentPage === StepEnum.Synthesis) {
    //   const content = initialState.defaultValue;
    //   const updatedAt = new Date().toString();
    //   const answerPayload = {
    //     content,
    //     updatedAt,
    //   };
    //   db.doSetAnswer({ prompt, authUser, answer: answerPayload });
    // }
  }

  render() {
    const defaultValue = this.state.defaultValue;
    const carryForward =
      this.props.exercise && this.props.exercise.carryForward;
    const hidePrompt =
      this.props.currentPage === StepEnum.Review && !defaultValue;
    const promptName =
      this.props.currentPage !== StepEnum.Work && !carryForward
        ? "Your Responses"
        : this.props.prompt.name;

    return hidePrompt ? null : (
      <SharedSpeechConsumer>
        {({
          startTalking,
          stopTalking,
          key,
          browserSupportsSpeechRecognition,
        }) => {
          return (
            <Container>
              <Row type="flex" align="top">
                {this.props.index && (
                  <Col xs={0} sm={2}>
                    <TextCenter>{this.getButton()}</TextCenter>
                  </Col>
                )}
                <Col xs={24} sm={this.props.index ? 22 : 24}>
                  {promptName && (
                    <Label>
                      <MarkdownPreview
                        appendContent={this.appendContent}
                        markdown={promptName}
                      />
                    </Label>
                  )}
                  <FlexDivideBody>
                    {this.props.currentPage === StepEnum.Review ? (
                      <EdiText
                        type="textarea"
                        hint="Click the 'Apply' button once you're done making changes!"
                        saveButtonContent="Apply"
                        cancelButtonContent="Cancel"
                        viewProps={{
                          className: "content",
                        }}
                        inputProps={{
                          className: "textarea",
                          style: {
                            outline: "none",
                            minWidth: window.innerWidth / 2,
                          },
                          rows: 8,
                        }}
                        value={defaultValue}
                        onSave={(content: string) => {
                          this.setState({ defaultValue: content });
                          if (
                            this.props.updateSharedContent &&
                            this.props.prompt
                          )
                            this.props.updateSharedContent(
                              this.props.prompt,
                              content
                            );
                        }}
                      />
                    ) : (
                      <Input.TextArea
                        value={defaultValue}
                        disabled={this.props.disabled}
                        id={this.props.prompt.key}
                        onFocus={(e: any) => {
                          if (e.target) {
                            stopTalking();
                            this.handleChange(e.target.value);
                          }
                        }}
                        onBlur={(e: any) => {
                          if (e.target) {
                            stopTalking();
                            this.handleChange(e.target.value);
                          }
                        }}
                        onChange={(e: any) => {
                          if (e.target) {
                            this.handleChange(e.target.value);
                          }
                        }}
                        autosize={{
                          minRows: this.getMinRows(),
                          maxRows: 15,
                        }}
                      />
                    )}
                  </FlexDivideBody>
                </Col>
              </Row>

              {this.props.currentPage !== StepEnum.Review &&
                browserSupportsSpeechRecognition && (
                  <RightAlign>
                    {key !== this.props.prompt.key ? (
                      <Button
                        icon="sound"
                        size="large"
                        disabled={this.props.disabled}
                        onClick={() => {
                          if (startTalking && stopTalking) {
                            stopTalking();
                            startTalking(
                              this.props.prompt.key,
                              defaultValue,
                              this.handleChange
                            );
                          }
                        }}
                      >
                        Start Recording
                      </Button>
                    ) : (
                      <Button icon="stop" onClick={() => stopTalking()}>
                        Stop Recording
                      </Button>
                    )}
                  </RightAlign>
                )}
            </Container>
          );
        }}
      </SharedSpeechConsumer>
    );
  }
}
