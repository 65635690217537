import * as React from "react";
import { Button, Icon } from "antd";
import { Link, Route } from "react-router-dom";
import styled from "styled-components";
import { nextPage } from "../../firebase/utils";

interface MiniCtaProps {
  readonly relative: boolean;
}

export const MiniCta = styled.div<MiniCtaProps>`
  bottom: -85px;
  position: ${props => (props.relative ? "relative" : "inherit")};
  text-align: center;
`;

interface InterfaceProps {
  page: number;
  text: string;
  relative: boolean;
}

export const NextPage = (props: InterfaceProps) => (
  <MiniCta relative={props.relative} className="minicta">
    <Route
      render={({ match }) => (
        <Link to={nextPage(match.url, props.page)}>
          <Button
            style={{
              position: props.relative ? "relative" : "inherit"
            }}
            type={"primary"}
            size={"large"}
          >
            <Icon type="arrow-right" /> {props.text}
          </Button>
        </Link>
      )}
    />
  </MiniCta>
);
