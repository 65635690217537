import * as React from "react";
import * as _ from "lodash";
import { db } from "../../firebase";
import { Select } from "antd";
import styled from "styled-components";
import { IUser, IWorkbook, StepEnum } from "../../firebase/models";
import { subDomain } from "../../firebase/utils";
import { RouteComponentProps, withRouter } from "react-router";
import { isMobile } from "react-device-detect";
import {
  onceGetIndividualSubscriptions,
  onceGetWorkbooks
} from "../../firebase/db";

const Cover = styled.div`
  height: 60px;
  width: 60px;
  min-width: 60px;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  border-radius: 6px;
  margin-right: 8px;
`;

const SelectContainer = styled.div`
  margin-top: ${(props: any) => (isMobile ? 0 : "-100px")};
  max-width: 100vw;
  .ant-select-lg .ant-select-selection--single {
    height: 80px !important;
  }
`;

const OptionContent = styled.div`
  display: flex;
  align-items: center;
  height: 80px;
`;

const OptionText = styled.div`
  flex: 1;
`;

const Title = styled.div`
  font-size: 1rem;
  font-weight: 500;
  white-space: nowrap;
  color: rgba(0, 0, 0, 0.85);
  margin-bottom: 0.1rem;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-top: -5px;
  line-height: 1.5rem !important;
`;
const Description = styled.div`
  font-style: italic;
  line-height: 1 !important;
  font-size: 0.9rem;
  font-weight: normal !important;
`;

interface IMatchParams {
  key: string;
}

interface InterfaceProps extends RouteComponentProps<IMatchParams> {
  authUser: IUser;
}

export const getDefaultAndAvailableWorkbooks = async (authUser: IUser) => {
  const { subscriptionNames = [] } = await onceGetIndividualSubscriptions(
    authUser
  );
  const allWorkbooksSnapshot = await onceGetWorkbooks();
  const allWorkbooksArr = Object.values(
    allWorkbooksSnapshot.val()
  ) as IWorkbook[];
  const subscribedWorkbooks = allWorkbooksArr.filter(workbook =>
    subscriptionNames.includes(workbook.name)
  );
  const organizationSnapshot = await db.onceGetOrganization(subDomain());
  const organization = _.values(organizationSnapshot.val())[0] || {
    key: "",
    workbooks: {}
  };
  const orgWorkbookSnapshots = await Promise.all(
    _.keys(organization.workbooks).map(
      async (workbookKey: string) => await db.onceGetWorkbook(workbookKey)
    )
  );
  const orgWorkbooks = orgWorkbookSnapshots.map(snapshot => snapshot.val());
  let userWorkbooks = [];
  if (subDomain() === "individuals") {
    userWorkbooks = [...subscribedWorkbooks];
  } else {
    userWorkbooks = [...orgWorkbooks];
  }

  return { defaultWorkbookKey: userWorkbooks[0].key, workbooks: userWorkbooks };
};

export class SelectWorkbookComponent extends React.Component<
  InterfaceProps,
  { workbooks: IWorkbook[]; defaultWorkbookKey: string }
  > {
  constructor(props: any) {
    super(props);

    this.state = {
      workbooks: [],
      defaultWorkbookKey: ""
    };
  }

  componentDidMount = async () => {
    const { authUser } = this.props;
    const {
      defaultWorkbookKey,
      workbooks
    } = await getDefaultAndAvailableWorkbooks(authUser);
    this.setState({ defaultWorkbookKey, workbooks });
  };

  onChange = (key: string) => {
    const { history, match } = this.props;
    history.push(match.url + key + "/" + StepEnum.Work);
    db.doUpdateWorkbook({ key, lastOpened: new Date() });
    this.setState({
      defaultWorkbookKey: key
    });
  };

  render() {
    const { workbooks, defaultWorkbookKey } = this.state;
    // hack to remove other workbooks on introduction subdomain
    let workbooksForRender: any = [];
    if (workbooks.length && subDomain() === "introduction") {
      workbooksForRender.push(workbooks[0]);
    } else {
      workbooksForRender = workbooks;
    }

    return (
      <SelectContainer key="select" className="tooltip-2">
        <Select
          style={{
            width: "100%",
            marginBottom: "25px !important"
          }}
          showSearch={true}
          size={"large"}
          value={defaultWorkbookKey}
          onSelect={this.onChange}
          placeholder="Select Your Workbook"
          optionFilterProp="children"
        >
          {workbooksForRender.map((workbook: any) => {
            return (
              <Select.Option key={workbook.key} value={workbook.key}>
                <OptionContent>
                  <Cover
                    style={{ backgroundImage: `url('${workbook.imageUrl}')` }}
                  />
                  <OptionText>
                    <Title>{workbook.name}</Title>
                    <Description>{workbook.description}</Description>
                  </OptionText>
                </OptionContent>
              </Select.Option>
            );
          })}
        </Select>
      </SelectContainer>
    );
  }
}

export const SelectWorkbook = withRouter(SelectWorkbookComponent);
