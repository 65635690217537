import * as React from "react";
import ReactWordcloud from "react-wordcloud";
import stopword from "stopword";
import _ from "lodash";
export function WordCloud({ words = "" }) {
  const wordFrequencies = stopword
    .removeStopwords(words.replace(/[^\w\s]/g, "").split(/\s+/))
    .reduce(function(map, word) {
      map[word] = (map[word] || 0) + 1;
      return map;
    }, Object.create({}));

  const textValueArr = _.orderBy(
    Object.entries(wordFrequencies).map(([text, value]) => ({ text, value })),
    "value",
    "desc"
  ).slice(0, 100);
  return (
    <div
      style={{
        width: "100%",
        height: "400",
        background: "white",
        marginTop: 10
      }}
      className="tooltip-2-2"
    >
      <ReactWordcloud
        words={textValueArr}
        options={{
          colors: [
            "#1f77b4",
            "#ff7f0e",
            "#2ca02c",
            "#d62728",
            "#9467bd",
            "#8c564b"
          ],
          enableTooltip: true,
          fontFamily: "impact",
          fontSizes: [15, 60],
          fontStyle: "normal",
          fontWeight: "normal",
          transitionDuration: 1000
        }}
      />
    </div>
  );
}
