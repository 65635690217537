import * as React from "react";
import styled from "styled-components";
import posed from "react-pose";

const itemConfig = {
  open: { height: "auto", opacity: 1 },
  closed: { height: "1px", opacity: 0 }
};
const Item = posed.div(itemConfig);

const Container = styled.div`
   background: white;
       padding: 16px;
}
`;

const Header = styled.div`
  position: relative;
  padding: 12px 16px 12px 40px;
  color: rgba(0, 0, 0, 0.85);
  line-height: 22px;
  cursor: pointer;
  transition: all 0.3s;
`;

const Wrapper = styled.div`
  background: rgb(240, 247, 246);
  border-width: 0px !important;
  border-style: initial !important;
  border-color: initial !important;
  border-image: initial !important;
`;

interface AppProps {
  locked: boolean;
  header: any;
  children: any;
}

export class Collapse extends React.Component<AppProps> {
  render() {
    return (
      <Wrapper>
        <Header>{this.props.header}</Header>

        <Item
          style={{ overflow: "hidden" }}
          className="item"
          pose={!this.props.locked ? "open" : "closed"}
        >
          <Container>{this.props.children}</Container>
        </Item>
      </Wrapper>
    );
  }
}
